import { types, SnapshotOut } from '@vklink/libs-state';

export const ProductModel = types.model('Product Model', {
  id: types.identifier,
  quantity: types.number,
  code: types.string,
  name: types.maybeNull(types.string),
  slug: types.maybeNull(types.string),
  fileUrl: types.maybeNull(types.string),
});

export interface Product extends SnapshotOut<typeof ProductModel> {}

export const GiftDetailModel = types.model('Gift Detail Model', {
  products: types.array(ProductModel),
  point: types.maybeNull(types.number),
});

export interface GiftDetail extends SnapshotOut<typeof GiftDetailModel> {}
