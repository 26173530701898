import { keyframes } from '@emotion/react';

import { Box, BoxProps } from '@mui/material';

const Running = keyframes`
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
`;

type Props = {
  content: React.ReactNode;

  sx?: BoxProps['sx'];

  duration?: number;
};

const RunningText = (props: Props) => {
  return (
    <Box
      component="div"
      sx={{
        ...props.sx,
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <Box
        component="div"
        sx={{
          transform: 'translateX(100%)',
          animation: `${Running} ${props.duration || 30}s linear infinite`,
          whiteSpace: 'nowrap',
        }}
      >
        {props.content}
      </Box>
    </Box>
  );
};

export default RunningText;
