import { EditOutlined, DeleteOutlined } from '@mui/icons-material';
import { Button, Stack, Typography, Divider, IconButton } from '@mui/material';

import { useToast } from '@vklink/components-toast-message';
import { useI18n } from '@vklink/libs-i18n';
import { observer } from '@vklink/libs-state';
import { useProfileStore } from 'pages/profile/store';
import { UserShippingAddress } from 'pages/profile/store/models';

type AddressDetailProps = {
  isDefault: boolean;
  onUpdate: () => void;
  onDelete?: () => void;
  address: UserShippingAddress;
};

const AddressDetail = (props: AddressDetailProps) => {
  const store = useProfileStore();
  const toast = useToast();
  const { t } = useI18n(['pages', 'common']);

  const handleUpdateAddress = () => {
    props.onUpdate && props.onUpdate();
  };

  const handleSetDefaultAddress = () => {
    store.updateDefaultUserShippingAddressAsync(props.address.id, {
      success: () => {
        store.getUserShippingAddressAsync();
        toast('Success', 'Set Default Address Success', 'success', 'filled');
      },
      error: (err) => {
        console.log(err);
        toast('Error', err.message, 'error', 'filled');
      },
    });
  };

  const handleDeleteAddress = () => {
    store.deleteUserShippingAddressAsync(props.address.id, {
      success: () => {
        store.getUserShippingAddressAsync();
        toast('Success', 'Delete Address Success', 'success', 'filled');
      },
      error: (err) => {
        console.log(err);
        toast('Error', err.message, 'error', 'filled');
      },
    });
  };

  const getFullAddress = (address: UserShippingAddress) => {
    return `${address.fullAddress}, ${address.wardName}, ${address.districtName}, ${address.provinceName}`;
  };

  const renderLabel = (text: string) => {
    return (
      <Typography
        variant="body1"
        component="p"
        color="grey.600"
        sx={{
          minWidth: 150,
          display: {
            xs: 'none',
            md: 'block',
          },
          alignSelf: 'flex-start',
        }}
      >
        {text}
      </Typography>
    );
  };

  const renderValue = (text: string) => {
    return (
      <Typography variant="body1" component="p" color="grey.600">
        {text}
      </Typography>
    );
  };

  return (
    <Stack spacing={1} borderBottom={1} borderColor="grey.200" pb={2} mb={2}>
      <Stack direction="row">
        <Stack flex={1} spacing={0.5}>
          <Stack direction="row" alignItems="center">
            {renderLabel(t('pages:users.fullName'))}
            {renderValue(props.address.contactName)}
          </Stack>
          <Stack direction="row" alignItems="center">
            {renderLabel(t('pages:users.phoneNumber'))}
            {renderValue(props.address.contactPhone)}
          </Stack>
          <Stack direction="row" alignItems="center">
            {renderLabel(t('common:address.address'))}
            {renderValue(getFullAddress(props.address))}
          </Stack>
        </Stack>

        <Stack alignItems="flex-end" ml={1}>
          <Typography
            variant="body2"
            component="p"
            sx={{
              color: 'success.light',
              fontWeight: 'bold',
              visibility: props.isDefault ? 'visible' : 'hidden',
            }}
          >
            {t('common:status.default')}
          </Typography>

          <Stack direction="row">
            {!props.isDefault && (
              <>
                <IconButton onClick={handleDeleteAddress} color="error" sx={{}}>
                  <DeleteOutlined sx={{ fontSize: 20 }} />
                </IconButton>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ mx: 1, backgroundColor: 'grey.300' }}
                />
              </>
            )}
            <IconButton onClick={handleUpdateAddress} color="primary" sx={{}}>
              <EditOutlined sx={{ fontSize: 20 }} />
            </IconButton>
          </Stack>

          <Button
            variant="outlined"
            sx={{
              color: 'common.black',
              maxWidth: (theme) => theme.spacing(14),
              minWidth: (theme) => theme.spacing(12),
              px: 0,
            }}
            size="small"
            color="warning"
            onClick={handleSetDefaultAddress}
            disabled={props.isDefault}
          >
            {t('common:actions.setAsDefault')}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default observer(AddressDetail);
