import { createHttpInstance, type HttpInstance } from '@vklink/libs-http';
import {
  types,
  LoadingStore,
  Instance,
  createStore,
  createStoreContext,
  onSnapshot,
} from '@vklink/libs-state';
import env from 'env';
import i18n from 'i18n';
import CommonStore from './CommonStore';
import LayoutStore from './LayoutStore';

const RootStore = types.model('Root Store', {
  loadingStore: LoadingStore,
  commonStore: CommonStore,
  layoutStore: LayoutStore,
});

type RootStoreInstance = Instance<typeof RootStore>;
type RootStoreEnv = {
  httpInstance: HttpInstance;
};

const httpInstance = createHttpInstance(env.baseApiUrl);

const rootStoreEnv: RootStoreEnv = {
  httpInstance,
};

const initialRootState = createStore(
  RootStore,
  {
    loadingStore: LoadingStore.create(),
    commonStore: CommonStore.create(),
    layoutStore: LayoutStore.create(),
  },
  rootStoreEnv
);

onSnapshot(initialRootState.layoutStore, (snapshot) => {
  i18n.changeLanguage(snapshot.language);
});

const [RootStoreProvider, useRootStore] = createStoreContext<RootStoreInstance>();

export {
  type RootStoreInstance,
  type RootStoreEnv,
  initialRootState,
  RootStoreProvider,
  useRootStore,
  httpInstance,
};
