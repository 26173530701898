import { observer } from '@vklink/libs-state';
import { useAuthStore } from '@vklink/libs-auth';
import { Navigate } from 'react-router-dom';
import { HomeRoutes } from '../routers';

type Props = {
  children: React.ReactNode;
};

const AnonymousRoute = (props: Props) => {
  const authStore = useAuthStore();

  if (authStore.isAuthenticated && !authStore.loading) {
    return <Navigate to={HomeRoutes.ROOT} />;
  }

  return <>{props.children}</>;
};

export default observer(AnonymousRoute);
