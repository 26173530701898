const API_V1_ORDER = '/order/api/v1/orders';
const API_V1_USER = '/order/api/v1/users';
const API_V1_CARTS = '/order/api/v1/carts';
const API_V1_CART_ITEM = '/order/api/v1/cart-items';
const API_V1_COUPONS = '/order/api/v1/coupons';

export const ORDER_API = {
  POST_ORDER: `${API_V1_ORDER}`,
  GET_ORDERS: `${API_V1_USER}/orders`,
  GET_ORDER_STATUSES_BY_USER: `${API_V1_USER}/orders/count-by-status`,
  POST_BANK_TRANSFER_INFORMATION: `${API_V1_ORDER}/send-bank-transfer`,

  GET_ORDER_DETAIL: `${API_V1_USER}/orders/:id`,
  PUT_ORDER_STATUS: `${API_V1_ORDER}/:id/status`,
  POST_ONE_PAY_PAYMENT: `${API_V1_ORDER}/payments/onepay/generate`,
  ADD_ORDER_ITEM_FEEDBACK: `${API_V1_ORDER}/:id/item/:itemId/rating`,

  POST_PRODUCT_TO_CART: `${API_V1_CARTS}`,
  GET_CART_DETAIL: `${API_V1_CARTS}`,
  ADD_PRODUCT_TO_CART: `${API_V1_CARTS}`,

  PUT_CART_ITEMS: `${API_V1_CART_ITEM}/:id`,
  DELETE_CART_ITEMS: `${API_V1_CART_ITEM}/delete`,

  GET_COUPONS: `${API_V1_COUPONS}/:code`,
};
