import { SnapshotIn, types } from '@vklink/libs-state';
import { RequestType } from 'enums';

export const UserEditEmailOrPhoneModel = types.model('User Edit Email Or Phone Model', {
  value: types.maybeNull(types.string),
  token: types.string,
  returnUrl: types.string,
  requestType: types.maybeNull(
    types.enumeration<RequestType>('RequestType', Object.values(RequestType))
  ),
});

export type UserEditOldValue = Omit<UserEditEmailOrPhoneInputModel, 'value' | 'token'>;

export type UserAddNewValue = Omit<UserEditEmailOrPhoneInputModel, 'token'>;

export type UserConfirmOtpOldValue = Omit<UserEditEmailOrPhoneInputModel, 'value'>;

export interface UserEditEmailOrPhoneInputModel
  extends SnapshotIn<typeof UserEditEmailOrPhoneModel> {}
