import { Instance, SnapshotOut, types } from '@vklink/libs-state';
import { OrderItemAttributeValuesModel } from './OrderItemAttributeValuesModel';
import { PromotionType } from 'enums';
import { ProductModel } from 'stores/models';

const GiftDetailModel = types.model('Gift Detail Model', {
  products: types.array(ProductModel),
  point: types.maybeNull(types.number),
  promotionType: types.enumeration('Promotion Type', Object.values(PromotionType)),
});

export interface GiftDetail extends SnapshotOut<typeof GiftDetailModel> {}

export const OrderItemModel = types
  .model('Order Item Model', {
    id: types.identifier,
    productId: types.string,
    productCode: types.string,
    productName: types.string,
    productSlug: types.string,
    productImageUrl: types.maybeNull(types.string),
    quantity: types.number,
    sellPrice: types.number,
    regularPrice: types.maybeNull(types.number),
    totalSellPrice: types.number,
    orderItemAttributeValues: types.array(OrderItemAttributeValuesModel),
    isRating: types.maybeNull(types.boolean),
    giftDetails: types.maybeNull(GiftDetailModel),
  })
  .views((self) => ({
    get getTotalPrice() {
      return self.sellPrice * self.quantity;
    },
    get getAttributeValues() {
      return self.orderItemAttributeValues.map((item) => {
        return item.attributeValue;
      });
    },
  }));

export interface OrderItem extends SnapshotOut<typeof OrderItemModel> {}
export interface OrderItemInstance extends Instance<typeof OrderItemModel> {}
