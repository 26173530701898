import { types, SnapshotOut } from '@vklink/libs-state';

export const AddressCatalogModel = types.model('Address Catalog Model', {
  id: types.identifier,
  code: types.string,
  name: types.string,
  type: types.string,
});

export interface AddressCatalog extends SnapshotOut<typeof AddressCatalogModel> {}
