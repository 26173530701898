import { Box, LinearProgress } from '@mui/material';

import { observer } from '@vklink/libs-state';

import { useRootStore } from 'stores';

const TopLoading = () => {
  const rootStore = useRootStore();

  return (
    <>
      {rootStore.loadingStore.isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 999999,
            width: '100%',
          }}
        >
          <LinearProgress color="primary" />
        </Box>
      )}
    </>
  );
};

export default observer(TopLoading);
