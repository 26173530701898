import { SnapshotOut, types, Instance } from '@vklink/libs-state';
import { CouponType } from 'enums';
import { OrderDeliveryInformationModel } from './OrderDeliveryInformationModel';
import { OrderItemModel } from './OrderItemModel';
import { OrderPaymentInformationModel } from './OrderPaymentInformationModel';

export const OrderModel = types.model('Order Model', {
  id: types.identifier,
  orderNumber: types.string,
  customerId: types.string,
  customerName: types.string,
  sellDate: types.string,
  status: types.string,
  paymentStatus: types.string,
  subtotal: types.number,
  totalPrice: types.number,
  totalDiscount: types.maybeNull(types.number),
  deliveryFee: types.number,
  couponCode: types.maybeNull(types.string),
  pointDiscount: types.maybeNull(types.number),
  couponValue: types.string,
  couponType: types.maybeNull(
    types.enumeration<CouponType>('CouponType', Object.values(CouponType))
  ),
  rebaseOrderNumber: types.maybeNull(types.string),
  trackAndTrace: types.maybeNull(types.string),
  note: types.maybeNull(types.string),
  createdAt: types.string,
  createdById: types.string,
  createdBy: types.maybeNull(types.string),
  orderItems: types.array(OrderItemModel),
  orderDeliveryInfo: types.maybeNull(OrderDeliveryInformationModel),
  orderPaymentInfo: types.maybeNull(OrderPaymentInformationModel),
});

export interface Order extends SnapshotOut<typeof OrderModel> {}
export interface OrderInstance extends Instance<typeof OrderModel> {}
