import { TypeAddress } from 'enums';

export const TypeAddressOptions: SelectOption[] = [
  {
    label: 'common:address.home',
    value: TypeAddress.HOME,
  },
  {
    label: 'common:address.office',
    value: TypeAddress.OFFICE,
  },
];
