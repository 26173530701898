import { useNavigate } from 'react-router';

import { useToast } from '@vklink/components-toast-message';
import { useRootStore } from 'stores';
import { PaymentRoutes } from '../routers';

export enum AfterAuthenticatedActions {
  AddToCart = 'add_to_cart',
  BuyNow = 'buy_now',
}

type AddToCartData = {
  productId: string;
  quantity: number;
  attributeValueIds: string[];
};

export type MapAuthenticatedActionData = {
  [AfterAuthenticatedActions.AddToCart]?: AddToCartData;
  [AfterAuthenticatedActions.BuyNow]?: AddToCartData;
};

export const useCacheAfterAuthenticatedActions = () => {
  const { commonStore } = useRootStore();
  const navigator = useNavigate();
  const toast = useToast();

  const cacheActions = (cache: MapAuthenticatedActionData) => {
    Object.entries(cache).map(([key, val]) => {
      try {
        sessionStorage.setItem(key, JSON.stringify(val));
      } catch (error) {}
    });
  };

  const onAddToCart = (data: AddToCartData) => {
    try {
      const productInfo = {
        cartId: commonStore.carts?.id ?? null,
        productId: data.productId,
        quantity: data.quantity,
        attributeValueIds: data.attributeValueIds,
      };

      commonStore.addProductToCartAsync(productInfo, {
        success: () => {
          toast('Success', 'Add to cart successfully', 'success', 'filled');
          commonStore.getCartItemsAsync();
        },
        error: () => {
          toast('Error', 'Add to cart failed', 'error', 'filled');
        },
      });
    } catch (error) {
    } finally {
      sessionStorage.removeItem(AfterAuthenticatedActions.AddToCart);
    }
  };

  const onBuyNow = (data: AddToCartData) => {
    try {
      const productInfo = {
        cartId: commonStore.carts?.id ?? null,
        productId: data.productId,
        quantity: data.quantity,
        attributeValueIds: data.attributeValueIds,
      };

      commonStore.addProductToCartAsync(productInfo, {
        success: async () => {
          toast('Success', 'Add to cart successfully', 'success', 'filled');
          await commonStore.getCartItemsAsync();

          const cartItemId =
            commonStore.getProductInCart?.find((el) => el.productId === productInfo.productId)
              ?.id ?? '';

          if (!!cartItemId) {
            commonStore.checkoutAllSelectedProduct([cartItemId]);
            navigator(PaymentRoutes.ROOT);
          }
        },
        error: () => {
          toast('Error', 'Add to cart failed', 'error', 'filled');
        },
      });
    } catch (error) {
    } finally {
      sessionStorage.removeItem(AfterAuthenticatedActions.BuyNow);
    }
  };

  const MapActions = {
    [AfterAuthenticatedActions.AddToCart]: onAddToCart,
    [AfterAuthenticatedActions.BuyNow]: onBuyNow,
  };

  const runActions = () => {
    Object.entries(MapActions).map(([key, action]) => {
      const cacheData = sessionStorage.getItem(key);

      if (!!cacheData) {
        try {
          const parsedData = JSON.parse(cacheData);

          action(parsedData);
        } catch (error) {}
      }
    });
  };

  return {
    cacheActions,
    runActions,
  };
};
