import { Navigate } from 'react-router-dom';

import { useAuthStore } from '@vklink/libs-auth';
import { observer } from '@vklink/libs-state';
import { HomeRoutes } from '../routers';

const ProtectedRoute = (props: { children: any }) => {
  const authStore = useAuthStore();

  if (!authStore.isAuthenticated && !authStore.loading) {
    return <Navigate to={HomeRoutes.ROOT} />;
  }

  return props.children;
};

export default observer(ProtectedRoute);
