import { useState } from 'react';

import { Button, Dialog, IconButton } from '@mui/material';

import { useDisclosure } from '@vklink/components-shared';
import { observer } from '@vklink/libs-state';
import { useRootStore } from 'stores';
import ProductMainInfo from './ProductMainInfo';
import { CloseOutlined } from '@mui/icons-material';
import { useI18n } from '@vklink/libs-i18n';
import { PromotionProduct } from 'pages/shared/models';

type Props = { productId: string };

const QuickViewButton = (props: Props) => {
  const { isOpen, toggle } = useDisclosure(false);
  const { commonStore } = useRootStore();
  const [data, setData] = useState(null);
  const [promotion, setPromotion] = useState<PromotionProduct>();
  const { t } = useI18n(['common']);

  const onClick = () => {
    if (data) {
      toggle();
      return;
    }

    commonStore.getProductDetailAsync(props.productId, {
      success: (detail) => {
        setData(detail);
        toggle();
      },
    });

    commonStore.getPromotionDetailAsync(props.productId, {
      success: (promotion) => {
        setPromotion(promotion);
      },
      error: () => {
        setPromotion(undefined);
      },
    });
  };

  return (
    <>
      <Button
        className="quick-view-btn"
        fullWidth
        variant="contained"
        color="warning"
        sx={{
          borderRadius: 0,
          // border: (theme) => `1px solid ${theme.palette.warning.main}`,
          fontWeight: 'bold',
          color: 'common.black',
          fontSize: (theme) => theme.typography.fontSize - 1,

          // ':hover': {
          //   borderColor: 'grey.300',
          //   backgroundColor: 'common.white',
          // },
        }}
        onClick={onClick}
      >
        {t('common:actions.quickView').toUpperCase()}
      </Button>

      <Dialog
        onClose={toggle}
        open={isOpen}
        maxWidth={'lg'}
        sx={{
          '& .MuiPaper-root.MuiDialog-paper': {
            backgroundColor: 'common.white',
            p: 2,
          },
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            backgroundColor: 'warning.main',
            border: (theme) => `1px solid ${theme.palette.warning.main}`,
            color: 'common.white',
            zIndex: 1,
            ':hover': {
              color: 'common.black',
            },
          }}
          onClick={toggle}
        >
          <CloseOutlined fontSize="small" />
        </IconButton>
        <ProductMainInfo productDetail={data} promotion={promotion} />
      </Dialog>
    </>
  );
};

export default observer(QuickViewButton);
