const applyCustomConfigInterceptor = (
  instance: HttpInstance,
  getHeadersAsync: () => Promise<{ [key: string]: string } | null>
) => {
  instance.interceptors.request.use(
    (config) => {
      return getHeadersAsync()
        .then((headers) => {
          config.headers = {
            ...config.headers,
            ...headers,
          };

          return config;
        })
        .catch((error) => Promise.reject(error));
    },

    (error) => {
      return Promise.reject(error);
    }
  );
};

export default applyCustomConfigInterceptor;
