import { generatePath, NavLink } from 'react-router-dom';

import { Box } from '@mui/material';

import { ProductRoutes } from 'pages/shared/routers';
import DiscountTag from './DiscountTag';

type Props = {
  product: {
    id: string;
    slug: string;
    imageUrl?: string;
    discountPercent?: number | null;
  };
  hideDiscount?: boolean;
};

const ProductAvatar = (props: Props) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        overflow: 'hidden',
        zIndex: 0,

        '& a': {
          flex: 1,
        },

        '& img': {
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          transition: '0.5s',

          ':hover': {
            transform: 'scale(1.1)',
          },
        },
      }}
    >
      <NavLink
        style={{
          textDecoration: 'none',
          backgroundColor: 'transparent',
        }}
        to={generatePath(ProductRoutes.ROOT, {
          id: props.product.id.toString(),
          slug: props.product.slug,
        })}
      >
        <img src={props.product.imageUrl} alt="main-product-img" />
      </NavLink>

      {props.product?.discountPercent && !props.hideDiscount && (
        <DiscountTag discountPercent={props.product.discountPercent} />
      )}
    </Box>
  );
};

export default ProductAvatar;
