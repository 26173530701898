import React from 'react';

import { Dialog } from '@mui/material';

type Props = {
  isTouchBreakpoint: boolean;
  children: React.ReactNode;
  header?: React.ReactNode;
};

const BreakpointFullScreenDialog = (props: Props) => {
  return (
    <>
      {props.isTouchBreakpoint ? (
        <Dialog
          open
          fullScreen
          sx={{
            '& .MuiPaper-root.MuiDialog-paper': {
              backgroundColor: (theme) => theme.palette.common.white,
            },
          }}
        >
          {!!props.header && props.header}
          {props.children}
        </Dialog>
      ) : (
        props.children
      )}
    </>
  );
};

export default BreakpointFullScreenDialog;
