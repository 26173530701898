import { createStoreContext } from '@vklink/libs-state';
import { ProductStoreInstance } from './ProductStore';

const [ProductStoreProvider, useProductStore] = createStoreContext<ProductStoreInstance>();

export { ProductStoreProvider, useProductStore };

export {
  default as ProductStore,
  type ProductStoreEnv,
  type ProductStoreInstance,
} from './ProductStore';
