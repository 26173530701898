import { RootStoreEnv } from 'stores';
import { types, getEnv, flow, applySnapshot, getSnapshot } from '@vklink/libs-state';
import { REQUEST_WAREHOUSE_API } from 'api';
import { CheckStock, OutOfStockModel } from 'pages/shared/models';
import { CartItem } from 'stores/models';

export type CheckStockStoreEnv = RootStoreEnv & {
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

export const CheckStockStore = types
  .model('Check Stock Store', {
    listOutOfStocks: types.array(OutOfStockModel),
  })
  .views((self) => {
    return {
      get getListOutOfStocks() {
        return getSnapshot(self.listOutOfStocks);
      },
      get getListSkuOutOfStocks() {
        return self.listOutOfStocks.map((item) => item.goodsCode);
      },
    };
  })
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<CheckStockStoreEnv>(self);

    const updateListOutOfStocksByArray = (listProducts: CartItem[]) => {
      const result = self.getListOutOfStocks.map((el) => {
        const product = listProducts.find((item) => item.productCode === el.goodsCode);
        if (product) {
          return {
            ...el,
            stockQuantity: el.stockQuantity - product.quantity,
          };
        }
        return el;
      });

      applySnapshot(self.listOutOfStocks, result);
    };

    const updateListOutOfStocksBySku = (sku: string, quantity: number) => {
      const result = self.getListOutOfStocks.map((el) => {
        if (el.goodsCode === sku) {
          return {
            ...el,
            stockQuantity: el.stockQuantity - quantity,
          };
        }
        return el;
      });

      applySnapshot(self.listOutOfStocks, result);
    };

    const postCheckStockAsync = flow(function* (products: CheckStock, cb?: RequestCallback) {
      const loadingId = load('Post Check Stock Async');

      try {
        yield httpInstance.post(REQUEST_WAREHOUSE_API.POST_CHECK_STOCK, {
          ...products,
        });

        cb?.success && cb.success();
      } catch (err: any) {
        applySnapshot(self.listOutOfStocks, err?.response.data.data);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      postCheckStockAsync,
      updateListOutOfStocksByArray,
      updateListOutOfStocksBySku,
    };
  });
