import { Button } from '@mui/material';
import { useDisclosure } from '@vklink/components-shared';
import { ConfirmDialog } from '@vklink/components-dialog';
import { useI18n } from '@vklink/libs-i18n';

type Props = {
  onClick: () => void;
};

const ReceivedButton = (props: Props) => {
  const { isOpen, toggle } = useDisclosure();
  const { t } = useI18n(['common']);

  const handleReceiveOrder = () => {
    toggle();
    props.onClick();
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={toggle}
        sx={{
          backgroundColor: 'warning.main',

          ':hover': {
            backgroundColor: 'warning.main',
          },
        }}
      >
        {t('common:actions.receivedOrder')}
      </Button>
      <ConfirmDialog
        open={isOpen}
        title="Confirm"
        content={`Did you receive your order ?`}
        onNoCallback={toggle}
        onYesCallback={handleReceiveOrder}
      />
    </>
  );
};

export default ReceivedButton;
