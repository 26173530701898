import { SnapshotOut, types } from '@vklink/libs-state';
import { SubmitGiftDetailModel } from './SubmitGiftDetailModel';

export const ProductItemModel = types.model('ProductItem Model', {
  productId: types.string,
  quantity: types.number,
  totalPrice: types.number,
  productName: types.string,
  productCode: types.string,
  cartItemId: types.string,
  sellPrice: types.number,
  regularPrice: types.number,
  giftDetail: types.maybeNull(SubmitGiftDetailModel),
});

export interface ProductItem extends SnapshotOut<typeof ProductItemModel> {}
