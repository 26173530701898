export enum MyProfileRoutes {
  ROOT = '/profile',
  ACCOUNT_INFORMATION = '/profile/my-account',

  EDIT_PROFILE = '/profile/my-account/edit',
  EDIT_OLD_EMAIL = '/profile/my-account/edit/email',
  EDIT_NEW_EMAIL = '/profile/my-account/edit/email/new',
  EDIT_OLD_PHONE = '/profile/my-account/edit/phone',
  EDIT_NEW_PHONE = '/profile/my-account/edit/phone/new',

  CONFIRM_OTP_EMAIL = '/profile/my-account/confirm-otp/email',
  CONFIRM_OTP_NEW_EMAIL = '/profile/my-account/confirm-otp/email/new',
  CONFIRM_OTP_PHONE = '/profile/my-account/confirm-otp/phone',
  CONFIRM_OTP_NEW_PHONE = '/profile/my-account/confirm-otp/phone/new',

  MY_POINTS = '/profile/my-account/my-points',
  CHANGE_PASSWORD = '/profile/my-account/change-password',
  ADDRESS_MANAGEMENT = '/profile/my-account/address',
  BANK_MANAGEMENT = '/profile/my-account/bank',
  PURCHASE_ORDER = '/profile/purchase-order',
  PURCHASE_ORDER_DETAIL = '/profile/purchase-order/:id',
  ORDER_UPDATE = '/profile/notify',
  PROMOTION = '/profile/notify/promotion',
}
