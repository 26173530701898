import { types, SnapshotOut } from '@vklink/libs-state';

export const AttributeValuesModel = types.model('Attribute Values Model', {
  id: types.identifier,
  value: types.string,
});

export const AttributeModel = types.model('Attribute Model', {
  id: types.identifier,
  name: types.string,
  attributeType: types.string,
  attributeValues: types.array(AttributeValuesModel),
});

export interface AttributeValues extends SnapshotOut<typeof AttributeValuesModel> {}
