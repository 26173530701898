import { types, SnapshotIn } from '@vklink/libs-state';

export const OrderFilterParamsModel = types.model('Order Filter Params Model', {
  keyword: types.string,
  status: types.string,
});

export const DefaultOrderFilterParams: OrderFilterParams = {
  keyword: '',
  status: '',
};

export interface OrderFilterParams extends SnapshotIn<typeof OrderFilterParamsModel> {}
