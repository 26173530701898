import { Box, Grid, Typography } from '@mui/material';
import { ProductAvatar, ProductName } from 'pages/shared/components';
import { GiftDetail } from '../../store/models';
import { Product } from 'stores/models';
import { formatCurrency } from 'pages/shared/utils';
import promotion from 'assets/promotion-3.png';
import { PromotionType } from 'enums';
import { useBreakpoints } from '@vklink/components-shared';

const PromotionProductOrder = (props: GiftDetail) => {
  const { point, products, promotionType } = props;
  const breakpoints = useBreakpoints();

  const renderPoint = () => (
    <Grid
      container
      mt={2}
      sx={{
        justifyContent: breakpoints.md ? 'flex-start' : 'space-between',
        borderTop: 1,
        borderColor: 'grey.400',
        pt: 2,
        pl: breakpoints.md ? 4 : 2,
        pr: breakpoints.md ? 1 : 0,
      }}
    >
      <Grid item md={6} display="flex">
        <Box
          sx={{
            minWidth: 60,
            width: 60,
            height: 60,
          }}
          border={1}
          borderColor="grey.400"
          p={1}
          borderRadius={2}
        >
          <Box
            component="img"
            src={promotion}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              transition: '0.5s',
            }}
          />
        </Box>
      </Grid>
      {breakpoints.md ? (
        <>
          <Grid item md={2} />
          <Grid item md={2}>
            <Typography
              variant="body2"
              component="div"
              textAlign="center"
              sx={{ color: 'grey.600' }}
            >
              <Box ml={1}>{point && formatCurrency(point, 0, 3)} points</Box>
            </Typography>
          </Grid>
          <Grid item md={2} />
        </>
      ) : (
        <Grid
          item
          sx={{
            alignSelf: 'center',
          }}
        >
          <Typography variant="body2" component="div" textAlign="center" sx={{ color: 'grey.600' }}>
            <Box ml={1}>{point && formatCurrency(point, 0, 3)} points</Box>
          </Typography>
        </Grid>
      )}
    </Grid>
  );

  const renderProduct = (product: Product) => (
    <Grid
      key={product.id}
      container
      mt={2}
      sx={{
        borderTop: 1,
        borderColor: 'grey.400',
        pt: 2,
        pl: breakpoints.md ? 4 : 2,
        pr: breakpoints.md ? 1 : 0,
      }}
    >
      <Grid item xs={12} md={6} display="flex">
        <Box
          sx={{
            minWidth: 60,
            width: 60,
            height: 60,
          }}
          border={1}
          borderColor="grey.400"
          p={1}
          borderRadius={2}
        >
          <ProductAvatar
            product={{
              id: product.id,
              imageUrl: product.fileUrl || '',
              slug: product.slug || '',
            }}
          />
        </Box>

        <Box
          ml={1}
          sx={{
            overflow: 'hidden',
            '& .product-name h6': {
              color: 'common.black',
              fontWeight: 'normal',
              minHeight: 22,
            },
          }}
        >
          <ProductName
            name={product.name || ''}
            id={product.id}
            slug={product.slug || ''}
            promotionType={PromotionType.GIFT}
          />
          <Typography variant="body2" component="p" color="grey.400">
            x 1
          </Typography>
        </Box>
      </Grid>
      {breakpoints.md && (
        <>
          <Grid item md={2} />
          <Grid item md={2}>
            <Typography
              variant="body2"
              component="div"
              textAlign="center"
              sx={{ color: 'grey.600' }}
            >
              1
            </Typography>
          </Grid>
          <Grid item md={2} />
        </>
      )}
    </Grid>
  );

  const renderListProducts = () => <>{products.map((product) => renderProduct(product))}</>;

  return (
    <>
      {promotionType === PromotionType.POINT && renderPoint()}
      {promotionType === PromotionType.GIFT && renderListProducts()}
    </>
  );
};

export default PromotionProductOrder;
