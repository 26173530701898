import { SnapshotIn, SnapshotOut, types } from '@vklink/libs-state';

export const UserCommentModel = types.model('User Comment Model', {
  name: types.string,
  phone: types.string,
  email: types.string,
});

export interface UserCommentInputModel extends SnapshotIn<typeof UserCommentModel> {}

export const DefaultUserCommentValue: Partial<UserCommentInputModel> = {
  name: '',
  phone: '',
  email: '',
};

export const PostProductDiscussionModel = types.model('Post Product Discussion Model', {
  comment: types.string,
  parentCommentId: types.maybeNull(types.string),
  userComment: types.maybeNull(UserCommentModel),
});

export interface PostProductDiscussion extends SnapshotOut<typeof PostProductDiscussionModel> {}
