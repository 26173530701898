import { Instance, SnapshotIn, SnapshotOut, types } from '@vklink/libs-state';
import { MediaModel } from 'pages/shared/models/media';
import { PromotionType } from 'enums';

export const ProductModel = types.model('Product Model', {
  id: types.identifier,
  code: types.string,
  name: types.string,
  slug: types.string,
  medias: types.array(MediaModel),
});

export interface Product extends SnapshotOut<typeof ProductModel> {}

export const PromotionProductModel = types.model('Promotion Product Model', {
  promotionType: types.enumeration('Promotion Type', Object.values(PromotionType)),
  products: types.maybeNull(types.array(ProductModel)),
  point: types.maybeNull(types.number),
});

export interface PromotionProductInstance extends Instance<typeof PromotionProductModel> {}
export interface PromotionProductInputModel extends SnapshotIn<typeof PromotionProductModel> {}
export interface PromotionProduct extends SnapshotOut<typeof PromotionProductModel> {}
