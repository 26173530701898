import { Box, Typography, Stack } from '@mui/material';
import { BreadcrumbItemProps, Breadcrumbs, useBreakpoints } from '@vklink/components-shared';
import { useI18n } from '@vklink/libs-i18n';

type Props = {
  src: string;
  title: string;

  breadcrumbs: BreadcrumbItemProps[];
};

const BannerBreadcrumbs = (props: Props) => {
  const breakpoints = useBreakpoints();
  const { t } = useI18n(['components']);

  return (
    <Box
      sx={{
        backgroundImage: `url(${props.src})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: 'top',
        minHeight: breakpoints.md ? 360 : 180,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',

        ':before': {
          position: 'absolute',
          content: '""',
          background: '#0e2641',
          opacity: 0.3,
          width: '100%',
          height: '100%',
          zIndex: -1,
        },
      }}
    >
      <Stack alignItems="center" spacing={2}>
        <Typography
          variant={breakpoints.md ? 'h2' : 'h4'}
          component="h4"
          sx={{
            color: 'common.white',
          }}
        >
          {props.title}
        </Typography>

        <Breadcrumbs
          items={props.breadcrumbs}
          homeText={t('components:sideBar.home')}
          sx={{
            '& .MuiBreadcrumbs-separator': {
              color: '#999',
            },

            '& .breadcrumbs-item': {
              '& .MuiTypography-root': {
                color: '#999',
              },
            },

            '& .breadcrumbs-item.last': {
              '& .MuiTypography-root': {
                color: 'common.white',
              },
            },
          }}
        />
      </Stack>
    </Box>
  );
};

export default BannerBreadcrumbs;
