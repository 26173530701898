const API_V1_USERS = '/customer/api/v1/users';
const API_V1_CONTACTS = '/customer/api/v1/contacts';

export const USERS_API = {
  GET_USERS_SHIPPING_ADDRESS: `${API_V1_USERS}/shipping-addresses`,
  POST_USERS_SHIPPING_ADDRESS: `${API_V1_USERS}/shipping-addresses`,
  PUT_DEFAULT_USERS_SHIPPING_ADDRESS: `${API_V1_USERS}/shipping-addresses/:id/set-default`,
  GET_HISTORY_POINT_USER_ID: `${API_V1_USERS}/:id/history-point`,

  PUT_USERS_INFORMATION: `${API_V1_USERS}`,
  GET_USER: `${API_V1_USERS}/userId`,
  POST_USERS: `${API_V1_USERS}`,
  POST_USERS_LOGIN: `${API_V1_USERS}/login`,

  POST_CONFIRM_OLD_EMAIL: `${API_V1_USERS}/confirm-email`,
  POST_CHECK_EXIST_PHONE_NUMBER: `${API_V1_USERS}/check-exist-phone-number`,
  POST_CONTACT_US: `${API_V1_CONTACTS}`,
  POST_EMAIL_NOTIFICATION: `${API_V1_CONTACTS}/subscribe`,
};
