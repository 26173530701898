import { generatePath, NavLink } from 'react-router-dom';

import { Typography } from '@mui/material';

import { ProductRoutes } from 'pages/shared/routers';
import { PromotionType } from 'enums';
import ProductPromotion from './ProductPromotion';

type Props = {
  name: string;
  slug: string;
  id: string;

  numOfLines?: number;
  promotionType?: PromotionType;
};

const ProductName = (props: Props) => {
  const numOfLines = props.numOfLines || 1;

  return (
    <NavLink
      className="product-name"
      style={{
        textDecoration: 'none',
        backgroundColor: 'transparent',
      }}
      to={generatePath(ProductRoutes.ROOT, {
        id: props.id,
        slug: props.slug,
      })}
    >
      {props.promotionType && (
        <ProductPromotion
          content={props.promotionType}
          sx={{
            pr: 0.25,
          }}
        />
      )}
      <Typography
        component="h6"
        sx={{
          fontWeight: 'bold',
          // whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: 'common.black',
          transition: '0.5s',
          fontSize: (theme) => theme.typography.body2.fontSize,
          display: '-webkit-box',
          WebkitLineClamp: numOfLines,
          WebkitBoxOrient: 'vertical',
          minHeight: numOfLines > 1 ? 42 : undefined,

          ':hover': {
            color: (theme) => `${theme.palette.warning.main} !important`,
          },
        }}
      >
        {/* {props.promotionType && (
          <ProductPromotion
            content={props.promotionType}
            sx={{
              pr: 0.25,
            }}
          />
        )} */}
        {props.name}
      </Typography>
    </NavLink>
  );
};

export default ProductName;
