import { IconButton } from '@mui/material';
import { useRootStore } from 'stores';
import { observer } from '@vklink/libs-state';
import { CountryFlagIcon } from '@vklink/components-shared';
import { formValidationCallback } from '@vklink/components-form';
import { useI18n } from '@vklink/libs-i18n';

const ChangeLanguage = () => {
  const { layoutStore } = useRootStore();
  const { t } = useI18n();

  const toggleLanguage = () => {
    layoutStore.changeLanguage(layoutStore.language === 'vi' ? 'en' : 'vi');
    formValidationCallback(null, t);
  };

  return (
    <IconButton onClick={toggleLanguage} style={{ color: 'unset' }}>
      {layoutStore.language === 'vi' ? (
        <CountryFlagIcon countryCode="VN" svg />
      ) : (
        <CountryFlagIcon countryCode="US" svg />
      )}
    </IconButton>
  );
};

export default observer(ChangeLanguage);
