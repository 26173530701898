import { useMemo } from 'react';
import { Outlet } from 'react-router';

import { createStore, getEnv } from '@vklink/libs-state';

import { useRootStore } from 'stores';
import { ProductStore, ProductStoreInstance, ProductStoreProvider } from './stores';

const ProductLayout = () => {
  const rootStore = useRootStore();
  const initialState: ProductStoreInstance = useMemo(() => {
    return createStore(ProductStore, undefined, {
      ...getEnv(rootStore),
      load: rootStore.loadingStore.load,
      loaded: rootStore.loadingStore.loaded,
    });
  }, []);

  return (
    <ProductStoreProvider value={initialState}>
      <Outlet />
    </ProductStoreProvider>
  );
};

export default ProductLayout;
