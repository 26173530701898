import { Button } from '@mui/material';
import { ConfirmDialog } from '@vklink/components-dialog';
import { useDisclosure } from '@vklink/components-shared';
import { useI18n } from '@vklink/libs-i18n';

type Props = {
  onClick: () => void;
};

const RepayButton = (props: Props) => {
  const { isOpen, toggle } = useDisclosure();
  const { t } = useI18n(['pages']);

  const handleRepayOrder = () => {
    toggle();
    props.onClick();
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={toggle}
        sx={{
          backgroundColor: 'warning.main',

          ':hover': {
            backgroundColor: 'warning.main',
          },
        }}
      >
        Pay Order
      </Button>
      <ConfirmDialog
        open={isOpen}
        title="Confirm"
        content={t('pages:orders.confirmOrderPayment')}
        onNoCallback={() => toggle()}
        onYesCallback={() => handleRepayOrder()}
      />
    </>
  );
};

export default RepayButton;
