import { Instance, SnapshotIn, SnapshotOut, types } from '@vklink/libs-state';

export const DiscussionModel = types.model('Discussion Model', {
  comment: types.optional(types.string, ''),
  keyword: types.optional(types.string, ''),
});

export const DefaultDiscussionValue: Partial<DiscussionInputModel> = {
  comment: '',
  keyword: '',
};

export interface DiscussionInstance extends Instance<typeof DiscussionModel> {}
export interface DiscussionInputModel extends SnapshotIn<typeof DiscussionModel> {}
export interface Discussion extends SnapshotOut<typeof DiscussionModel> {}
