import { useState } from 'react';

import { ContentCopyOutlined } from '@mui/icons-material';
import { IconButton, SxProps, Theme, Popover, Typography } from '@mui/material';

type Props = {
  text: string | number;
  sx?: SxProps<Theme>;
};

const CopyButton = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleCopyToClipboard = (event: React.MouseEvent<HTMLButtonElement>) => {
    navigator.clipboard.writeText(props.text.toString());
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton onClick={handleCopyToClipboard}>
        <ContentCopyOutlined fontSize="small" />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Typography sx={{ p: 2 }}>Copied</Typography>
      </Popover>
    </>
  );
};

export default CopyButton;
