import { useState, useEffect } from 'react';

import { SxProps, Theme } from '@mui/material';

export const useTouchDevice = () => {
  const query = matchMedia('(hover: none), (pointer: coarse)');
  const [isTouchDevice, setIsTouchDevice] = useState(query.matches);

  const makeDeviceSxStyle = (touchDevice: SxProps<Theme>, mouseDevice: SxProps<Theme> = {}) => {
    return isTouchDevice ? touchDevice : mouseDevice;
  };

  useEffect(() => {
    const onChange = (evt: MediaQueryListEvent) => setIsTouchDevice(evt.matches);
    query.addEventListener('change', onChange);

    return () => {
      try {
        query.removeEventListener('change', onChange);
      } catch (error) {}
    };
  }, []);

  return {
    isTouchDevice,
    makeDeviceSxStyle,
  };
};
