import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';
import { PromotionType } from 'enums';
import { MediaModel } from './media';

export const ProductItemModel = types.model('Product Item Model', {
  id: types.identifier,
  code: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  slug: types.string,
  description: types.maybeNull(types.string),
  vatPercent: types.maybeNull(types.number),
  discountPercent: types.maybeNull(types.number),
  regularPrice: types.number,
  sellPrice: types.number,
  stock: types.maybeNull(types.number),
  ratingAvg: types.maybeNull(types.number),
  brandId: types.maybeNull(types.string),
  categoryId: types.maybeNull(types.string),
  promotionType: types.enumeration('Promotion Type', Object.values(PromotionType)),
  enabled: types.boolean,
  media: MediaModel,
});

export interface ProductItemInstance extends Instance<typeof ProductItemModel> {}
export interface ProductItemInputModel extends SnapshotIn<typeof ProductItemModel> {}
export interface ProductItem extends SnapshotOut<typeof ProductItemModel> {}
