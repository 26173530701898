import { useNavigate } from 'react-router';
import { generatePath } from 'react-router-dom';

import { Card, CardActionArea, CardContent, CardMedia, Stack } from '@mui/material';
import ProductName from './ProductName';
import { ProductRoutes } from 'pages/shared/routers';
import { Product } from 'pages/shared/models';

const PromotionProduct = (props: Product) => {
  const navigator = useNavigate();

  const handleNavigateToProductDetail = () => {
    navigator(generatePath(ProductRoutes.ROOT, { id: props.id.toString(), slug: props.slug }));
  };

  return (
    <Card
      sx={{
        backgroundColor: (theme) => theme.palette.common.white,
        position: 'relative',
        boxShadow: (theme) => theme.shadows[0],
        maxWidth: 180,
      }}
    >
      <CardActionArea onClick={handleNavigateToProductDetail}>
        <CardMedia
          component="img"
          image={props.medias[0]?.url}
          alt={props.medias[0]?.url}
          sx={{ cursor: 'pointer', maxHeight: 150, objectFit: 'contain' }}
          loading="lazy"
        />
        <CardContent
          sx={{
            p: 1,
          }}
          component={Stack}
          direction={'column'}
        >
          <ProductName name={props.name || ''} id={props.id} slug={props.slug} numOfLines={2} />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default PromotionProduct;
