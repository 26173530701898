import { SnapshotOut, types } from '@vklink/libs-state';
import { PromotionType } from 'enums';

const ProductGiftModel = types.model('Product Gift Model', {
  id: types.string,
  quantity: types.number,
  code: types.string,
  name: types.maybeNull(types.string),
});

export const SubmitGiftDetailModel = types.model('Submit Gift Model', {
  promotionType: types.enumeration('Promotion Type', Object.values(PromotionType)),
  products: types.array(ProductGiftModel),
  point: types.maybeNull(types.number),
});

export interface SubmitGiftDetail extends SnapshotOut<typeof SubmitGiftDetailModel> {}
