import { useRef } from 'react';

import { Box, Button, Stack } from '@mui/material';
import { KeyboardArrowUpOutlined, KeyboardArrowDownOutlined } from '@mui/icons-material';

import { useDisclosure } from '@vklink/components-shared';
import { useI18n } from '@vklink/libs-i18n';

type Props = {
  text: string | null | undefined;
};

const RichTextView = (props: Props) => {
  const { isOpen, close, open } = useDisclosure(false);
  const ref = useRef<any>(null);
  const { t } = useI18n(['common']);

  const onClose = () => {
    close();

    if (typeof ref.current?.scrollIntoView === 'function') {
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      }, 100);
    }
  };

  return (
    <>
      {!!props.text && (
        <Stack
          ref={ref}
          alignItems="center"
          sx={{
            overflow: 'hidden',

            '& .wrapper': {
              width: '100%',
              display: 'flex',
              flexDirection: 'column',

              '& p': {
                m: 0,
                lineHeight: '30px',
                display: 'flex',
                maxWidth: '100%',
              },

              '& img': {
                maxWidth: {
                  xs: '100%',
                  sm: 500,
                  md: 800,
                },
                objectFit: 'contain',
                mx: 'auto',
              },
            },
          }}
        >
          <Box
            className="wrapper"
            component="div"
            dangerouslySetInnerHTML={{ __html: props.text }}
            sx={{
              minHeight: 100,
              maxHeight: !isOpen ? 500 : 500000,
              overflow: 'hidden',
              transition: '1s',

              '& img': {
                display: isOpen ? undefined : 'none',
              },

              position: 'relative',
              ':after': {
                content: '""',
                position: 'absolute',
                zIndex: 1,
                bottom: 0,
                left: 0,
                right: 0,
                height: isOpen ? 0 : 200,
                background:
                  'linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%)',
              },
            }}
          />

          <Button
            sx={{
              mt: 1,
              zIndex: 1,
              color: 'warning.main',
              px: 3,
              ':hover': {
                backgroundColor: 'warning.main',
                color: 'common.white',
              },
            }}
            variant="text"
            size="large"
            endIcon={isOpen ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
            onClick={isOpen ? onClose : open}
          >
            {isOpen ? t('common:actions.hidden') : t('common:actions.showMore')}
          </Button>
        </Stack>
      )}
    </>
  );
};

export default RichTextView;
