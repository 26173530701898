import { Grid } from '@mui/material';

import { ProductItem } from '../models';
import ProductItemCard from './ProductItem';

type Props = {
  products: any[];
  card?: (props: ProductItem) => JSX.Element;
};

const ProductList = (props: Props) => {
  const Card = props.card || ProductItemCard;

  return (
    <Grid container spacing={2}>
      {props.products.map((item, index) => (
        <Grid key={index} item xs={6} md={3} lg={3}>
          <Card {...item} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductList;
