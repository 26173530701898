import { useAuthStore } from '@vklink/libs-auth';
import { useLocation } from 'react-router';

export const useSignIn = () => {
  const { pathname } = useLocation();
  const authStore = useAuthStore();

  const signInAsync = (redirectPath?: string) => {
    authStore.signinAsync({
      extraQueryParams: {
        redirect_path: redirectPath || pathname,
      },
    });
  };

  return { signInAsync };
};
