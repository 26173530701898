import { types, Instance, getEnv, flow, getSnapshot, applySnapshot } from '@vklink/libs-state';
import { CategoryModel, ProductItemModel } from 'pages/shared/models';
import { CATALOG_API, PRODUCT_API, USERS_API } from 'api';
import { PaginationStore } from 'pages/shared/stores';
import { BannerType, Pagination } from 'enums';
import {
  BannerModel,
  ContactUsInputModel,
  DefaultProductFilterParams,
  FilterProduct,
  FilterProductModel,
} from './models';
import { removeEmptyInObject } from 'pages/shared/utils';
import { RootStoreEnv } from 'stores';

export type HomeStoreEnv = RootStoreEnv & {
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const HomeStore = types
  .compose(
    PaginationStore,
    types.model('Home Store', {
      productItems: types.array(ProductItemModel),
      listProductItems: types.array(ProductItemModel),
      categoryOptions: types.array(CategoryModel),
      filterParams: types.optional(FilterProductModel, DefaultProductFilterParams),
      listBanners: types.array(BannerModel),

      bannerLoading: types.optional(types.boolean, false),
    })
  )

  .views((self) => {
    return {
      get listProducts() {
        return getSnapshot(self.productItems);
      },
      get listTopNewProducts() {
        return getSnapshot(self.listProductItems);
      },
      get getCategoryOptions() {
        return self.categoryOptions.map((el) => {
          return { value: el.id, label: el.name };
        });
      },
      get getQueryParams() {
        return removeEmptyInObject({
          ...getSnapshot(self.filterParams),
        });
      },
      get getMainBanner() {
        const result = getSnapshot(self.listBanners).filter(
          (el) => el.bannerType === BannerType.MAIN_BANNER
        );
        return result;
      },
      get getEventBanner() {
        const result = getSnapshot(self.listBanners).filter(
          (el) => el.bannerType === BannerType.EVENT_BANNER
        );
        return result;
      },
      get getSubBanner() {
        const result = getSnapshot(self.listBanners).filter(
          (el) => el.bannerType === BannerType.SUB_BANNER
        );
        return result;
      },
    };
  })
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<HomeStoreEnv>(self);

    const setQueryParams = (filterParams: Partial<FilterProduct>) => {
      applySnapshot(self.filterParams, { ...DefaultProductFilterParams, ...filterParams });
    };

    const getProductsAsync = flow(function* (keyword?: string) {
      const loadingId = load('Get All Products Async');
      try {
        const categoryId = ((self.getQueryParams?.categoryIds || []) as string[]).join(',');
        const brandId = ((self.getQueryParams?.brandIds || []) as string[]).join(',');

        const response = yield httpInstance.get(PRODUCT_API.GET_PRODUCTS, {
          params: {
            ...self.getPaginationParams,
            ...self.getQueryParams,
            categoryId,
            brandId,
            keyword: keyword || self.getQueryParams?.keyword,
          },
        });
        applySnapshot(self.productItems, response.data);
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getTopNewProductsAsync = flow(function* () {
      const loadingId = load('Get Top New Products Async');
      try {
        const response = yield httpInstance.get(PRODUCT_API.GET_TOP_PRODUCTS, {
          params: {
            pageSize: Pagination.PAGE_SIZE_LOAD_MORE * 3,
            pageNumber: 1,
          },
        });

        applySnapshot(self.listProductItems, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getListBannersAsync = flow(function* () {
      const loadingId = load('Get List Banners Async');
      try {
        self.bannerLoading = true;
        const response = yield httpInstance.get(CATALOG_API.GET_LIST_BANNERS, {
          params: {
            pageSize: Pagination.PAGE_SIZE,
            pageNumber: 1,
            enabled: true,
          },
        });

        applySnapshot(self.listBanners, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        self.bannerLoading = false;
        loaded(loadingId);
      }
    });

    const postContactUsAsync = flow(function* (data: ContactUsInputModel, cb?: RequestCallback) {
      const loadingId = load('Post Contact Us Async');
      try {
        yield httpInstance.post(USERS_API.POST_CONTACT_US, data);

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      setQueryParams,
      postContactUsAsync,
      getProductsAsync,
      getTopNewProductsAsync,
      getListBannersAsync,
    };
  });

export default HomeStore;
export type HomeStoreInstance = Instance<typeof HomeStore>;
