import { types, SnapshotOut } from '@vklink/libs-state';

export const CategoryModel = types.model('Category  Model', {
  id: types.identifier,
  name: types.string,
  parent: types.maybeNull(
    types.model({
      id: types.identifier,
      name: types.string,
    })
  ),
});

export interface Category extends SnapshotOut<typeof CategoryModel> {}
