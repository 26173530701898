import { SnapshotOut, types } from '@vklink/libs-state';

export const AddToCartModel = types.model('Add To Cart Model', {
  cartId: types.maybeNull(types.string),
  productId: types.string,
  quantity: types.number,
  attributeValueIds: types.array(types.string),
});

export interface AddToCart extends SnapshotOut<typeof AddToCartModel> {}
