import { PaymentStatus } from 'enums';

export const PaymentStatusOptions: SelectOption[] = [
  {
    label: 'pages:payment.paid',
    value: PaymentStatus.PAID,
  },
  {
    label: 'pages:payment.unpaid',
    value: PaymentStatus.UN_PAID,
  },
  {
    label: 'pages:payment.waitingPayment',
    value: PaymentStatus.WAITING,
  },
];
