const API_V1_AUTHENTICATION = '/id/api/v1/account';

export const AUTHENTICATION_API = {
  POST_LOGIN: `${API_V1_AUTHENTICATION}`,
  POST_REGISTRATION: `${API_V1_AUTHENTICATION}/register`,
  POST_CONFIRM_EMAIL: `${API_V1_AUTHENTICATION}/register/confirm`,

  POST_CHANGE_EMAIL_PHONE: `${API_V1_AUTHENTICATION}/change-email-phone`,
  POST_CHANGE_EMAIL_PHONE_CONFIRM: `${API_V1_AUTHENTICATION}/change-email-phone/confirm`,
  POST_CHANGE_NEW_EMAIL_PHONE: `${API_V1_AUTHENTICATION}/change-new-email-phone`,
  POST_CHANGE_NEW_EMAIL_PHONE_CONFIRM: `${API_V1_AUTHENTICATION}/change-new-email-phone/confirm`,

  POST_ADD_EMAIL_PHONE: `${API_V1_AUTHENTICATION}/add-email-phone`,
  POST_ADD_EMAIL_PHONE_CONFIRM: `${API_V1_AUTHENTICATION}/add-email-phone/confirm`,

  POST_CHANGE_PASSWORD: `${API_V1_AUTHENTICATION}/change-password`,
};
