import { Outlet } from 'react-router';

import { Box } from '@mui/material';
import {
  // TopLoading,
  Footer,
  AppHeader,
  CtaArea,
  FloatingTopButton,
  BackdropLoading,
} from './components';

const MasterLayout = () => {
  return (
    <>
      {/* <TopLoading /> */}
      <AppHeader />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: 'hidden',
          backgroundColor: 'common.white',
          minHeight: 'calc(100vh - 421px)',
        }}
      >
        <Outlet />
      </Box>
      <CtaArea />
      <Footer />

      <FloatingTopButton />
      <BackdropLoading />
    </>
  );
};

export default MasterLayout;
