import React from 'react';

import { Stack, IconButton, Box } from '@mui/material';
import { ArrowBackOutlined, CloseOutlined } from '@mui/icons-material';

type Props = {
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;

  title?: React.ReactNode;

  onBack?: () => void;
  onClose?: () => void;
};

const StickyDialogHeader = (props: Props) => {
  return (
    <Stack
      direction={'row'}
      sx={{
        position: 'sticky',
        top: 0,
        background: (theme) => theme.palette.common.white,
        zIndex: (theme) => theme.zIndex.appBar,
        boxShadow: 2,
        alignItems: 'center',
        py: 1,
      }}
    >
      {!!props.onBack && (
        <IconButton
          onClick={props.onBack}
          sx={{
            background: (theme) => theme.palette.common.white,
          }}
        >
          <ArrowBackOutlined />
        </IconButton>
      )}
      {!props.onBack && !!props.startAdornment && props.startAdornment}
      <Box sx={{ flexGrow: 1, textAlign: 'center' }}>{!!props.title && props.title}</Box>

      {!!props.onClose && (
        <IconButton
          onClick={props.onClose}
          sx={{
            background: (theme) => theme.palette.common.white,
          }}
        >
          <CloseOutlined />
        </IconButton>
      )}
      {!props.onClose && !!props.endAdornment && props.endAdornment}
    </Stack>
  );
};

export default StickyDialogHeader;
