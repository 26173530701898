import { NavLink, useNavigate } from 'react-router-dom';

import { Remove, Add, CloseOutlined, SearchOutlined, ManageSearch } from '@mui/icons-material';
import { Typography, Stack, IconButton, Collapse, Box, Drawer, Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useDisclosure } from '@vklink/components-shared';
import { useForm, TextField } from '@vklink/components-form';
import { observer } from '@vklink/libs-state';
import { useAuthStore } from '@vklink/libs-auth';
import { useRootStore } from 'stores';
import { AuthenticationRoutes, HomeRoutes, MyProfileRoutes } from '../routers';
import { useSignIn } from '../hook';
import { SubCustomerCareList } from 'constant';

import avatar from 'assets/avatar-default.jpeg';
import logoVKLink from 'assets/logo-ecommerce.svg';
import { useI18n } from '@vklink/libs-i18n';

const AppSidebarButton = () => {
  const { t } = useI18n(['components', 'common', 'pages']);
  const MyAccountItems = [
    { label: t('pages:myAccount.profile'), href: MyProfileRoutes.EDIT_PROFILE },
    { label: t('pages:orders.orders'), href: MyProfileRoutes.PURCHASE_ORDER },
  ];

  const authStore = useAuthStore();
  const { commonStore } = useRootStore();
  const navigator = useNavigate();
  const { isOpen: isOpenDrawer, open: openDrawer, close: closeDrawer } = useDisclosure();
  const { signInAsync } = useSignIn();

  const buildCategorySearchUrl = (cateId: string) => {
    return `${HomeRoutes.SEARCH}?categoryIds[]=${cateId}`;
  };

  const handleLogout = () => {
    commonStore.resetCart();

    authStore.signoutAsync();
  };

  const renderCategoryList = (list: any[]): any[] => {
    return list.map((item) => {
      const haveChildren = !!item.children?.length;

      return {
        label: item.label,
        href: buildCategorySearchUrl(item.value),
        onClick: closeDrawer,
        subs: haveChildren ? renderCategoryList(item.children) : undefined,
      };
    });
  };

  return (
    <>
      <IconButton
        sx={{
          color: 'common.white',
          pl: 0,
        }}
        onClick={openDrawer}
      >
        <ManageSearch sx={{ fontSize: 30 }} />
      </IconButton>

      <Drawer
        anchor={'right'}
        open={isOpenDrawer}
        onClose={closeDrawer}
        sx={{
          zIndex: (theme) => theme.zIndex.modal + 1,
          '& .MuiPaper-root': {
            backgroundColor: (theme) => theme.palette.common.white,
            width: '100%',
            maxWidth: 450,
            overflow: 'hidden',
          },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: 'primary.main',
            p: {
              xs: 2,
              sm: 3,
            },
            mb: 2,
          }}
        >
          <Box
            component="img"
            sx={{
              height: 50,
              cursor: 'pointer',
              objectFit: 'contain',
              alignSelf: 'flex-start',
            }}
            alt="Logo VKLink."
            src={logoVKLink}
            onClick={() => navigator('/')}
          />
          <IconButton
            sx={{
              marginLeft: 'auto',
              backgroundColor: 'warning.main',
              color: 'common.white',
              ':hover': {
                backgroundColor: 'common.black',
              },
            }}
            onClick={closeDrawer}
          >
            <CloseOutlined />
          </IconButton>
        </Stack>

        <Stack
          sx={{
            flex: 1,
            p: {
              xs: 3,
              sm: 5,
            },
            pt: '0 !important',
            overflowY: 'auto',
          }}
        >
          <SearchInput closeDrawer={closeDrawer} />

          {authStore.isAuthenticated ? (
            <DrawerCollapseItem
              avatar={() => {
                return (
                  <Avatar
                    sx={{ width: 40, height: 40 }}
                    alt="user-avatar"
                    src={commonStore.userInformation?.avatar ?? avatar}
                  />
                );
              }}
              label={t('common:user.myAccount')}
              subs={[
                ...MyAccountItems.map((item) => ({
                  label: item.label,
                  href: item.href,
                  onClick: closeDrawer,
                })),
                {
                  label: t('common:actions.logout'),
                  onClick: () => {
                    closeDrawer();
                    handleLogout();
                  },
                },
              ]}
            />
          ) : (
            <DrawerCollapseItem
              label={t('common:user.account')}
              subs={[
                {
                  label: t('common:actions.signIn'),
                  onClick: () => {
                    closeDrawer();
                    signInAsync();
                  },
                },
                {
                  label: t('common:actions.signUp'),
                  href: AuthenticationRoutes.SIGN_UP,
                  onClick: closeDrawer,
                },
              ]}
            />
          )}

          <DrawerCollapseItem
            label="All Categories"
            subs={renderCategoryList(commonStore.getHierarchyCategoryOptions)}
          />

          <DrawerCollapseItem
            label="Contact Us"
            href={HomeRoutes.CONTACT_US}
            onClick={closeDrawer}
          />

          <DrawerCollapseItem
            label={t('components:sideBar.aboutUs')}
            subs={SubCustomerCareList.map((item) => {
              return {
                label: t(item.title as any),
                href: item.href || HomeRoutes.ROOT,
                onClick: closeDrawer,
              };
            })}
          />
        </Stack>
      </Drawer>
    </>
  );
};

export default observer(AppSidebarButton);

type DrawerCollapseItemProps = {
  level?: number;
  label: string;
  href?: string;
  onClick?: () => void;
  subs?: Omit<DrawerCollapseItemProps, 'level'>[];
  isSub?: boolean;
  avatar?: () => React.ReactNode;
};

const DrawerCollapseItem = observer((props: DrawerCollapseItemProps) => {
  const level = props.level || 0;
  const { isOpen, toggle } = useDisclosure(level < 1);
  const haveChildren = props.subs && !!props.subs.length;
  const label = (
    <Typography
      onClick={props.onClick}
      sx={{
        fontWeight: props.isSub ? undefined : 'bold',
        color: 'common.black',
        ':hover': {
          color: 'warning.main',
        },
      }}
    >
      {props.label}
    </Typography>
  );

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          borderBottomWidth: 1,
          borderColor: 'grey.300',
          borderBottomStyle: 'solid',
          py: 1,
          pl: level * 3,
        }}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          {!!props.avatar && props.avatar()}
          {props.href ? (
            <NavLink
              style={{
                textDecoration: 'none',
                backgroundColor: 'transparent',
              }}
              end
              to={props.href}
            >
              {label}
            </NavLink>
          ) : (
            label
          )}
        </Stack>
        {haveChildren && (
          <IconButton
            sx={{
              backgroundColor: 'common.white',
              color: 'common.black',
              // border: (theme) => `1px solid ${theme.palette.grey[300]}`,
              borderColor: 'grey.300',
              borderRadius: 0,
              width: 32,
              height: 32,
              ':hover': {
                backgroundColor: 'warning.main',
              },
            }}
            onClick={toggle}
          >
            {isOpen ? <Remove /> : <Add />}
          </IconButton>
        )}
      </Stack>

      {haveChildren && (
        <Collapse in={isOpen}>
          {props.subs?.map((item, idx) => {
            return <DrawerCollapseItem key={idx} {...item} level={level + 1} isSub />;
          })}
        </Collapse>
      )}
    </Stack>
  );
});

const SearchInput = observer((props: { closeDrawer: () => void }) => {
  const theme = useTheme();
  const navigator = useNavigate();
  const { control, handleSubmit } = useForm<{ search: string }>({
    defaultValues: { search: '' },
  });

  const onSearch = (dataSearch: { search: string }) => {
    props.closeDrawer();
    navigator(HomeRoutes.SEARCH + `?keyword=${dataSearch.search}`);
  };

  return (
    <Stack sx={{ mb: 2 }} component={'form'} onSubmit={handleSubmit(onSearch)}>
      <TextField
        control={control}
        name="search"
        sx={{
          '& .MuiOutlinedInput-root': {
            flex: 1,
            pr: 0,
            backgroundColor: (theme) => theme.palette.common.white,
            borderRadius: 1,
          },
        }}
        autoComplete="off"
        placeholder="Search products by names, categories, and brands"
        endAdornment={
          <IconButton
            type="submit"
            sx={{
              // backgroundColor: theme.palette.warning.main,
              borderRadius: 0,
              borderTopRightRadius: 'inherit',
              borderBottomRightRadius: 'inherit',
              ':hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.warning.main,
              },
            }}
            onClick={handleSubmit(onSearch)}
          >
            <SearchOutlined />
          </IconButton>
        }
      />
    </Stack>
  );
});
