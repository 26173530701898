import { useMemo, useEffect } from 'react';
import { Outlet } from 'react-router';

import { Box, Container, Grid } from '@mui/material';
import { MenuBar } from '../components';
import { useRootStore } from 'stores';
import { ProfileStore, ProfileStoreInstance, ProfileStoreProvider } from '../store';
import { createStore, getEnv } from '@vklink/libs-state';

const MyProfileLayout = () => {
  const rootStore = useRootStore();
  const initialState: ProfileStoreInstance = useMemo(() => {
    return createStore(ProfileStore, undefined, {
      ...getEnv(rootStore),
      load: rootStore.loadingStore.load,
      loaded: rootStore.loadingStore.loaded,
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ProfileStoreProvider value={initialState}>
      <Container sx={{ pb: 6, pt: 4 }}>
        <Grid
          container
          spacing={{
            xs: 0,
            md: 2,
            lg: 3,
          }}
        >
          <Grid item xs={12} md={3} display={{ xs: 'none', md: 'block' }}>
            <MenuBar />
          </Grid>
          <Grid item xs={12} md={9}>
            <Box
              sx={{
                p: { md: 2 },
                boxShadow: { md: 2 },
              }}
            >
              <Outlet />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ProfileStoreProvider>
  );
};

export default MyProfileLayout;
