import { RootStoreEnv } from 'stores';
import { types, getEnv, flow } from '@vklink/libs-state';
import { PAYMENT_API } from 'api/src/payment';
import { BankTransfer, OnePay } from './models';
import { ORDER_API } from 'api';

export type PaymentStoreEnv = RootStoreEnv & {
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

export const PaymentStore = types
  .model('Payment Store', {
    returnUrl: types.optional(types.string, ''),
    isApplyPoint: types.optional(types.boolean, false),
  })

  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<PaymentStoreEnv>(self);

    const setApplyPoint = (value: boolean) => {
      self.isApplyPoint = value;
    };

    const postOnePayPaymentAsync = flow(function* (data: OnePay, cb?: RequestCallback) {
      const loadingId = load('Post OnePay Payment Async');
      try {
        const response = yield httpInstance.post(PAYMENT_API.POST_ONE_PAY_PAYMENT, { ...data });

        self.returnUrl = response.data.url;
        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const postBankTransferInformationAsync = flow(function* (
      data: BankTransfer,
      cb?: RequestCallback
    ) {
      const loadingId = load('Post Bank Transfer Information Async');
      try {
        yield httpInstance.post(ORDER_API.POST_BANK_TRANSFER_INFORMATION, {
          ...data,
        });

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getOnepayCallbackAsync = flow(function* (data: string) {
      const loadingId = load('Get Onepay Callback Async');
      try {
        yield httpInstance.get(PAYMENT_API.GET_ONEPAY_CALLBACK + data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      setApplyPoint,
      postBankTransferInformationAsync,
      postOnePayPaymentAsync,
      getOnepayCallbackAsync,
    };
  });
