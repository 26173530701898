import { SnapshotOut, types } from '@vklink/libs-state';
import { PaymentMethods } from 'enums';

export const OrderPaymentInformationModel = types.model('Order Item Model', {
  id: types.identifier,
  inputPrice: types.number,
  paymentMethod: types.enumeration<PaymentMethods>('PaymentMethods', Object.values(PaymentMethods)),
});

export interface OrderPaymentInformation extends SnapshotOut<typeof OrderPaymentInformationModel> {}
