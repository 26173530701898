import { types, SnapshotOut } from '@vklink/libs-state';

export const SettingKeyModel = types.model('Setting Key Model', {
  id: types.identifier,
  settingType: types.string,
  key: types.string,
  value: types.string,
  description: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
});

export interface SettingKey extends SnapshotOut<typeof SettingKeyModel> {}
