import { createStoreContext } from '@vklink/libs-state';

import { type AddressCatalogStoreInstance } from './AddressCatalogStore';

const [AddressCatalogStoreProvider, useAddressCatalogStore] =
  createStoreContext<AddressCatalogStoreInstance>();

export { AddressCatalogStoreProvider, useAddressCatalogStore };

export {
  default as AddressCatalogStore,
  type AddressCatalogStoreEnv,
  type AddressCatalogStoreInstance,
} from './AddressCatalogStore';

export * from './PaginationStore';
export * from './UploadFileStore';
