import { Box, SxProps, Typography, Theme } from '@mui/material';
import { PromotionType } from 'enums';
import { formatCurrency } from 'pages/shared/utils';
import { useI18n } from '@vklink/libs-i18n';

type Props = {
  content: PromotionType;
  point?: number | null;
  sx?: SxProps<Theme>;
};

const ProductPromotion = (props: Props) => {
  const { content, sx, point } = props;
  const { t } = useI18n(['pages']);

  const conditionRenderContent = () => {
    if (content && content !== PromotionType.OTHER) return true;
    return;
  };

  const getPoint = () => {
    if (!!point) return formatCurrency(point, 0, 3);
    return;
  };

  const getLabelByContent = () => {
    switch (content) {
      case PromotionType.GIFT:
        return t('pages:orders.freeGift');
      case PromotionType.POINT:
        return t('pages:products.freeQuantityPoint', { quantity: getPoint() });
      default:
        return '';
    }
  };

  return (
    <Box
      component="span"
      sx={{
        width: 'fit-content',
        minHeight: 22,
        maxHeight: 22,
        ...sx,
      }}
    >
      {conditionRenderContent() && (
        <Typography
          component="span"
          variant="caption"
          className="text-product-promotion"
          sx={{
            lineHeight: 1,
            color: 'warning.main',
            border: 1,
            borderColor: 'warning.main',
            px: 0.5,
            py: 0.25,
            whiteSpace: 'nowrap',
          }}
        >
          {getLabelByContent()}
        </Typography>
      )}
    </Box>
  );
};

export default ProductPromotion;
