import { useMemo } from 'react';
import { Outlet } from 'react-router';

import { Box, Container } from '@mui/material';
import { RegisterStore, RegisterStoreInstance, RegisterStoreProvider } from './store';
import { useRootStore } from 'stores';
import { createStore, getEnv } from '@vklink/libs-state';

const RegisterLayout = () => {
  const rootStore = useRootStore();
  const initialState: RegisterStoreInstance = useMemo(() => {
    return createStore(RegisterStore, undefined, {
      ...getEnv(rootStore),
      load: rootStore.loadingStore.load,
      loaded: rootStore.loadingStore.loaded,
    });
  }, []);

  return (
    <RegisterStoreProvider value={initialState}>
      <Container sx={{ my: 8 }}>
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.common.white,
            margin: '0 auto',
            minWidth: { xs: 0, sm: 500 },
            maxWidth: 600,
            padding: {
              xs: 0,
              md: 4,
            },
          }}
        >
          <Outlet />
        </Box>
      </Container>
    </RegisterStoreProvider>
  );
};

export default RegisterLayout;
