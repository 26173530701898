import { types, SnapshotOut } from '@vklink/libs-state';

export const FilterProductModel = types.model('Product Model', {
  keyword: types.optional(types.string, ''),
  minPrice: types.maybeNull(types.number),
  maxPrice: types.maybeNull(types.number),
  categoryIds: types.optional(types.array(types.string), []),
  brandIds: types.optional(types.array(types.string), []),
  sortBy: types.maybeNull(types.string),
});

export const DefaultProductFilterParams: FilterProduct = {
  keyword: '',
  minPrice: null,
  maxPrice: null,
  categoryIds: [],
  brandIds: [],
  sortBy: '',
};

export interface FilterProduct extends SnapshotOut<typeof FilterProductModel> {}
