import { Box, Skeleton, SkeletonProps } from '@mui/material';
import { useDisclosure } from '@vklink/components-shared';

type Props = {
  src: string;
  alt: string;
  style?: React.CSSProperties;
  skeletonProps: SkeletonProps;
};

const LazyImg = (props: Props) => {
  const { isOpen: loading, close: endLoading } = useDisclosure(true);
  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100%' }} className="lazy-img">
      <img
        src={props.src}
        alt={props.alt}
        onLoad={() => {
          endLoading();
        }}
        loading="lazy"
        style={{
          animation: 'fadeInDownBig 1s',
          transitionDelay: '0.5s',
          objectFit: 'cover',
          width: '100%',
          height: '100%',
          ...props.style,
        }}
      />

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: loading ? 'block' : 'none',
        }}
      >
        <Skeleton className="img-loading" animation="wave" {...props.skeletonProps} />
      </Box>
    </Box>
  );
};

export default LazyImg;
