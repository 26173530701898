import { AddOutlined, RemoveOutlined } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { BaseInput } from '@vklink/components-shared';

export type ButtonAdjustmentProps = {
  quantity: number;
  increaseBtnDisabled?: boolean;
  increase: () => void;
  decrease: () => void;
  onChange?: (quantity: number) => void;
  onBlur?: (quantity: number) => void;
};

const MIN_QUANTITY = 1;
const MAX_QUANTITY = 100;

const ButtonAdjustment = (props: ButtonAdjustmentProps) => {
  return (
    <Box
      className="product-btn-adjustment"
      sx={{
        width: 'fit-content',
        height: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        border: (theme) => `1px solid ${theme.palette.grey[300]}`,

        '& .MuiButton-root': {
          color: 'common.black',

          '&.Mui-disabled': {
            color: 'grey.500',
          },
        },
      }}
    >
      <Button
        disabled={props.quantity < 2}
        sx={{
          flex: 1,
          minWidth: 20,
          boxShadow: (theme) => theme.shadows[0],
          borderRadius: 0,
          borderColor: 'grey.300',
        }}
        onClick={props.decrease}
      >
        <RemoveOutlined fontSize="small" />
      </Button>

      <Box
        sx={{
          flex: 1.5,

          '& .MuiOutlinedInput-root': {
            flex: 1,
            pr: 0,
            backgroundColor: (theme) => theme.palette.common.white,
            '& fieldset': {
              borderColor: 'transparent !important',
            },
          },
        }}
      >
        {!props.onChange ? (
          <Typography
            variant="body2"
            component="div"
            textAlign="center"
            height="100%"
            sx={{ minWidth: 30 }}
          >
            {props.quantity}
          </Typography>
        ) : (
          <BaseInput
            value={props.quantity}
            onChange={(e) => {
              const news = Number(e.target.value);

              if (Number.isInteger(news)) {
                props.onChange!(news);
              }
            }}
            onBlur={(e) => {
              const news = Number(e.target.value);

              if (news < MIN_QUANTITY) {
                props.onChange!(MIN_QUANTITY);
                props.onBlur && props.onBlur!(MIN_QUANTITY);
                return;
              } else if (news > MAX_QUANTITY) {
                props.onChange!(MAX_QUANTITY);
                props.onBlur && props.onBlur!(MAX_QUANTITY);
                return;
              }

              props.onBlur && props.onBlur!(news);
            }}
            sx={{
              '& input': {
                textAlign: 'center',
                p: '0 !important',
              },
            }}
          />
        )}
      </Box>

      <Button
        disabled={props.increaseBtnDisabled || props.quantity >= MAX_QUANTITY}
        sx={{
          flex: 1,
          minWidth: 20,
          boxShadow: (theme) => theme.shadows[0],
          borderRadius: 0,
          borderColor: 'grey.300',
        }}
        onClick={props.increase}
      >
        <AddOutlined fontSize="small" />
      </Button>
    </Box>
  );
};

export default ButtonAdjustment;
