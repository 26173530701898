import { types, SnapshotOut } from '@vklink/libs-state';
import { CartItemModel } from './CartItemModel';

export const CartModel = types.model('Cart Model', {
  id: types.identifier,
  customerId: types.string,
  customerName: types.string,
  cartItems: types.array(CartItemModel),
});

export interface Cart extends SnapshotOut<typeof CartModel> {}
