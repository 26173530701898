import { Button, Dialog, Box, Typography, Stack, ButtonProps } from '@mui/material';
import { useDisclosure, useBreakpoints } from '@vklink/components-shared';
import { ProductAvatar } from 'pages/shared/components';
// import { CameraAltOutlined, VideocamOutlined } from '@mui/icons-material';
import {
  useForm,
  yup,
  yupResolver,
  TextField,
  UploadField,
  RatingField,
} from '@vklink/components-form';
import {
  CreateOrderItemFeedback,
  DefaultOrderItemFeedbackValue,
} from 'pages/profile/store/models/index';
import { useEffect } from 'react';
import { OrderItemInstance } from 'pages/profile/store/models';
import ProductName from '../ProductCards/ProductName';

export type AddOrderItemFeedbackProps = {
  onClose?: () => void;
  onComplete: (data: CreateOrderItemFeedback) => void;
  size: 'small' | 'medium' | 'large';
  orderId: string;
  disabled?: boolean;
  orderItem: OrderItemInstance;

  sx?: ButtonProps['sx'];
};

const RatingButton = (props: AddOrderItemFeedbackProps) => {
  const { id, productImageUrl, productName, getAttributeValues, quantity, productId, productSlug } =
    props.orderItem;

  const validationSchema: yup.SchemaOf<CreateOrderItemFeedback> = yup
    .object()
    .shape({
      rate: yup.number().nullable().required('Rating is required').max(5).min(0).label('Rating'),
      comment: yup.string().required('Comment is required').max(500).label('Comment'),
      medias: yup.array().min(1, 'Image is required'),
    })
    .defined();

  const { control, handleSubmit, setValue } = useForm<CreateOrderItemFeedback>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: DefaultOrderItemFeedbackValue,
  });

  // const review = watch('review');

  useEffect(() => {
    setValue('orderId', props.orderId);
    setValue('itemId', id);
    return;
  }, []);

  // const defaultReviewTexts = [
  //   'Great product quanlity',
  //   'Product packaging is very nice and secure',
  //   'The store has very good service',
  //   'Very worth the money',
  //   'Very fast delivery time',
  // ];

  // const onClickReview = (review: string) => {
  //   let curReview = getValues('review');
  //   if (curReview && curReview != '') {
  //     const arr = curReview.split(',').map((element) => element.trim());
  //     const index = arr.indexOf(review);
  //     if (index < 0) {
  //       arr.push(review);
  //       curReview = arr.join(', ');
  //     } else {
  //       arr.splice(index, 1);
  //       curReview = arr.join(', ');
  //     }
  //   } else curReview = review;
  //   setValue('review', curReview);
  // };
  const { isOpen, toggle } = useDisclosure();
  const breakpoints = useBreakpoints();

  const handleSubmitRating = (data: CreateOrderItemFeedback) => {
    toggle();
    props.onComplete(data);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={toggle}
        size={props.size || 'medium'}
        disabled={props.disabled}
        color="warning"
        sx={props.sx}
      >
        Rating
      </Button>

      <Dialog
        open={isOpen}
        onClose={toggle}
        sx={{
          '& .MuiPaper-root': {
            minWidth: breakpoints.md ? 600 : '100%',
          },
        }}
      >
        <Box p={4} sx={{ backgroundColor: (theme) => theme.palette.common.white }}>
          <Typography variant="h6" component="p" pb={2} sx={{ fontWeight: 'bold' }}>
            Product Reviews
          </Typography>
          <Box>
            <Stack direction="row">
              <Box
                sx={{
                  width: 70,
                  height: 70,
                }}
                border={1}
                borderColor="grey.300"
                p={0.5}
                borderRadius={2}
              >
                <ProductAvatar
                  product={{
                    id: productId,
                    imageUrl: productImageUrl || '',
                    slug: productSlug,
                  }}
                />
              </Box>
              <Box ml={1} alignSelf="center">
                <ProductName name={productName} id={productId} slug={productSlug} />
                <Typography variant="body2" component="div" color="grey.400">
                  {getAttributeValues.toString()}
                </Typography>
                <Typography variant="body2" component="div" color="grey.400">
                  x {quantity}
                </Typography>
              </Box>
            </Stack>
            {/* <Box textAlign="center">
              {defaultReviewTexts.map((reviewText, index) => {
                const isSelected = (review || '').includes(reviewText);
                return (
                  <Button
                    key={index}
                    size="small"
                    variant={!isSelected ? 'outlined' : 'contained'}
                    color="secondary"
                    sx={{ borderRadius: 25, m: 0.5 }}
                    onClick={() => onClickReview(reviewText)}
                  >
                    {reviewText}
                  </Button>
                );
              })}
            </Box> */}
            <Stack spacing={2} borderColor="grey.300">
              <RatingField control={control} name="rate" sx={{ alignItems: 'center' }} />
              <TextField
                control={control}
                multiline
                rows={4}
                name="comment"
                label="Comment:"
                placeholder="Share more review about this product..."
              />
              <UploadField control={control} name="medias" multiple />
            </Stack>
            <Box display="flex" gap={2} py={2} justifyContent="flex-end">
              <Button
                type="reset"
                variant="outlined"
                sx={{
                  px: 4,
                  color: 'grey.600',
                  borderColor: 'grey.300',

                  ':hover': {
                    borderColor: 'grey.300',
                  },
                }}
                onClick={toggle}
              >
                Back
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="warning"
                sx={{
                  px: 4,
                }}
                onClick={handleSubmit(handleSubmitRating)}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default RatingButton;
