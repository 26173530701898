import { IconButton, Popover, Stack, Typography, Divider } from '@mui/material';
import { MoreVertOutlined } from '@mui/icons-material';
import { useAnchorElDisclosure, useBreakpoints } from '@vklink/components-shared';
import { useEffect } from 'react';
import MenuBar from './MenuBar';

type Props = {
  title: string | React.ReactNode;
  subTitle?: string;
};

const BlockHeader = (props: Props) => {
  const breakPoints = useBreakpoints();
  const { isOpen, open, close, anchorEl } = useAnchorElDisclosure();

  useEffect(() => {
    if (breakPoints.md && isOpen) {
      close();
    }
  }, [breakPoints.md]);

  return (
    <Stack
      sx={{
        mb: 2,
      }}
    >
      <Stack direction="row">
        <Stack sx={{ mr: 'auto' }}>
          {typeof props.title === 'string' ? (
            <Typography
              variant="h6"
              component="p"
              sx={{
                fontWeight: 'bold',
                fontSize: (theme) => theme.typography.fontSize * 1.5,
                color: 'primary.main',
                position: 'relative',

                ':after': {
                  position: 'absolute',
                  content: '""',
                  width: '100%',
                  height: 2.5,
                  background: (theme) => theme.palette.warning.main,
                  bottom: (theme) => ({
                    xs: theme.spacing(-1),
                    md: theme.spacing(0),
                  }),
                  left: 0,
                },
              }}
            >
              {props.title}
            </Typography>
          ) : (
            props.title
          )}
        </Stack>

        <IconButton
          sx={{
            visibility: !breakPoints.md ? undefined : 'hidden',
            backgroundColor: 'common.white',
            height: !breakPoints.md ? undefined : 0,
          }}
          onClick={open}
        >
          <MoreVertOutlined />
        </IconButton>
      </Stack>

      <Divider
        sx={{ mt: 0, mb: !!props.subTitle ? 1 : 0, backgroundColor: 'grey.300', width: '100%' }}
      />

      <Typography variant="body2" component="p" sx={{ color: 'grey.700' }}>
        {props.subTitle}
      </Typography>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuBar />
      </Popover>
    </Stack>
  );
};

export default BlockHeader;
