import { SnapshotIn, types } from '@vklink/libs-state';

export const AuthSettingsModel = types.model('Auth User', {
  authority: types.string,
  clientId: types.string,
  clientSecret: types.maybe(types.string),
  redirectUrl: types.string,
  silentRedirectUrl: types.string,
  postLogoutUrl: types.string,
  scopes: types.array(types.string),
  storePrefix: types.optional(types.string, 'oidc.'),
});

export interface AuthSettings extends SnapshotIn<typeof AuthSettingsModel> {}
