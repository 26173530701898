import { SnapshotOut, types, Instance, SnapshotIn } from '@vklink/libs-state';
import { TypeAddress } from 'enums';

export const UserShippingAddressModel = types.model('User Shipping Address Model', {
  id: types.identifier,
  userId: types.string,
  contactName: types.string,
  contactPhone: types.string,
  isDefault: types.boolean,
  fullAddress: types.string,
  address1: types.string,
  address2: types.string,
  wardId: types.string,
  wardCode: types.string,
  wardName: types.string,
  districtId: types.string,
  districtCode: types.string,
  districtName: types.string,
  provinceId: types.string,
  provinceCode: types.string,
  provinceName: types.string,
});

export const DefaultUserShippingAddressValue: Partial<UserShippingAddressInputModel> = {
  contactName: '',
  contactPhone: '',
  fullAddress: '',
  address1: TypeAddress.HOME,
  address2: '',
  wardId: '',
  wardCode: '',
  wardName: '',
  districtId: '',
  districtCode: '',
  districtName: '',
  provinceId: '',
  provinceCode: '',
  provinceName: '',
  isDefault: false,
};

export type CreateUserShippingAddress = Pick<
  UserShippingAddressInputModel,
  | 'contactName'
  | 'contactPhone'
  | 'fullAddress'
  | 'address1'
  | 'address2'
  | 'wardId'
  | 'wardName'
  | 'districtId'
  | 'districtName'
  | 'provinceId'
  | 'provinceName'
  | 'isDefault'
>;

export interface UserShippingAddressInstance extends Instance<typeof UserShippingAddressModel> {}
export interface UserShippingAddressInputModel
  extends SnapshotIn<typeof UserShippingAddressModel> {}
export interface UserShippingAddress extends SnapshotOut<typeof UserShippingAddressModel> {}
