import { SnapshotOut, types } from '@vklink/libs-state';

export const AttributeModel = types.model('Attribute Model', {
  attributeValueIds: types.array(types.string),
});

export const DefaultAttributeValue: Partial<Attribute> = {
  attributeValueIds: [],
};

export interface Attribute extends SnapshotOut<typeof AttributeModel> {}
