import { createStoreContext } from '@vklink/libs-state';

import { type RegisterStoreInstance } from './RegisterStore';

const [RegisterStoreProvider, useRegisterStore] = createStoreContext<RegisterStoreInstance>();

export { RegisterStoreProvider, useRegisterStore };

export {
  default as RegisterStore,
  type RegisterStoreEnv,
  type RegisterStoreInstance,
} from './RegisterStore';
