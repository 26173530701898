import { SnapshotOut, types } from '@vklink/libs-state';

export const MediaFeedbackModel = types.model('Product Feedback Model', {
  id: types.string,
  feedbackId: types.maybeNull(types.string),
  mediaIndex: types.string,
  name: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  path: types.maybeNull(types.string),
  extension: types.string,
  url: types.string,
  bucketName: types.maybeNull(types.string),
});

export const ProductFeedbackModel = types.model('Product Feedback Model', {
  id: types.string,
  rate: types.number,
  comment: types.string,
  customerId: types.string,
  customerName: types.string,
  username: types.string,
  customerAvatar: types.maybeNull(types.string),
  createdAt: types.string,
  medias: types.array(MediaFeedbackModel),
});

export interface ProductFeedback extends SnapshotOut<typeof ProductFeedbackModel> {}
export interface MediaFeedback extends SnapshotOut<typeof MediaFeedbackModel> {}
