import { generatePath } from 'react-router-dom';

import { SnapshotOut, types, getSnapshot, getEnv, flow, applySnapshot } from '@vklink/libs-state';
import { OrderStatus, Pagination } from 'enums';
import { PaginationStore } from 'pages/shared/stores';
import {
  DefaultOrderFilterParams,
  OrderFilterParams,
  OrderFilterParamsModel,
  OrderModel,
  OrderStatusModel,
} from './models';
import { ORDER_API } from 'api';
import { removeEmptyInObject } from 'pages/shared/utils';
import { OrderStatusWithoutCancelledOptions, OrderStatusCancelledOptions } from 'constant';
import { RootStoreEnv } from 'stores';
import { OrderFeedbackStore } from './OrderFeedbackStore';

export type ProfileStoreEnv = RootStoreEnv & {
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

export const ListOrdersStore = types
  .compose(
    PaginationStore,
    OrderFeedbackStore,
    types.model('List Order Store', {
      listOrders: types.array(OrderModel),
      orderDetail: types.maybeNull(OrderModel),
      filterParams: types.optional(OrderFilterParamsModel, DefaultOrderFilterParams),
      numberOrderStatuses: types.array(OrderStatusModel),
    })
  )
  .views((self) => {
    return {
      get getQueryParams() {
        return removeEmptyInObject({
          ...self.filterParams,
        });
      },
      get getListOrders() {
        return getSnapshot(self.listOrders);
      },
      get getStepActions() {
        if (self.orderDetail?.status === OrderStatus.CANCEL) {
          return OrderStatusCancelledOptions.map((el) => el.label);
        } else {
          return OrderStatusWithoutCancelledOptions.map((el) => el.label);
        }
      },
      get getNumberOrderStatuses() {
        return getSnapshot(self.numberOrderStatuses);
      },
    };
  })
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<ProfileStoreEnv>(self);

    const setQueryParams = (filterParams: OrderFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultOrderFilterParams, ...filterParams });
    };

    const getMyOrdersAsync = flow(function* () {
      const loadingId = load('Get All Products Async');
      try {
        const response = yield httpInstance.get(ORDER_API.GET_ORDERS, {
          params: {
            ...self.getQueryParams,
            pageNumber: self.getPaginationInfo.pageNumber,
            pageSize: Pagination.PAGE_SIZE_LOAD_MORE,
          },
        });
        if (self.getPaginationInfo.pageNumber === 1) {
          applySnapshot(self.listOrders, response.data);
        } else {
          applySnapshot(self.listOrders, [...self.listOrders, ...response.data]);
        }
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getOrderDetailByIdAsync = flow(function* (id: string) {
      const loadingId = load('Get Order Detail Async');
      try {
        const url = generatePath(ORDER_API.GET_ORDER_DETAIL, { id });
        const response = yield httpInstance.get(url);

        self.orderDetail = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const updateOrderStatusAsync = flow(function* (
      id: string,
      status: string,
      cb?: RequestCallback
    ) {
      const loadingId = load('Update Order Status Async');
      try {
        const url = generatePath(ORDER_API.PUT_ORDER_STATUS, { id });
        yield httpInstance.put(url, { status });

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getOrderStatusesByUserAsync = flow(function* () {
      const loadingId = load('Get Order Statuses By User Async');
      try {
        const response = yield httpInstance.get(ORDER_API.GET_ORDER_STATUSES_BY_USER);

        applySnapshot(self.numberOrderStatuses, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      setQueryParams,
      getMyOrdersAsync,
      getOrderDetailByIdAsync,
      updateOrderStatusAsync,
      getOrderStatusesByUserAsync,
    };
  });

export interface ListOrders extends SnapshotOut<typeof ListOrdersStore> {}
