import { types, SnapshotIn } from '@vklink/libs-state';

export const ContactUsModel = types.model('Contact Us Model', {
  name: types.string,
  email: types.string,
  phone: types.string,
  subject: types.string,
  message: types.string,
  address: types.string,
});

export const DefaultContactUsValue: ContactUsInputModel = {
  name: '',
  email: '',
  phone: '',
  subject: '',
  message: '',
  address: '',
};

export interface ContactUsInputModel extends SnapshotIn<typeof ContactUsModel> {}
