export const CancelOptions = [
  {
    value: 1,
    label: 'pages:orders.changeShipping',
  },
  {
    value: 2,
    label: 'pages:orders.notRespondRequests',
  },
  {
    value: 3,
    label: 'pages:orders.changeMind',
  },
  {
    value: 4,
    label: 'pages:orders.complicatedPayment',
  },
  {
    value: 5,
    label: 'pages:orders.findCheaperPrice',
  },
  {
    value: 6,
    label: 'pages:orders.changeMyMind',
  },
  {
    value: 7,
    label: 'pages:orders.otherViolations',
  },
];
