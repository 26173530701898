import { flow, getEnv, types } from '@vklink/libs-state';
import { ORDER_API } from 'api';
import { generatePath } from 'react-router';
import { RootStoreEnv } from 'stores';
import { CreateOrderItemFeedback, OrderItemFeedbackModel } from './models';

export type ProfileStoreEnv = RootStoreEnv & {
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

export const OrderFeedbackStore = types
  .model('Order feedback Store', {
    feedback: types.maybeNull(OrderItemFeedbackModel),
  })
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<ProfileStoreEnv>(self);

    const addOrderItemFeedbackAsync = flow(function* (
      feedback: CreateOrderItemFeedback,
      cb?: RequestCallback
    ) {
      const loadingId = load('Add Order Item feedback Async');
      try {
        const url = generatePath(ORDER_API.ADD_ORDER_ITEM_FEEDBACK, {
          id: feedback.orderId,
          itemId: feedback.itemId,
        });

        yield httpInstance.post(url, {
          ...feedback,
        });

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      addOrderItemFeedbackAsync,
    };
  });
