import { generatePath } from 'react-router-dom';
import { useNavigate } from 'react-router';

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from '@mui/material';

import { ProductRoutes } from '../../routers';
import { ProductItem } from '../../models';
import { formatCurrency } from '../../utils';
import QuickViewButton from './QuickViewButton';
import DiscountTag from './DiscountTag';
import ProductName from './ProductName';
import ReadOnlyProductRating from './ReadOnlyProductRating';
import ProductPromotion from './ProductPromotion';

const HoverProductCard = (props: ProductItem) => {
  const navigator = useNavigate();

  const handleNavigateToProductDetail = () => {
    navigator(generatePath(ProductRoutes.ROOT, { id: props.id.toString(), slug: props.slug }));
  };

  return (
    <Box
      sx={{
        pb: 2,
        px: 1,
        '& .outer-spacer': {
          transition: '0.5s',
          height: 48,
        },

        '& .inner-spacer': {
          transition: '0.5s',
          height: 0,
        },

        '&:hover': {
          '& .outer-spacer': {
            height: 0,
          },

          '& .inner-spacer': {
            height: 48,
          },

          '& .MuiPaper-root': {
            boxShadow: (theme) => theme.shadows[9],
          },

          '& .product__add-cart': {
            opacity: 1,
            visibility: 'visible',
            bottom: 16,
          },
        },
      }}
    >
      <Card
        elevation={9}
        sx={{
          backgroundColor: (theme) => theme.palette.common.white,
          position: 'relative',
          boxShadow: (theme) => theme.shadows[0],
        }}
      >
        {props.discountPercent && <DiscountTag discountPercent={props.discountPercent} />}

        <CardActionArea onClick={handleNavigateToProductDetail}>
          <CardMedia
            component="img"
            image={props.media?.url}
            alt={props.media?.url}
            sx={{ cursor: 'pointer', maxHeight: 276, objectFit: 'contain', minHeight: 276 }}
            loading="lazy"
          />
          <CardContent
            sx={{
              borderTop: '1px solid #E3E9EF',
              p: 2,
            }}
            component={Stack}
            direction={'column'}
          >
            <ProductName name={props.name || ''} id={props.id} slug={props.slug} numOfLines={2} />
            <ReadOnlyProductRating sx={{ my: 0.5 }} rating={props.ratingAvg} />
            <ProductPromotion content={props.promotionType} />
            <Box mt={1}>
              <Typography component="span" sx={{ fontWeight: 'bold', color: 'common.black' }}>
                ₫{formatCurrency(props.sellPrice, 0, 3)}
              </Typography>
              <Typography
                variant="body2"
                component="span"
                color="gray"
                ml={2}
                sx={{ textDecoration: 'line-through' }}
              >
                ₫{formatCurrency(props.regularPrice, 0, 3)}
              </Typography>
            </Box>
            <div className="inner-spacer" />
          </CardContent>
        </CardActionArea>

        <Stack
          sx={{
            px: 2,
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 60,
            transition: '0.5s',
            visibility: 'hidden',
            opacity: 0,
            backgroundColor: 'transparent',
          }}
          className="product__add-cart"
        >
          <QuickViewButton productId={props.id} />
        </Stack>
      </Card>
      <div className="outer-spacer" />
    </Box>
  );
};

export default HoverProductCard;
