export const removeEmptyInObject = (obj: any) => {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null && v !== ''));
};

export const formatCurrency = (currency: number | string, n: number, x: number) => {
  const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
  return Number(currency)
    .toFixed(Math.max(0, ~~n))
    .replace(new RegExp(re, 'g'), '$&,');
};

export const onlyUniqueForOptions = (array: any) => {
  const result: any[] = [];
  array.filter((item: any) => {
    const i = result.findIndex((x) => x.label == item.label);
    if (i <= -1) {
      result.push({ value: item.value, label: item.label });
    }
    return null;
  });
  return result;
};

export const minusTwoDate = (date1: Date, date2: Date) => {
  const diff = date1.getTime() - date2.getTime();
  return Math.ceil(diff / (1000 * 60 * 60 * 24)) - 1;
};

export const getYesterday = () => {
  const yesterday = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24);
  return yesterday;
};

export const countTotalInArray = (array: any, key: string) => {
  return array.reduce((a: any, b: any) => a + (b[key] || 0), 0);
};

//sum similar products in array
export const sumSimilarProductInArray = (array: any[]) => {
  return Array.from(
    array.reduce(
      (m, { goodsCode, quantity }) => m.set(goodsCode, (m.get(goodsCode) || 0) + quantity),
      new Map()
    ),
    ([goodsCode, quantity]) => ({ goodsCode, quantity })
  );
};

export const arrayUniqueByKey = (array: any[], key: string) => {
  return [...new Map(array.map((item) => [item[key], item])).values()];
};
