import { SnapshotOut, types } from '@vklink/libs-state';

export const PaginationModel = types.model({
  totalPages: types.optional(types.number, 0),
  totalItems: types.optional(types.number, 0),
  pageNumber: types.optional(types.number, 1),
  pageSize: types.optional(types.number, 12),
  hasNextPage: types.optional(types.boolean, false),
  hasPreviousPage: types.optional(types.boolean, false),
});

export const DefaultPaginationInfo: Pagination = {
  totalItems: 0,
  totalPages: 0,
  pageNumber: 1,
  pageSize: 20,
  hasNextPage: false,
  hasPreviousPage: false,
};

export interface Pagination extends SnapshotOut<typeof PaginationModel> {}
