import { generatePath } from 'react-router-dom';
import { useNavigate } from 'react-router';

import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Stack,
  Typography,
  Box,
} from '@mui/material';
import { ProductRoutes } from '../routers';
import { ProductItem } from '../models';
import { formatCurrency } from '../utils';
import DiscountTag from './ProductCards/DiscountTag';
import ReadOnlyProductRating from './ProductCards/ReadOnlyProductRating';
import ProductName from './ProductCards/ProductName';

const ProductItem = (props: ProductItem) => {
  const navigator = useNavigate();

  const handleNavigateToProductDetail = () => {
    navigator(generatePath(ProductRoutes.ROOT, { id: props.id.toString(), slug: props.slug }));
  };

  return (
    <Card
      elevation={9}
      sx={{
        mx: 1,
        backgroundColor: (theme) => theme.palette.common.white,
        position: 'relative',
        boxShadow: (theme) => theme.shadows[0],
      }}
    >
      {props.discountPercent && (
        <DiscountTag
          discountPercent={props.discountPercent}
          sx={{
            top: 10,
          }}
        />
      )}

      <CardActionArea onClick={handleNavigateToProductDetail}>
        <CardMedia
          component="img"
          image={props.media?.url}
          alt={props.media?.url}
          sx={{ cursor: 'pointer', maxHeight: 276, objectFit: 'contain' }}
          loading="lazy"
        />
        <CardContent
          sx={{
            borderTop: '1px solid #E3E9EF',
            p: 1,
          }}
          component={Stack}
          direction={'column'}
        >
          <ProductName name={props.name || ''} id={props.id} slug={props.slug} numOfLines={2} />
          <Box
            sx={{
              my: 0.5,
              '& .rating-stars': {
                fontSize: 15,
              },
            }}
          >
            <ReadOnlyProductRating rating={props.ratingAvg} />
          </Box>
          <Stack direction="row" flexWrap="wrap-reverse">
            <Typography
              variant="body2"
              component="span"
              color="primary.main"
              mr={2}
              fontWeight="bold"
            >
              ₫{formatCurrency(props.sellPrice, 0, 3)}
            </Typography>

            <Typography
              variant="caption"
              component="span"
              color="grey.600"
              sx={{ textDecoration: 'line-through' }}
            >
              ₫{formatCurrency(props.regularPrice, 0, 3)}
            </Typography>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ProductItem;
