import { SnapshotIn, SnapshotOut, types } from '@vklink/libs-state';

export const CouponModel = types.model('Coupon Model', {
  name: types.string,
  code: types.string,
  type: types.string,
  value: types.number,
});

export type ApplyCoupon = Pick<Coupon, 'code'>;

export interface Coupon extends SnapshotOut<typeof CouponModel> {}
export interface CouponInputModel extends SnapshotIn<typeof CouponModel> {}
