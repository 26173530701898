import { OidcClient, User, UserManager, UserProfile, WebStorageStateStore } from 'oidc-client-ts';

import {
  types,
  flow,
  applySnapshot,
  Instance,
  createStore,
  createStoreContext,
} from '@vklink/libs-state';

import { AuthSettings, AuthSettingsModel } from './models/AuthSettingsModel';
import { AuthUserModel } from './models/AuthUserModel';

export const AuthStore = types
  .model('Auth Store', {
    idToken: types.optional(types.string, ''),
    accessToken: types.optional(types.string, ''),
    refreshToken: types.optional(types.string, ''),
    authUser: types.maybeNull(AuthUserModel),
    authSettings: AuthSettingsModel,

    loading: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get isAuthenticated() {
      return !!self.accessToken;
    },
  }))
  .actions((self) => {
    const oidc = new UserManager({
      authority: self.authSettings.authority,
      client_id: self.authSettings.clientId,
      client_secret: self.authSettings.clientSecret,
      redirect_uri: self.authSettings.redirectUrl,
      silent_redirect_uri: self.authSettings.silentRedirectUrl,
      post_logout_redirect_uri: self.authSettings.postLogoutUrl,
      scope: self.authSettings.scopes.join(' '),
      response_type: 'code',
      response_mode: 'query',
      monitorSession: true,
      automaticSilentRenew: true,
      revokeTokensOnSignout: true,
      revokeTokenTypes: ['access_token', 'refresh_token'],
      // oidc
      stateStore: new WebStorageStateStore({
        prefix: self.authSettings.storePrefix,
        store: window.localStorage,
      }),
      // user store
      userStore: new WebStorageStateStore({
        prefix: self.authSettings.storePrefix,
        store: window.localStorage,
      }),
    });

    const startLoading = () => (self.loading = true);
    const endLoading = () => (self.loading = false);

    const internalLoadPreviousSessionAsync = flow(function* () {
      try {
        startLoading();
        const user: User = yield oidc.getUser();

        if (!!user) {
          internalUpdateToken(user.id_token, user.access_token, user.refresh_token);
          internalUpdateUserProfile(user.profile);
        }
      } catch (error) {
        console.log(error);
      } finally {
        endLoading();
      }
    });

    const internalUpdateToken = (idToken?: string, accessToken?: string, refreshToken?: string) => {
      applySnapshot(self, {
        ...self,
        idToken,
        accessToken,
        refreshToken,
      });
    };

    const internalUpdateUserProfile = (authUserProfile?: UserProfile) => {
      if (!authUserProfile || Object.keys(authUserProfile).length === 0) {
        applySnapshot(self, {
          ...self,
          authUser: null,
        });

        return;
      }

      applySnapshot(self, {
        ...self,
        authUser: {
          id: authUserProfile.sub,
          userName: authUserProfile.preferred_username ?? '',
          firstName: authUserProfile.given_name ?? '',
          lastName: authUserProfile.family_name ?? '',
          fullName: authUserProfile.name ?? '',
          // displayName: authUserProfile. ?? '',
          // birthDate: authUserProfile. ?? '',
          email: authUserProfile.email ?? '',
          emailConfirmed: authUserProfile.email_verified ?? false,
          phoneNumber: authUserProfile.phone_number ?? '',
          phoneNumberConfirmed: authUserProfile.phone_number_verified ?? false,
          // avatar: authUserProfile. ?? '',
          //   roles: authUserProfile.roles
          //   permissions: authUserProfile.permissions,
        },
      });
    };

    const signinAsync = flow(function* (args?: Record<string, any>) {
      yield oidc.signinRedirect(args);
    });

    const signinCallbackAsync = flow(function* (url: string) {
      const user: User = yield oidc.signinCallback(url);

      internalUpdateToken(user.id_token, user.access_token, user.refresh_token);
      internalUpdateUserProfile(user.profile);
    });

    const signinSilentAsync = flow(function* () {
      try {
        const user: User = yield oidc.signinSilent();

        internalUpdateToken(user.id_token, user.access_token, user.refresh_token);
        internalUpdateUserProfile(user.profile);
      } catch {
        yield signoutAsync();
      }
    });

    const signinSilentCallbackAsync = flow(function* (url: string) {
      yield oidc.signinSilentCallback(url);
    });

    const siginDirectlyAsync = flow(function* (args?: Record<string, any>) {
      try {
        const user: User = yield oidc.signinResourceOwnerCredentials({
          username: args?.userName,
          password: args?.password,
          skipUserInfo: true,
        });

        internalUpdateToken(user.id_token, user.access_token, user.refresh_token);
        internalUpdateUserProfile(user.profile);
      } catch (err) {
        console.log(err);
        throw new Error('Username or password is incorrect');
      }
    });

    const signoutAsync = flow(function* () {
      yield oidc.revokeTokens(['access_token', 'refresh_token']);
      yield oidc.signoutRedirect();
      yield oidc.removeUser();

      internalUpdateToken('', '', '');
      internalUpdateUserProfile();
    });

    const signoutDirectlyAsync = flow(function* () {
      try {
        yield oidc.revokeTokens(['access_token', 'refresh_token']);

        const oidcClient = new OidcClient({
          ...oidc.settings,
          stateStore: new WebStorageStateStore({
            prefix: self.authSettings.storePrefix,
            store: window.localStorage,
          }),
        });

        oidcClient
          .createSignoutRequest({
            state: new WebStorageStateStore({
              prefix: self.authSettings.storePrefix,
              store: window.localStorage,
            }),
            extraQueryParams: {
              client_id: self.authSettings.clientId,
              response_type: 'code',
              scope: self.authSettings.scopes.join(' '),
              response_mode: 'query',
            },
          })
          .then((req) => {
            fetch(req.url, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },

              credentials: 'include',
            });
          })
          .catch((err) => {
            console.log(err);
          });

        yield oidc.removeUser();

        internalUpdateToken('', '', '');
        internalUpdateUserProfile();
      } catch (error) {
        console.log(error);
      }
    });

    // oidc.events.addAccessTokenExpiring(signinSilentAsync);
    // oidc.events.addAccessTokenExpired(signoutAsync);

    const afterCreate = () => {
      internalLoadPreviousSessionAsync();
    };

    return {
      afterCreate,
      signinAsync,
      signinCallbackAsync,
      signinSilentAsync,
      signinSilentCallbackAsync,
      siginDirectlyAsync,
      signoutAsync,
      signoutDirectlyAsync,
    };
  });

export interface AuthStoreInstance extends Instance<typeof AuthStore> {}

const createAuthStore = (authSettings: AuthSettings) =>
  createStore(AuthStore, {
    authSettings,
  });
const [AuthStoreProvider, useAuthStore] = createStoreContext<AuthStoreInstance>();

export { createAuthStore, AuthStoreProvider, useAuthStore };
