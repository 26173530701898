const API_V1_PRODUCT = '/prod/api/v1/products';
const API_V1_BRAND = '/prod/api/v1/brands';
const API_V1_CATEGORIES = '/prod/api/v1/categories';

export const PRODUCT_API = {
  GET_PRODUCTS: `${API_V1_PRODUCT}/filter`,
  GET_TOP_PRODUCTS: `${API_V1_PRODUCT}/filter`,
  GET_PRODUCT_DETAIL: `${API_V1_PRODUCT}/:id`,
  GET_PRODUCT_FEEDBACK: `${API_V1_PRODUCT}/:id/feedback`,
  GET_PRODUCT_RATING_FEEDBACK: `${API_V1_PRODUCT}/:id/rating`,
  POST_PRODUCT_DISCUSSION: `${API_V1_PRODUCT}/:id/discussion`,
  GET_PRODUCT_DISCUSSION: `${API_V1_PRODUCT}/:id/discussion`,
  GET_PROMOTION_BY_PRODUCT_ID: `${API_V1_PRODUCT}/:id/promotion`,

  GET_BRANDS: `${API_V1_BRAND}`,

  GET_CATEGORIES: `${API_V1_CATEGORIES}`,
  POST_CATEGORY: `${API_V1_CATEGORIES}`,
  PUT_CATEGORY: `${API_V1_CATEGORIES}/:id`,
  PUT_CATEGORY_STATUS: `${API_V1_CATEGORIES}/:id/status`,
  GET_CATEGORIES_OPTIONS: `${API_V1_CATEGORIES}/options`,
  GET_CATEGORY_BY_PRODUCT_ID: `${API_V1_CATEGORIES}/recursive-parents`,
};
