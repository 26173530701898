export enum HistoryStatus {
  OTHER = 'OTHER',
  USE = 'USE',
  CANCEL = 'CANCEL',
  GIFT = 'GIFT',
}

export enum PointHistory {
  RECEIVED = 'RECEIVED',
  USED = 'USED',
}
