import { generatePath } from 'react-router';

import { Box, Button, Typography, Stack } from '@mui/material';
import { MyProfileRoutes } from 'pages/shared/routers';
import { useBreakpoints } from '@vklink/components-shared';

type Props = {
  title: string;
  description: string;

  image: string;
  type: string;
};

const NotificationItem = ({ image, title, description, type }: Props) => {
  const breakpoints = useBreakpoints();
  const renderTypeButton = () => {
    return (
      <Box ml={!breakpoints.md ? undefined : 'auto'}>
        {type === 'promotion' ? (
          <Button
            variant="contained"
            color="primary"
            href={generatePath(MyProfileRoutes.PURCHASE_ORDER, { status: 'rating' })}
          >
            View Details
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            href={generatePath(MyProfileRoutes.PURCHASE_ORDER_DETAIL, { status: 'rating' })}
          >
            View Order Details
          </Button>
        )}
      </Box>
    );
  };

  return (
    <Stack direction="row" spacing={1} py={2}>
      <Box
        component={'img'}
        src={image}
        sx={{
          width: 70,
          height: 70,
        }}
        border={1}
        borderColor="grey.400"
        borderRadius={2}
        alignSelf={breakpoints.md ? 'center' : 'flex-start'}
      />
      <Stack
        flex={1}
        direction={breakpoints.md ? 'row' : 'column'}
        spacing={1}
        alignItems="flex-start"
      >
        <Stack direction="column" flex={1}>
          <Typography variant="body2" component="div">
            {title}
          </Typography>
          <Typography variant="body2" component="div" color="grey.400">
            {description}
          </Typography>
          <Typography variant="body2" component="div" color="grey.400">
            13:39 19-07-2022
          </Typography>
        </Stack>
        {renderTypeButton()}
      </Stack>
    </Stack>
  );
};

export default NotificationItem;
