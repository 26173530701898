import { generatePath } from 'react-router';

import { SnapshotOut, types, getEnv, flow, applySnapshot, getSnapshot } from '@vklink/libs-state';
import { SHIPPING_ADDRESSES_API, USERS_API } from 'api';
import {
  UpdateUserInformation,
  CreateUserShippingAddress,
  UserShippingAddressModel,
  UserPointModel,
} from './models';
import { RootStoreEnv } from 'stores';

export type ProfileStoreEnv = RootStoreEnv & {
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

export const UserInformationStore = types
  .model('User Information Store', {
    userShippingAddress: types.array(UserShippingAddressModel),
    userHistoryPoint: types.array(UserPointModel),
  })
  .views((self) => {
    return {
      get getDefaultShippingAddress() {
        return self.userShippingAddress.find((x) => x.isDefault);
      },
      get getUserShippingAddress() {
        return getSnapshot(self.userShippingAddress);
      },
      get getUserHistoryPoint() {
        return getSnapshot(self.userHistoryPoint);
      },
    };
  })
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<ProfileStoreEnv>(self);

    const getUserShippingAddressAsync = flow(function* () {
      const loadingId = load('Get User Shipping Address Async');
      try {
        const response = yield httpInstance.get(USERS_API.GET_USERS_SHIPPING_ADDRESS);

        applySnapshot(self.userShippingAddress, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const updateUserInformationAsync = flow(function* (
      userInformation: UpdateUserInformation,
      cb?: RequestCallback
    ) {
      const loadingId = load('Update User Information Async');

      try {
        yield httpInstance.put(USERS_API.PUT_USERS_INFORMATION, {
          ...userInformation,
          userName: undefined,
        });

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const createUserShippingAddressAsync = flow(function* (
      userShippingAddress: CreateUserShippingAddress,
      cb?: RequestCallback
    ) {
      const loadingId = load('Create User Shipping Address Async');
      try {
        yield httpInstance.post(USERS_API.POST_USERS_SHIPPING_ADDRESS, {
          ...userShippingAddress,
        });

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const updateDefaultUserShippingAddressAsync = flow(function* (
      id: string,
      cb?: RequestCallback
    ) {
      const loadingId = load('Update Default User Shipping Address Async');
      try {
        yield httpInstance.put(
          generatePath(SHIPPING_ADDRESSES_API.PUT_DEFAULT_SHIPPING_ADDRESS, { id })
        );

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const deleteUserShippingAddressAsync = flow(function* (id: string, cb?: RequestCallback) {
      const loadingId = load('Delete User Shipping Address Async');
      try {
        yield httpInstance.delete(
          generatePath(SHIPPING_ADDRESSES_API.DELETE_SHIPPING_ADDRESS, { id })
        );

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const updateUserShippingAddressAsync = flow(function* (
      id: string,
      userShippingAddress: CreateUserShippingAddress,
      cb?: RequestCallback
    ) {
      const loadingId = load('Update User Shipping Address Async');
      try {
        const url = generatePath(SHIPPING_ADDRESSES_API.PUT_SHIPPING_ADDRESS, {
          id,
        });

        yield httpInstance.put(url, {
          ...userShippingAddress,
        });

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getHistoryPointAsync = flow(function* (id: string) {
      const loadingId = load('Get History Point Async');
      try {
        const url = generatePath(USERS_API.GET_HISTORY_POINT_USER_ID, {
          id,
        });

        const response = yield httpInstance.get(url);

        applySnapshot(self.userHistoryPoint, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      getUserShippingAddressAsync,
      updateUserInformationAsync,
      createUserShippingAddressAsync,
      updateDefaultUserShippingAddressAsync,
      deleteUserShippingAddressAsync,
      updateUserShippingAddressAsync,
      getHistoryPointAsync,
    };
  });

export interface UserInformation extends SnapshotOut<typeof UserInformationStore> {}
