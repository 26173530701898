import { SnapshotOut, types, getEnv, flow } from '@vklink/libs-state';
import {
  UserAddNewValue,
  UserChangePassword,
  UserConfirmOtpOldValue,
  UserEditEmailOrPhoneInputModel,
  UserEditOldValue,
} from './models';
import { AUTHENTICATION_API } from 'api';
import { RequestType } from 'enums';
import { RootStoreEnv } from 'stores';

export type EditUserFlowStoreEnv = RootStoreEnv & {
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

export const EditUserFlowStore = types.model('Edit User Flow Store', {}).actions((self) => {
  const { httpInstance, load, loaded } = getEnv<EditUserFlowStoreEnv>(self);

  const findRequestTypeByUrl = (url: string) => {
    if (url.indexOf('email') > 0) {
      return RequestType.EMAIL;
    }
    if (url.indexOf('phone') > 0) {
      return RequestType.PHONENUMBER;
    }
    return null;
  };

  const sendOtpByOldValue = flow(function* (value: UserEditOldValue, cb?: RequestCallback) {
    const loadingId = load('Send Otp By Old Value Async');
    try {
      yield httpInstance.post(AUTHENTICATION_API.POST_CHANGE_EMAIL_PHONE, {
        ...value,
      });

      cb?.success && cb.success();
    } catch (err) {
      console.log(err);
      cb?.error && cb.error(err);
    } finally {
      loaded(loadingId);
    }
  });

  const confirmOldValue = flow(function* (value: UserConfirmOtpOldValue, cb?: RequestCallback) {
    const loadingId = load('Confirm Old Otp Async');

    try {
      yield httpInstance.post(AUTHENTICATION_API.POST_CHANGE_EMAIL_PHONE_CONFIRM, {
        ...value,
      });

      cb?.success && cb.success();
    } catch (err) {
      console.log(err);
      cb?.error && cb.error(err);
    } finally {
      loaded(loadingId);
    }
  });

  const changeToNewValue = flow(function* (value: UserAddNewValue, cb?: RequestCallback) {
    const loadingId = load('Change To New Value Otp Async');

    try {
      yield httpInstance.post(AUTHENTICATION_API.POST_CHANGE_NEW_EMAIL_PHONE, {
        ...value,
      });

      cb?.success && cb.success();
    } catch (err) {
      console.log(err);
      cb?.error && cb.error(err);
    } finally {
      loaded(loadingId);
    }
  });

  const confirmNewValue = flow(function* (
    value: UserEditEmailOrPhoneInputModel,
    cb?: RequestCallback
  ) {
    const loadingId = load('Confirm Otp By New Value Async');

    try {
      yield httpInstance.post(AUTHENTICATION_API.POST_CHANGE_NEW_EMAIL_PHONE_CONFIRM, {
        ...value,
      });

      cb?.success && cb.success();
    } catch (err) {
      console.log(err);
      cb?.error && cb.error(err);
    } finally {
      loaded(loadingId);
    }
  });

  const addNewValue = flow(function* (value: UserAddNewValue, cb?: RequestCallback) {
    const loadingId = load('Add New Value Async');

    try {
      yield httpInstance.post(AUTHENTICATION_API.POST_ADD_EMAIL_PHONE, {
        ...value,
      });

      cb?.success && cb.success();
    } catch (err) {
      console.log(err);
      cb?.error && cb.error(err);
    } finally {
      loaded(loadingId);
    }
  });

  const confirmAddNewValue = flow(function* (value: UserAddNewValue, cb?: RequestCallback) {
    const loadingId = load('Add New Value Async');

    try {
      yield httpInstance.post(AUTHENTICATION_API.POST_ADD_EMAIL_PHONE_CONFIRM, {
        ...value,
      });

      cb?.success && cb.success();
    } catch (err) {
      console.log(err);
      cb?.error && cb.error(err);
    } finally {
      loaded(loadingId);
    }
  });

  const changePasswordAsync = flow(function* (value: UserChangePassword, cb?: RequestCallback) {
    const loadingId = load('Change Password Async');

    try {
      yield httpInstance.post(AUTHENTICATION_API.POST_CHANGE_PASSWORD, {
        ...value,
      });

      cb?.success && cb.success();
    } catch (err) {
      console.log(err);
      cb?.error && cb.error(err);
    } finally {
      loaded(loadingId);
    }
  });

  return {
    findRequestTypeByUrl,
    sendOtpByOldValue,
    changePasswordAsync,
    confirmOldValue,
    changeToNewValue,
    confirmNewValue,
    addNewValue,
    confirmAddNewValue,
  };
});

export interface UserInformation extends SnapshotOut<typeof EditUserFlowStore> {}
