import { Stack, Typography } from '@mui/material';

const SampleDescriptions = [
  'Bass and Stereo Sound.',
  'Display with 3088 x 1440 pixels resolution.',
  'Memory, Storage & SIM: 12GB RAM, 256GB.',
  'Android v10.0 Operating system.',
];

const ProductShortDescription = () => {
  return (
    <Stack
      component="ul"
      sx={{
        pl: 2.2,
        '& li': {
          color: 'grey.700',
          fontSize: (theme) => theme.typography.fontSize + 1,
        },
      }}
    >
      {SampleDescriptions.map((item, idx) => {
        return (
          <Typography component="li" key={idx}>
            {item}
          </Typography>
        );
      })}
    </Stack>
  );
};

export default ProductShortDescription;
