import { SnapshotOut, types } from '@vklink/libs-state';

export const OrderItemAttributeValuesModel = types.model('Order Item Attribute Values Model', {
  id: types.identifier,
  attributeName: types.maybeNull(types.string),
  attributeValue: types.string,
  attributeValueId: types.string,
});

export interface OrderItemAttributeValues
  extends SnapshotOut<typeof OrderItemAttributeValuesModel> {}
