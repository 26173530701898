import { Instance, SnapshotIn, SnapshotOut, types } from '@vklink/libs-state';
import { Gender } from 'enums';
import { UploadFileModel, UploadInputModel } from 'pages/shared/models';

export const UserInformationModel = types.model('User Information Model', {
  id: types.identifier,
  userId: types.string,
  username: types.maybeNull(types.string),
  firstName: types.string,
  lastName: types.string,
  fullName: types.string,
  birthday: types.maybeNull(types.string),
  gender: types.string,
  point: types.number,
  usernameChanged: types.maybeNull(types.boolean),
  phone: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  media: types.maybeNull(UploadFileModel),
  avatar: types.maybeNull(types.string),
  images: types.array(UploadInputModel),
});

export const DefaultUserInformationValue: Partial<UserInformationInputModel> = {
  firstName: '',
  lastName: '',
  birthday: '',
  gender: Gender.MALE,
  phone: '',
  email: '',
};

export type UpdateUserInformation = Pick<
  UserInformationInputModel,
  | 'username'
  | 'firstName'
  | 'lastName'
  | 'birthday'
  | 'gender'
  | 'phone'
  | 'email'
  | 'media'
  | 'images'
>;
export interface UserInformationInstance extends Instance<typeof UserInformationModel> {}
export interface UserInformationInputModel extends SnapshotIn<typeof UserInformationModel> {}
export interface UserInformation extends SnapshotOut<typeof UserInformationModel> {}
