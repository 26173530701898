export enum PromotionType {
  POINT = 'POINT',
  GIFT = 'GIFT',
  OTHER = 'OTHER',
}

export enum ConditionType {
  PRODUCT = 'PRODUCT',
  ORDER = 'ORDER',
  OTHER = 'OTHER',
}
