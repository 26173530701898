import { types, SnapshotOut } from '@vklink/libs-state';

export const CartItemAttributeModel = types.model('Cart Item Attribute Model', {
  id: types.identifier,
  attributeId: types.string,
  attributeName: types.maybeNull(types.string),
  attributeType: types.maybeNull(types.string),
  attributeValueId: types.string,
  attributeValue: types.maybeNull(types.string),
});

export interface CartItemAttribute extends SnapshotOut<typeof CartItemAttributeModel> {}
