import { applySnapshot, types } from '@vklink/libs-state';
import { storage } from '@vklink/libs-utils';
import { TypeLayout } from 'enums';

export const LayoutStore = types
  .model({
    language: types.optional(types.string, 'en'),
  })
  .actions((self) => {
    const initializeFromLocalStorage = () => {
      const language = storage.get(TypeLayout.LANGUAGE) ?? 'en';

      applySnapshot(self, { ...self, language });
    };

    const changeLanguage = (language: string) => {
      storage.set(TypeLayout.LANGUAGE, language);

      applySnapshot(self, { ...self, language });
    };

    const afterCreate = () => {
      initializeFromLocalStorage();
    };

    return { afterCreate, changeLanguage };
  });

export default LayoutStore;
