import { PaymentMethods } from 'enums';

export const PaymentMethodOptions: SelectOption[] = [
  {
    label: 'common:paymentMethods.cashOnDelivery',
    value: PaymentMethods.CASH_ON_DELIVERY,
  },
  {
    label: 'common:paymentMethods.card',
    value: PaymentMethods.CARD,
    disabled: true,
  },
  {
    label: 'common:paymentMethods.bankTransfer',
    value: PaymentMethods.BANK_TRANSFER,
  },
  {
    label: 'common:paymentMethods.momoWallet',
    value: PaymentMethods.MOMO_WALLET,
    disabled: true,
  },
];
