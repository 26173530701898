import { Button } from '@mui/material';
import { useI18n } from '@vklink/libs-i18n';

import { observer } from '@vklink/libs-state';
import { ProductItem } from 'pages/shared/models';

type Props = { product: ProductItem; onClick?: () => void };

const AddToCartButton = (props: Props) => {
  const { t } = useI18n(['common']);

  const onClick = () => {
    console.log(props.product);
  };

  return (
    <Button
      className="add-to-cart-btn"
      fullWidth
      sx={{
        borderRadius: 0,
        border: (theme) => `1px solid ${theme.palette.warning.main}`,
        backgroundColor: 'warning.main',
        fontWeight: 'bold',
        color: 'common.black',
        fontSize: (theme) => theme.typography.fontSize - 1,

        ':hover': {
          backgroundColor: 'warning.main',
        },
      }}
      onClick={props.onClick || onClick}
    >
      {t('common:actions.addToCart').toUpperCase()}
    </Button>
  );
};

export default observer(AddToCartButton);
