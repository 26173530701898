import { Container, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import { ProductItem } from '../models';
import ProductItemCard from './ProductItem';
import { useBreakpoints } from '@vklink/components-shared';

type Props = {
  products: any[];
  card?: (props: ProductItem) => JSX.Element;
};

const ProductListSwiper = (props: Props) => {
  const theme = useTheme();
  const Card = props.card || ProductItemCard;
  const breakpoints = useBreakpoints();

  return (
    <Container
      sx={{
        p: '0 !important',
        position: 'relative',
        overflow: 'visible',

        ':hover': {
          '& .products-swiper-button': {
            visibility: 'visible',
          },
        },

        '& .products-swiper-button': {
          zIndex: 2,
          position: 'absolute',
          visibility: breakpoints.md ? 'hidden' : 'visible',
          width: {
            xs: 30,
            md: 50,
          },
          height: {
            xs: 30,
            md: 50,
          },
          backgroundColor: 'grey.300',
          color: 'common.white',
          transition: '0.5s',
          top: '50%',
          transform: 'translateY(-50%)',

          '&-prev': {
            left: {
              xs: -15,
              md: -20,
            },
            right: 'auto',
          },

          '&-next': {
            left: 'auto',
            right: {
              xs: -15,
              md: -20,
            },
          },

          ':hover': {
            backgroundColor: 'warning.main',
            color: 'common.black',
          },
        },
      }}
    >
      <IconButton className="products-swiper-button products-swiper-button-prev">
        <ChevronLeft />
      </IconButton>
      <IconButton className="products-swiper-button products-swiper-button-next">
        <ChevronRight />
      </IconButton>
      <Swiper
        navigation={{
          nextEl: '.products-swiper-button-next',
          prevEl: '.products-swiper-button-prev',
        }}
        modules={[Navigation]}
        slidesPerView={2.5}
        breakpoints={{
          [theme.breakpoints.values.sm]: {
            slidesPerView: 3,
          },
          [theme.breakpoints.values.md]: {
            slidesPerView: 4,
          },
          [theme.breakpoints.values.lg]: {
            slidesPerView: 5,
          },
          [theme.breakpoints.values.xl]: {
            slidesPerView: 6,
          },
        }}
      >
        {props.products.map((item, index) => (
          <SwiperSlide key={index}>
            <Card {...item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};

export default ProductListSwiper;
