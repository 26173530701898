import { Instance, SnapshotIn, SnapshotOut, types } from '@vklink/libs-state';
import { AttributeModel, BrandModel, CategoryModel } from 'pages/shared/models';
import { MediaModel } from 'pages/shared/models/media';

export const ProductDetailModel = types.model('ProductDetail Model', {
  id: types.identifier,
  code: types.string,
  name: types.string,
  slug: types.string,
  description: types.string,
  vatPercent: types.number,
  discountPercent: types.number,
  regularPrice: types.number,
  sellPrice: types.number,
  sellVatPrice: types.number,
  sellExpectVatPrice: types.number,
  stock: types.number,
  ratingAvg: types.number,
  enabled: types.boolean,
  brand: BrandModel,
  category: CategoryModel,
  attributes: types.array(AttributeModel),
  medias: types.array(MediaModel),
});

export interface ProductDetailInstance extends Instance<typeof ProductDetailModel> {}
export interface ProductDetailInputModel extends SnapshotIn<typeof ProductDetailModel> {}
export interface ProductDetail extends SnapshotOut<typeof ProductDetailModel> {}
