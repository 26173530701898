import { Backdrop, Box } from '@mui/material';
import { observer } from '@vklink/libs-state';
import { useRootStore } from 'stores';
import LoadingGif from 'assets/l1.gif';

const BackdropLoading = () => {
  const { loadingStore } = useRootStore();

  return (
    <>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.modal + 1,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
        }}
        open={loadingStore.isLoading}
      >
        <Box component="img" src={LoadingGif} alt="Loading" />
      </Backdrop>
    </>
  );
};

export default observer(BackdropLoading);
