import { NavLink } from 'react-router-dom';

import { Avatar, Box, Container, Divider, Grid, Stack, Typography } from '@mui/material';
import { HeadsetMicOutlined } from '@mui/icons-material';

import { HomeRoutes } from '../routers';
import { useI18n } from '@vklink/libs-i18n';
import {
  SubCustomerCareList,
  SubCustomerServiceList,
  SubMyAccountList,
  SubQuickLinksList,
  SupportedPaymentMethods,
  Links,
} from 'constant';

const Footer = () => {
  const { t } = useI18n(['components', 'common']);

  return (
    <Box
      sx={{
        backgroundColor: 'grey.900',
        color: (theme) => theme.palette.common.white,
        py: 7,
      }}
    >
      <Container>
        <Grid container spacing={2} rowGap={2}>
          <Grid item xs={6} sm={6} md={2}>
            <Box gap={1} display="flex" flexDirection={'column'}>
              <BlockTitle text={t('components:sideBar.aboutUs')} />
              {SubCustomerCareList.map((item, idx) => {
                return <LinkItem key={idx} {...item} />;
              })}
            </Box>
          </Grid>

          <Grid item xs={6} sm={6} md={2}>
            <Box gap={1} display="flex" flexDirection={'column'}>
              <BlockTitle text={t('common:mixins.customerCare')} />
              {SubCustomerServiceList.map((item, idx) => {
                return <LinkItem key={idx} {...item} />;
              })}
            </Box>
          </Grid>

          <Grid item xs={6} sm={6} md={2}>
            <Box gap={1} display="flex" flexDirection={'column'}>
              <BlockTitle text={t('common:user.myAccount')} />
              {SubMyAccountList.map((item, idx) => {
                return <LinkItem key={idx} {...item} />;
              })}
            </Box>
          </Grid>

          <Grid item xs={6} sm={6} md={2}>
            <Box gap={1} display="flex" flexDirection={'column'}>
              <BlockTitle text={t('common:mixins.quickLinks')} />
              {SubQuickLinksList.map((item, idx) => {
                return <LinkItem key={idx} {...item} />;
              })}
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AboutStoreBlock />
          </Grid>
        </Grid>
        <Divider sx={{ my: 5 }} />

        <LinksBlock />

        <Grid my={2} container rowGap={2} mx="auto" maxWidth={500}>
          {SupportedPaymentMethods.map((el, index) => (
            <Grid
              key={index}
              item
              sx={{ display: 'flex', justifyContent: 'center' }}
              xs={3}
              sm={12 / 8}
            >
              <Avatar
                sx={{
                  borderRadius: 0,
                  width: '100%',
                  maxWidth: 56,
                  '& img': {
                    objectFit: 'contain',
                  },
                }}
                alt={el.name}
                src={el.image}
              />
            </Grid>
          ))}
        </Grid>

        <Typography
          component="p"
          sx={{
            color: 'grey.300',
            fontSize: (theme) => theme.typography.fontSize,
            textAlign: 'center',
          }}
        >
          {t('components:footer.copyright')} ©
          <Typography
            component="span"
            sx={{
              color: 'warning.main',
              mx: 0.5,
            }}
          >
            VK Link.
          </Typography>
          {t('components:footer.allRightsReserved')}
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;

const LinkItem = (props: { title: string; href?: string }) => {
  const { t } = useI18n();
  const title: any = props.title;

  return (
    <NavLink
      style={{
        textDecoration: 'none',
        backgroundColor: 'transparent',
      }}
      to={props.href || HomeRoutes.ROOT}
    >
      <Typography
        sx={{
          color: 'grey.300',
          cursor: 'pointer',
          transition: '0.5s',
          fontSize: (theme) => theme.typography.fontSize,
          ':hover': {
            pl: 1,
            color: 'warning.main',
          },
        }}
      >
        {t(title)}
      </Typography>
    </NavLink>
  );
};

const BlockTitle = (props: { text: string }) => {
  return (
    <Typography
      variant="h6"
      fontWeight="bold"
      gutterBottom
      sx={{ fontSize: (theme) => theme.typography.fontSize + 4 }}
    >
      {props.text}
    </Typography>
  );
};

const LinksBlock = () => {
  if (!Links.length) {
    return <></>;
  }

  return (
    <Typography
      component="p"
      sx={{
        maxWidth: 950,
        mx: 'auto',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        textAlign: 'center',
        rowGap: 1,

        '& a': {
          cursor: 'pointer',
          fontSize: (theme) => theme.typography.fontSize - 0.4,
          color: 'grey.300',
          px: 1,
          position: 'relative',

          ':last-child': {
            ':before': {
              display: 'none',
            },
          },
        },

        '& a:before': {
          content: '"/"',
          position: 'absolute',
          top: 0,
          right: 0,
          opacity: 0.2,
          transform: 'translateX(50%)',
          color: 'grey.300',
        },

        '& a:hover': {
          color: 'warning.main',
        },
      }}
    >
      {Links.map((item, idx) => {
        return (
          <NavLink
            key={idx}
            style={{
              textDecoration: 'none',
              backgroundColor: 'transparent',
            }}
            to={HomeRoutes.ROOT}
          >
            <Typography
              sx={{
                color: 'grey.300',
                cursor: 'pointer',
                transition: '0.5s',
                fontSize: (theme) => theme.typography.fontSize,
                ':hover': {
                  pl: 1,
                  color: 'warning.main',
                },
              }}
            ></Typography>
            {item.title}
          </NavLink>
        );
      })}
    </Typography>
  );
};

const AboutStoreBlock = () => {
  const { t } = useI18n(['components']);

  return (
    <Box gap={1} display="flex" flexDirection={'column'}>
      <BlockTitle text={t('components:footer.aboutTheStore')} />

      <Box
        sx={{
          '& .MuiTypography-root': {
            color: 'grey.300',
            fontSize: (theme) => theme.typography.fontSize,
          },
        }}
      >
        <Typography sx={{ mb: 3 }}>{t('components:footer.aboutTheStoreContent')}</Typography>

        <Stack direction="row" alignItems="center" spacing={2} mb={2}>
          <HeadsetMicOutlined sx={{ color: 'warning.main', fontSize: 40 }} />
          <Stack>
            <Typography
              sx={{
                color: 'grey.300',
              }}
            >
              {t('components:footer.gotQuestion')}
            </Typography>
            <Typography
              sx={{
                color: (theme) => `${theme.palette.warning.main} !important`,
                fontSize: (theme) => `${theme.typography.fontSize * 1.5}px !important`,
                textDecoration: 'none',
              }}
              component="a"
              href="tel:+842873009959"
            >
              +84 28 7300 9959
            </Typography>
          </Stack>
        </Stack>

        <Typography sx={{ textDecoration: 'none' }} component="a" href="mailto:contact@vkshop.vn">
          Email: contact@vkshop.vn
        </Typography>
        <Typography>
          Add: 91 Nguyen Huu Canh st. Ward 22, Binh Thanh Dist, Ho Chi Minh City
        </Typography>
      </Box>
    </Box>
  );
};
