import { types, getEnv, flow, applySnapshot } from '@vklink/libs-state';
import { UPLOAD_FILE_API } from 'api';
import { UploadFile, UploadFileModel, UploadMultipleFileModel } from 'pages/shared/models';
import { RootStoreEnv } from 'stores';

export type UploadFileStoreEnv = RootStoreEnv & {
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

export const UploadFileStore = types
  .model('UploadFile Store', {
    uploadFile: types.maybeNull(UploadFileModel),
    uploadMultipleFile: types.array(UploadMultipleFileModel),
  })
  .views((self) => {
    return {
      get getUploadFile() {
        return self.uploadFile;
      },
      get getUploadMultipleFile() {
        return self.uploadMultipleFile;
      },
    };
  })
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<UploadFileStoreEnv>(self);

    const setUploadFile = (uploadFile: UploadFile) => {
      self.uploadFile = uploadFile;
    };

    const setUploadMultipleFile = (uploadMultipleFile: UploadFile[]) => {
      const multipleFile = uploadMultipleFile.map((file, index) => {
        return {
          ...file,
          mediaIndex: index,
          fileName: file.name,
          bucket: file.bucket ?? 'eshop-temp',
        };
      });
      applySnapshot(self.uploadMultipleFile, multipleFile);
    };

    const saveFile = flow(function* (file: any) {
      if (!file.length) return;
      const formData = new FormData();
      formData.append('key', file.length);
      formData.append('file', file[0]);

      const loadingId = load('Get Brand Async');
      try {
        const response = yield httpInstance.post(UPLOAD_FILE_API.POST_FILE, formData);

        self.uploadFile = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const saveMultipleFile = flow(function* (files: any) {
      if (!files.length) return;
      const formData = new FormData();
      files.forEach((file: any, index: number) => {
        formData.append(`items[${index}].key`, `${index + 1}`);
        formData.append(`items[${index}].file`, file);
      });

      const loadingId = load('Get Brand Async');
      try {
        const response = yield httpInstance.post(UPLOAD_FILE_API.POST_FILES, formData);

        applySnapshot(self.uploadMultipleFile, response.data);
        return response.data;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      saveFile,
      saveMultipleFile,
      setUploadFile,
      setUploadMultipleFile,
    };
  });
