const applyBearerTokenInterceptor = (
  instance: HttpInstance,
  getTokenAsync: () => Promise<string | null>
) => {
  instance.interceptors.request.use(
    (config) => {
      return getTokenAsync()
        .then((token) => {
          const originalConfig = config;

          if (token) {
            originalConfig.headers = {
              ...originalConfig.headers,
              Authorization: `Bearer ${token}`,
            };

            return originalConfig;
          }

          return originalConfig;
        })
        .catch((error) => Promise.reject(error));
    },

    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );
};

export default applyBearerTokenInterceptor;
