import { useNavigate } from 'react-router';

import { Divider, Drawer, IconButton, ListItemText, MenuItem, MenuList } from '@mui/material';
import { CloseOutlined, MenuOutlined } from '@mui/icons-material';

import { useBreakpoints, useDisclosure } from '@vklink/components-shared';
import { HomeRoutes } from '../routers';

const Categories = [
  { title: 'Fashion', path: HomeRoutes.SEARCH },
  { title: 'Electronics', path: HomeRoutes.SEARCH },
  { title: 'Health', path: HomeRoutes.SEARCH },
  { title: 'Baby', path: HomeRoutes.SEARCH },
  { title: 'Home - Life', path: HomeRoutes.SEARCH },
];

const HeaderNavigationBar = () => {
  const navigator = useNavigate();
  const { isOpen, open, close } = useDisclosure();
  const breakpoints = useBreakpoints();

  const navigateToHome = () => {
    close();
    navigator('/');
  };

  const renderMenuItemWithSub = (main: string, subs: { title: string; path?: string }[]) => {
    return (
      <>
        <MenuItem>
          <ListItemText sx={{ '> .MuiTypography-root': { fontSize: '1.3rem' } }}>
            {main}
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{
            paddingY: 0,
          }}
        >
          <MenuList
            sx={{
              padding: 0,
            }}
          >
            {subs.map((item, idx) => {
              return (
                <MenuItem
                  key={idx}
                  onClick={
                    item.path
                      ? () => {
                          close();
                          navigator(item.path!);
                        }
                      : undefined
                  }
                >
                  <ListItemText>{item.title}</ListItemText>
                </MenuItem>
              );
            })}
          </MenuList>
        </MenuItem>
      </>
    );
  };

  return (
    <>
      {!breakpoints.md && (
        <IconButton
          sx={{
            backgroundColor: 'common.white',
          }}
          onClick={open}
        >
          <MenuOutlined />
        </IconButton>
      )}

      <Drawer
        anchor={'left'}
        open={isOpen}
        onClose={close}
        sx={{
          zIndex: (theme) => theme.zIndex.modal + 1,
          '& .MuiPaper-root': {
            backgroundColor: (theme) => theme.palette.common.white,
            width: '100%',
            maxWidth: 600,
          },
        }}
      >
        <IconButton
          sx={{
            marginLeft: 'auto',
          }}
          onClick={close}
        >
          <CloseOutlined />
        </IconButton>

        <MenuList>
          <MenuItem onClick={navigateToHome}>
            <ListItemText sx={{ '> .MuiTypography-root': { fontSize: '1.3rem' } }}>
              Home
            </ListItemText>
          </MenuItem>
          <Divider />
          {renderMenuItemWithSub('Category', Categories)}
        </MenuList>
      </Drawer>
    </>
  );
};
export default HeaderNavigationBar;
