import { types, Instance } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';
import { ListOrdersStore } from './ListOrderStore';
import { UserInformationStore } from './UserInformationStore';
import { UploadFileStore } from 'pages/shared/stores';
import { EditUserFlowStore } from './EditUserFlowStore';
import { PaymentStore } from 'pages/payment/stores/PaymentStore';

export type ProfileStoreEnv = {
  httpInstance: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const ProfileStore = types.compose(
  ListOrdersStore,
  UserInformationStore,
  UploadFileStore,
  EditUserFlowStore,
  PaymentStore,
  types.model('Profile Store', {})
);

export default ProfileStore;

export type ProfileStoreInstance = Instance<typeof ProfileStore>;
