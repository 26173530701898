import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { HealthAndSafetyOutlined, LocalShippingOutlined, LoopOutlined } from '@mui/icons-material';
import { useRootStore } from 'stores';
import { CarouselProduct } from '@vklink/components-carousel';
import { useBreakpoints } from '@vklink/components-shared';
import { AddToCartButton, ButtonAdjustment, ReadOnlyProductRating } from 'pages/shared/components';
import { useToast } from '@vklink/components-toast-message';
import { observer } from '@vklink/libs-state';
import { formatCurrency } from 'pages/shared/utils';
import { SelectVariationField, useForm } from '@vklink/components-form';
import { AttributeValues, PromotionProduct } from 'pages/shared/models';
import { AttributeTypeOptions } from 'constant';
import { PaymentRoutes } from 'pages/shared/routers';
import { useAuthStore } from '@vklink/libs-auth';
import { Attribute, DefaultAttributeValue, AddToCart } from 'pages/product-detail/stores/models';
import { useCacheAfterAuthenticatedActions, useSignIn } from 'pages/shared/hook';
import { useI18n } from '@vklink/libs-i18n';
import ProductPromotion from './ProductPromotion';

type Props = {
  productDetail: any;
  promotion?: PromotionProduct;
};

const ProductMainInfo = (props: Props) => {
  const { commonStore } = useRootStore();
  const [quantity, setQuantity] = useState(1);
  const breakpoints = useBreakpoints();
  const toast = useToast();
  const navigator = useNavigate();
  const { isAuthenticated } = useAuthStore();
  const { signInAsync } = useSignIn();
  const { cacheActions } = useCacheAfterAuthenticatedActions();
  const { t } = useI18n(['pages', 'common']);

  const { control, handleSubmit, setValue } = useForm<Attribute>({
    mode: 'all',
    defaultValues: DefaultAttributeValue,
  });

  const productDetail = props.productDetail;
  const promotion = props.promotion;

  useEffect(() => {
    if (props.productDetail?.attributes.length > 0) {
      props.productDetail?.attributes.forEach((item: any, index: number) => {
        setValue(`attributeValueIds.${index}`, item.attributeValues[0].id);
      });
    }
  }, [props.productDetail?.attributes]);

  const isAuthentication = (beforeSignIn: () => void) => {
    if (!isAuthenticated) {
      beforeSignIn();
      signInAsync();
      return false;
    }

    return true;
  };

  const handleAddToCart = (data: Attribute) => {
    if (
      !isAuthentication(() => {
        cacheActions({
          add_to_cart: {
            productId: productDetail.id as string,
            quantity: quantity,
            attributeValueIds: data.attributeValueIds,
          },
        });
      })
    ) {
      return;
    }

    const productInfo: AddToCart = {
      cartId: commonStore.carts?.id ?? null,
      productId: productDetail.id as string,
      quantity: quantity,
      attributeValueIds: data.attributeValueIds,
    };

    commonStore.addProductToCartAsync(productInfo, {
      success: () => {
        commonStore.getCartItemsAsync();
        toast('Success', 'Add to cart successfully', 'success', 'filled');
      },
      error: () => {
        toast('Error', 'Add to cart failed', 'error', 'filled');
      },
    });
  };

  const handleBuyNow = (data: Attribute) => {
    if (
      !isAuthentication(() => {
        cacheActions({
          buy_now: {
            productId: productDetail.id as string,
            quantity: quantity,
            attributeValueIds: data.attributeValueIds,
          },
        });
      })
    ) {
      return;
    }

    const productInfo: AddToCart = {
      cartId: commonStore.carts?.id ?? null,
      productId: productDetail.id as string,
      quantity: quantity,
      attributeValueIds: data.attributeValueIds,
    };

    commonStore.addProductToCartAsync(productInfo, {
      success: async () => {
        await commonStore.getCartItemsAsync();

        const cartItemId =
          commonStore.getProductInCart?.find((el) => el.productId === productInfo.productId)?.id ??
          '';

        commonStore.checkoutAllSelectedProduct([cartItemId]);

        toast('Success', 'Add to cart successfully', 'success', 'filled');
        navigator(PaymentRoutes.ROOT);
      },
      error: () => {
        toast('Error', 'Add to cart failed', 'error', 'filled');
      },
    });

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  const handleIncreaseProduct = (product: number) => {
    setQuantity(product);
  };

  const handleDecreaseProduct = (product: number) => {
    if (product < 1) return;
    setQuantity(product);
  };

  const mappingToSelectOptions = (attributes: AttributeValues[]) => {
    return attributes.map((el) => {
      return {
        value: el.id,
        label: el.value,
      };
    });
  };

  const renderAttribute = () => (
    <>
      {productDetail?.attributes.map((el: any, index: number) => (
        <SelectVariationField
          key={el.id}
          control={control}
          name={`attributeValueIds.${index}`}
          options={mappingToSelectOptions(el.attributeValues)}
          label={
            el.name !== ''
              ? el.name
              : AttributeTypeOptions.find((attribute) => attribute.value == el.attributeType)?.label
          }
        />
      ))}
    </>
  );

  return (
    <Grid container>
      <Grid item xs={12} lg={6}>
        <CarouselProduct
          slider={(productDetail?.medias || []).map((el: any) => {
            return {
              src: el.url,
            };
          })}
        />
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        sx={{
          p: {
            xs: 1,
            lg: 3,
          },
        }}
      >
        <Stack spacing={1}>
          {breakpoints.md && (
            <Typography variant="body2" component="div">
              {t('pages:products.brand')}:{' '}
              <Typography variant="body2" component="span" color="primary">
                {productDetail?.brand.name}
              </Typography>
            </Typography>
          )}

          <Typography
            variant="h5"
            component="h1"
            sx={{
              fontWeight: 'bold',
              color: 'primary.light',
            }}
          >
            {productDetail?.name}
          </Typography>

          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
            spacing={2}
            divider={
              <Divider
                flexItem
                orientation="vertical"
                variant="middle"
                sx={{ backgroundColor: 'grey.300' }}
              />
            }
          >
            <ReadOnlyProductRating
              sx={{
                '& .rating-stars': {
                  fontSize: 25,
                },
              }}
              rating={productDetail?.ratingAvg ?? 5}
            />
            {promotion?.promotionType && (
              <ProductPromotion
                content={promotion.promotionType}
                point={promotion.point}
                sx={{
                  pl: 1,
                  '& .text-product-promotion': {
                    fontSize: 14,
                  },
                }}
              />
            )}
          </Stack>

          <Stack direction="row" gap={2} alignItems="center">
            <Typography
              sx={{
                fontSize: (theme) => theme.typography.fontSize * 1.5,
                fontWeight: 'bold',
              }}
            >
              ₫{productDetail?.sellPrice && formatCurrency(productDetail?.sellPrice, 0, 3)}
            </Typography>
            <Typography
              variant="body2"
              component="div"
              color="gray"
              sx={{ textDecoration: 'line-through' }}
            >
              ₫{productDetail?.regularPrice && formatCurrency(productDetail?.regularPrice, 0, 3)}
            </Typography>
            <Typography
              variant="body2"
              component="div"
              color="error.dark"
              sx={{ border: 1, p: 0.5, borderRadius: 1 }}
            >
              -{productDetail?.discountPercent}%
            </Typography>
          </Stack>
        </Stack>

        <Divider sx={{ backgroundColor: 'grey.300', my: 2 }} />

        <Stack
          spacing={1}
          sx={{
            '& .product-btn-adjustment': {
              maxWidth: 180,
              my: 2,
              ml: breakpoints.md ? 0 : 2,
            },
          }}
        >
          <Stack
            className="product-attributes"
            spacing={1}
            sx={{
              '& .MuiButton-contained': {
                backgroundColor: 'warning.main',
                color: 'common.white',
                fontWeight: 'bold',
                ':hover': {
                  backgroundColor: 'warning.main',
                },
              },
              '& .MuiButton-outlined': {
                borderColor: 'grey.300',
                color: 'grey.600',
                ':hover': {
                  borderColor: 'grey.300',
                },
              },
            }}
          >
            {renderAttribute()}
          </Stack>

          <Stack
            alignItems={{
              xs: 'center',
              sm: 'flex-start',
            }}
          >
            <ButtonAdjustment
              quantity={quantity}
              onChange={setQuantity}
              decrease={() => handleDecreaseProduct(quantity - 1)}
              increase={() => handleIncreaseProduct(quantity + 1)}
            />
          </Stack>

          <Stack direction="row" spacing={1}>
            {!!productDetail && (
              <AddToCartButton
                product={productDetail as any}
                onClick={handleSubmit(handleAddToCart)}
              />
            )}

            <Button
              fullWidth
              variant="outlined"
              sx={{
                borderRadius: 0,
                borderColor: 'grey.300',
                backgroundColor: 'common.white',
                fontWeight: 'bold',
                color: 'grey.600',
                fontSize: (theme) => theme.typography.fontSize - 1,

                ':hover': {
                  borderColor: 'grey.300',
                  backgroundColor: 'common.white',
                },
              }}
              onClick={handleSubmit(handleBuyNow)}
            >
              {t('common:actions.buyNow')}
            </Button>
          </Stack>
        </Stack>

        <Divider sx={{ backgroundColor: 'grey.300', my: 2 }} />

        <Stack spacing={1}>
          <Box display="flex" gap={1} color="gray">
            <HealthAndSafetyOutlined fontSize="small" />
            <Typography variant="body1" component="div">
              {t('pages:products.guarantee')}
            </Typography>
          </Box>
          <Box display="flex" gap={1} color="gray">
            <LocalShippingOutlined fontSize="small" />
            <Typography variant="body1" component="div">
              {t('pages:products.expectedDelivery')}
            </Typography>
          </Box>
          <Box display="flex" gap={1} color="gray">
            <LoopOutlined fontSize="small" />
            <Typography variant="body1" component="div">
              {t('pages:products.returnDay')}
            </Typography>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default observer(ProductMainInfo);
