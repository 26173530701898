import { useMemo } from 'react';
import { Outlet } from 'react-router';

import { createStore, getEnv } from '@vklink/libs-state';

import { useRootStore } from 'stores';
import { CheckoutStore, CheckoutStoreInstance, CheckoutStoreProvider } from './stores';

const CheckoutLayout = () => {
  const rootStore = useRootStore();
  const initialState: CheckoutStoreInstance = useMemo(() => {
    return createStore(CheckoutStore, undefined, {
      ...getEnv(rootStore),
      load: rootStore.loadingStore.load,
      loaded: rootStore.loadingStore.loaded,
    });
  }, []);

  return (
    <CheckoutStoreProvider value={initialState}>
      <Outlet />
    </CheckoutStoreProvider>
  );
};

export default CheckoutLayout;
