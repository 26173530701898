import { generatePath } from 'react-router';

import { Instance, types, flow, applySnapshot, LoadingStore } from '@vklink/libs-state';
import { ORDER_API, USERS_API, PRODUCT_API } from 'api';
import { BrandModel, CategoryModel } from 'pages/shared/models';
import { CartModel, LogIn } from './models';
import { httpInstance } from './RootStore';
import { countTotalInArray } from 'pages/shared/utils';
import { UserInformationModel } from 'pages/profile/store/models';
import { AddToCart } from 'pages/product-detail/stores/models';
import { MediaIndex } from 'enums';
import { formatCurrency } from '../pages/shared/utils/mixins';

const CommonStore = types
  .compose(
    LoadingStore,
    types.model('Common Store', {
      searchingText: types.optional(types.string, ''),
      categoryOptions: types.array(CategoryModel),
      brandOptions: types.array(BrandModel),
      carts: types.maybeNull(CartModel),
      selectedItemIds: types.array(types.string),
      userInformation: types.maybeNull(UserInformationModel),
    })
  )
  .views((self) => ({
    get getSearchingText() {
      return self.searchingText;
    },
    get getCategoryOptions() {
      return self.categoryOptions.map((el) => {
        return { value: el.id, label: el.name, parent: el.parent?.id };
      });
    },
    get getHierarchyCategoryOptions() {
      const opts = this.getCategoryOptions;

      const buildHierarchyTree = (list: any[]) => {
        const roots: any[] = [];
        const children: Record<string, any> = {};

        list.forEach((item) => {
          if (!!item.parent) {
            const temp = children[item.parent] || [];

            children[item.parent] = [...temp, item];
          } else {
            roots.push(item);
          }
        });

        const findChildren = (parent: any) => {
          if (children[parent.value]) {
            parent.children = children[parent.value];

            for (let i = 0, len = parent.children.length; i < len; ++i) {
              findChildren(parent.children[i]);
            }
          }
        };

        roots.forEach((item) => {
          findChildren(item);
        });

        return roots;
      };

      return buildHierarchyTree(opts);
    },
    get getBrandOptions() {
      return self.brandOptions.map((el) => {
        return { value: el.id, label: el.name };
      });
    },
    get getTotalProductInCart() {
      return self.carts?.cartItems ? self.carts?.cartItems.length : 0;
    },
    get getTotalPriceInCart() {
      return self.carts?.cartItems ? countTotalInArray(self.carts?.cartItems, 'getTotalPrice') : 0;
    },
    get getProductInCart() {
      return self.carts?.cartItems;
    },
    isProductSelected(id: string) {
      return self.selectedItemIds.includes(id);
    },
    get allSelected() {
      return (
        self.selectedItemIds.length === self.carts?.cartItems.length &&
        !!self.carts?.cartItems.length
      );
    },
    get indeterminateSelected() {
      return (
        self.selectedItemIds.length > 0 &&
        self.selectedItemIds.length < (self.carts?.cartItems ? self.carts?.cartItems.length : 0)
      );
    },
    get getSelectedItems() {
      if (self.selectedItemIds.length > 0) {
        const selectedItems = self.carts?.cartItems.filter((el) =>
          self.selectedItemIds.includes(el.id)
        );
        return selectedItems;
      } else {
        return [];
      }
    },
    get getPointOfUser() {
      if (!!self.userInformation?.point) {
        return formatCurrency(self.userInformation?.point, 0, 3);
      }
      return 0;
    },
  }))
  .actions((self) => {
    const setSearchingText = (text: string) => {
      self.searchingText = text;
    };

    const resetSelectedItemIds = () => {
      applySnapshot(self.selectedItemIds, []);
    };

    const resetCart = () => {
      self.carts = null;
    };

    const checkoutAllSelectedProduct = (listId: string[]) => {
      applySnapshot(self.selectedItemIds, listId);
    };

    const selectProduct = (id: string) => {
      const existedProduct = self.getProductInCart?.find((item) => item.id === id);

      if (existedProduct) {
        self.selectedItemIds.push(id);
      }
    };

    const toggleSelectAllProducts = (checked: boolean) => {
      if (checked && !self.getProductInCart?.length) {
        return;
      }
      applySnapshot(
        self.selectedItemIds,
        checked ? self.getProductInCart?.map((item) => item.id) : []
      );
    };

    const unselectProduct = (cartItemId: string) => {
      applySnapshot(
        self.selectedItemIds,
        self.selectedItemIds.filter((id) => id !== cartItemId)
      );
    };

    const toggleProductSelected = (cartItemId: string) => {
      const isSelected = self.isProductSelected(cartItemId);

      if (isSelected) {
        unselectProduct(cartItemId);
      } else {
        selectProduct(cartItemId);
      }
    };

    const getCategoryOptionsAsync = flow(function* () {
      try {
        const response = yield httpInstance.get(PRODUCT_API.GET_CATEGORIES_OPTIONS);
        applySnapshot(self.categoryOptions, response.data);
      } catch (err) {
        console.log(err);
      }
    });

    const getBrandOptionsAsync = flow(function* () {
      try {
        const response = yield httpInstance.get(PRODUCT_API.GET_BRANDS, {
          params: {
            pageSize: 1000,
            pageNumber: 1,
          },
        });

        // TODO fix api get brands
        applySnapshot(
          self.brandOptions,
          response.data.filter((item: any) => item.enabled)
        );
      } catch (err) {
        console.log(err);
      }
    });

    const getCartItemsAsync = flow(function* () {
      try {
        const response = yield httpInstance.get(ORDER_API.GET_CART_DETAIL);
        if (response.data) self.carts = response.data;
      } catch (err) {
        console.log(err);
      }
    });

    const updateQuantityProduct = flow(function* (
      id: string,
      quantity: number,
      cb?: RequestCallback
    ) {
      try {
        const url = generatePath(ORDER_API.PUT_CART_ITEMS, { id });

        yield httpInstance.put(url, { quantity });
        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      }
    });

    const deleteProductInCart = flow(function* (ids: string[], cb?: RequestCallback) {
      try {
        yield httpInstance.put(ORDER_API.DELETE_CART_ITEMS, { ids });
        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      }
    });

    const loginUserAsync = flow(function* (data: LogIn, cb?: RequestCallback) {
      try {
        yield httpInstance.post(USERS_API.POST_USERS_LOGIN, { ...data });

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      }
    });

    const getUserInformationAsync = flow(function* () {
      try {
        const response = yield httpInstance.get(USERS_API.GET_USER);

        if (response.data.avatar) response.data.images = [{ dataURL: response.data.avatar }];

        self.userInformation = response.data;
      } catch (err) {
        console.log(err);
      }
    });

    const addProductToCartAsync = flow(function* (productInfo: AddToCart, cb?: RequestCallback) {
      const loadingId = self.load('Add Product To Cart Async');
      try {
        yield httpInstance.post(ORDER_API.POST_PRODUCT_TO_CART, {
          ...productInfo,
        });
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
        console.log(err);
      } finally {
        self.loaded(loadingId);
      }
    });

    const getProductDetailAsync = flow(function* (id: string, cb?: RequestCallback<any, any>) {
      const loadingId = self.load('Get Product Detail By Id Async');
      try {
        const response = yield httpInstance.get(
          generatePath(PRODUCT_API.GET_PRODUCT_DETAIL, { id })
        );

        const data = {
          ...response.data,
          medias: response.data.medias.filter((el: any) => el.mediaIndex === MediaIndex.DETAIL),
        };
        cb?.success && cb.success(data);
      } catch (err) {
        console.log(err);
      } finally {
        self.loaded(loadingId);
      }
    });

    const getPromotionDetailAsync = flow(function* (id: string, cb?: RequestCallback<any, any>) {
      const loadingId = self.load('Get Promotion Detail Async');
      try {
        const response = yield httpInstance.get(
          generatePath(PRODUCT_API.GET_PROMOTION_BY_PRODUCT_ID, { id })
        );

        cb?.success && cb.success(response.data);
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        self.loaded(loadingId);
      }
    });

    const postEmailNotificationAsync = flow(function* (email: string, cb?: RequestCallback) {
      const loadingId = self.load('Post Email Notification Async');

      try {
        yield httpInstance.post(USERS_API.POST_EMAIL_NOTIFICATION, { email });

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        self.loaded(loadingId);
      }
    });

    return {
      getUserInformationAsync,
      checkoutAllSelectedProduct,
      resetSelectedItemIds,
      resetCart,
      setSearchingText,
      getCategoryOptionsAsync,
      getBrandOptionsAsync,
      getCartItemsAsync,
      updateQuantityProduct,
      deleteProductInCart,
      toggleProductSelected,
      toggleSelectAllProducts,
      loginUserAsync,
      addProductToCartAsync,
      getProductDetailAsync,
      postEmailNotificationAsync,
      getPromotionDetailAsync,
    };
  });

export default CommonStore;

export type CommonStoreInstance = Instance<typeof CommonStore>;
