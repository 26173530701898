import { types, applySnapshot } from '@vklink/libs-state';
import { DefaultPaginationInfo, PaginationModel } from 'pages/shared/models/pagination';

export const PaginationStore = types
  .model('Pagination Store', {
    pagination: types.optional(PaginationModel, DefaultPaginationInfo),
  })
  .views((self) => ({
    get getPaginationInfo() {
      return self.pagination;
    },
    get getPaginationParams() {
      return {
        pageSize: self.pagination.pageSize,
        pageNumber: self.pagination.pageNumber,
      };
    },
  }))
  .actions((self) => {
    const setPagination = (pagination: Partial<Pagination>) => {
      applySnapshot(self.pagination, { ...DefaultPaginationInfo, ...pagination });
    };

    return {
      setPagination,
    };
  });
