import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { observer } from '@vklink/libs-state';

import { useAuthStore } from 'AuthStore';

const SilentCallbackPage = observer(() => {
  const { signinSilentCallbackAsync } = useAuthStore();
  const navigator = useNavigate();

  useEffect(() => {
    (async () => {
      await signinSilentCallbackAsync(window.location.href);

      navigator('/');
    })();
  }, []);

  return <></>;
});

export default SilentCallbackPage;
