import { useMemo } from 'react';
import { Outlet } from 'react-router';

import { createStore, getEnv } from '@vklink/libs-state';

import { useRootStore } from 'stores';
import { HomeStore, HomeStoreInstance, HomeStoreProvider } from './stores';

const HomeLayout = () => {
  const rootStore = useRootStore();
  const initialState: HomeStoreInstance = useMemo(() => {
    return createStore(HomeStore, undefined, {
      ...getEnv(rootStore),
      load: rootStore.loadingStore.load,
      loaded: rootStore.loadingStore.loaded,
    });
  }, []);

  return (
    <HomeStoreProvider value={initialState}>
      <Outlet />
    </HomeStoreProvider>
  );
};

export default HomeLayout;
