import { OrderStatus } from 'enums';

export const OrderStatusOptions: SelectOption[] = [
  {
    label: 'common:orderStatus.waitingConfirmation',
    value: OrderStatus.WAITING,
  },
  {
    label: 'common:orderStatus.confirmed',
    value: OrderStatus.CONFIRMED,
  },
  {
    label: 'common:orderStatus.packed',
    value: OrderStatus.PACKED,
  },
  {
    label: 'common:orderStatus.shipping',
    value: OrderStatus.SHIPPING,
  },
  {
    label: 'common:orderStatus.delivered',
    value: OrderStatus.DELIVERED,
  },

  {
    label: 'common:orderStatus.received',
    value: OrderStatus.RECEIVED,
  },
  {
    label: 'common:orderStatus.cancelled',
    value: OrderStatus.CANCEL,
  },
];

export const OrderStatusWithoutCancelledOptions: SelectOption[] = OrderStatusOptions.filter(
  (el) => el.value !== OrderStatus.CANCEL
);

export const OrderStatusCancelledOptions: SelectOption[] = [
  {
    label: 'common:orderStatus.waitingConfirmation',
    value: OrderStatus.WAITING,
  },
  {
    label: 'common:orderStatus.cancelled',
    value: OrderStatus.CANCEL,
  },
];
