import { types, SnapshotOut, Instance } from '@vklink/libs-state';
import { CartItemAttributeModel } from './CartItemAttributeModel';
import { PromotionDetailModel } from './PromotionDetailModel';

export const CartItemModel = types
  .model('Cart Item Model', {
    id: types.identifier,
    productId: types.string,
    productCode: types.string,
    productName: types.string,
    productImageUrl: types.string,
    productSlug: types.string,
    quantity: types.number,
    sellPrice: types.number,
    regularPrice: types.number,
    cartItemAttributeValues: types.array(CartItemAttributeModel),
    promotionDetails: types.array(PromotionDetailModel),
  })
  .views((self) => ({
    get getTotalPrice() {
      return self.sellPrice * self.quantity;
    },
    get getAttributeValues() {
      return self.cartItemAttributeValues.map((item) => {
        return item.attributeValue;
      });
    },
  }));

export interface CartItem extends SnapshotOut<typeof CartItemModel> {}
export interface CartItemInstance extends Instance<typeof CartItemModel> {}
