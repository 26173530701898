import { SnapshotOut, types, Instance, SnapshotIn } from '@vklink/libs-state';
import { UploadInputModel } from 'pages/shared/models';

export const OrderItemFeedbackModel = types.model('Order Item Rating Model', {
  id: types.identifier,
  orderId: types.string,
  itemId: types.string,
  rate: types.maybeNull(types.integer),
  comment: types.maybeNull(types.string),
  medias: types.array(UploadInputModel),
});

export const DefaultOrderItemFeedbackValue: Partial<OrderItemFeedbackInputModel> = {
  rate: null,
  comment: '',
  medias: [],
};

export type CreateOrderItemFeedback = Pick<
  OrderItemFeedbackInputModel,
  'orderId' | 'itemId' | 'rate' | 'comment' | 'medias'
>;

export interface OrderItemFeedbackInstance extends Instance<typeof OrderItemFeedbackModel> {}
export interface OrderItemFeedbackInputModel extends SnapshotIn<typeof OrderItemFeedbackModel> {}
export interface OrderItemFeedback extends SnapshotOut<typeof OrderItemFeedbackModel> {}
