import { StaticRoutes, HomeRoutes, MyProfileRoutes } from 'pages/shared/routers';

import visa from 'assets/visa.svg';
import momo from 'assets/momo.svg';
import mastercard from 'assets/mastercard.svg';
import JCB from 'assets/JCB.svg';
import ATM from 'assets/ATM.svg';
import vnpay from 'assets/vnpay.svg';
import phone from 'assets/phone.svg';
import money from 'assets/money.svg';

export const SupportedPaymentMethods = [
  {
    name: 'Visa',
    image: visa,
  },
  {
    name: 'Momo',
    image: momo,
  },
  {
    name: 'MasterCard',
    image: mastercard,
  },
  {
    name: 'JCB',
    image: JCB,
  },
  {
    name: 'ATM',
    image: ATM,
  },
  {
    name: 'VNPAY',
    image: vnpay,
  },
  {
    name: 'Phone',
    image: phone,
  },
  {
    name: 'Money',
    image: money,
  },
];

export const SubCustomerCareList = [
  // { title: 'New Customers' },
  // { title: 'How to use Account' },
  // { title: 'Placing an Order' },
  { title: 'common:mixins.privacyPolicies', href: StaticRoutes.PRIVACY_POLICY },
  // { title: 'Problems with Order' },
];

export const SubCustomerServiceList = [
  // { title: 'Help Center' },
  { title: 'components:sideBar.contactUs', href: HomeRoutes.CONTACT_US },
  // { title: 'Report Abuse' },
  // { title: 'Submit a Dispute' },
  // { title: 'Policies & Rules' },
];

export const SubMyAccountList = [
  // { title: 'Product Support' },
  // { title: 'Checkout' },
  // { title: 'Shopping Cart' },
  // { title: 'Wishlist' },
  { title: 'common:mixins.termsAndConditions', href: StaticRoutes.TERMS_AND_CONDITIONS },
  // { title: 'Redeem Voucher' },
];

export const SubQuickLinksList = [
  // { title: 'Store Location' },
  { title: 'common:user.myAccount', href: MyProfileRoutes.EDIT_PROFILE },
  { title: 'common:user.myOrders', href: MyProfileRoutes.PURCHASE_ORDER },
  { title: 'common:mixins.faqs', href: StaticRoutes.FAQ },
];

export const Links: any[] = [
  // { title: 'Online Shopping' },
  // { title: 'Promotions' },
  // { title: 'My Orders' },
  // { title: 'Help' },
  // { title: 'Customer Service' },
  // { title: 'Support' },
  // { title: 'Most Populars' },
  // { title: 'New Arrivals' },
  // { title: 'Special Products ' },
  // { title: 'Manufacturers' },
  // { title: 'Garden Equipments' },
  // { title: 'Powers And Hand Tools ' },
  // { title: 'Utensil & Gadget ' },
  // { title: 'Printers' },
  // { title: 'Projectors' },
  // { title: 'Scanners' },
  // { title: 'Store' },
  // { title: 'Business' },
];
