import { SnapshotOut, types } from '@vklink/libs-state';

const ChildCommentModel = types.model('Child Comment Model', {
  id: types.identifier,
  commentText: types.string,
  userCommentName: types.string,
  userCommentAvatar: types.maybeNull(types.string),
  createdAt: types.string,
});

export interface ChildComment extends SnapshotOut<typeof ChildCommentModel> {}

export const ProductDiscussionModel = types.model('Product Discussion Model', {
  id: types.identifier,
  commentText: types.string,
  userCommentName: types.string,
  userCommentAvatar: types.maybeNull(types.string),
  createdAt: types.string,
  childComments: types.array(ChildCommentModel),
});

export interface ProductDiscussion extends SnapshotOut<typeof ProductDiscussionModel> {}
