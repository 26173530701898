import { types } from '@vklink/libs-state';

export const MediaModel = types.model('Media Model', {
  id: types.string,
  mediaIndex: types.string,
  name: types.string,
  title: types.string,
  url: types.string,
  bucketName: types.string,
});

export const BannerModel = types.model('Banner Model', {
  id: types.string,
  heading: types.maybeNull(types.string),
  bannerType: types.string,
  title: types.string,
  hyperlink: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  medias: types.array(MediaModel),
});
