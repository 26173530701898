import { Instance, SnapshotOut, types } from '@vklink/libs-state';
import { HistoryStatus } from 'enums';

export const UserPointModel = types
  .model('User Point Model', {
    id: types.identifier,
    pointId: types.string,
    orderId: types.string,
    orderNumber: types.string,
    oldValue: types.number,
    newValue: types.number,
    changedValue: types.number,
    historyStatus: types.enumeration('History Status', Object.values(HistoryStatus)),
    createdAt: types.maybeNull(types.string),
  })
  .views((self) => {
    return {
      get isReceivePoint() {
        return (
          self.historyStatus === HistoryStatus.GIFT || self.historyStatus === HistoryStatus.CANCEL
        );
      },
    };
  });

export interface UserPoint extends SnapshotOut<typeof UserPointModel> {}
export interface UserPointInstance extends Instance<typeof UserPointModel> {}
