import { applySnapshot, flow, getEnv, getSnapshot, Instance, types } from '@vklink/libs-state';
import { CATALOG_API } from 'api';
import { AddressCatalogModel } from '../models';
import { generatePath } from 'react-router';
import { SHIPPING_ADDRESSES_API } from 'api';
import { UserShippingAddressModel } from 'pages/profile/store/models';
import { RootStoreEnv } from 'stores';

export type AddressCatalogStoreEnv = RootStoreEnv & {
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

export const AddressCatalogStore = types
  .model('AddressCatalog Store', {
    provinces: types.array(AddressCatalogModel),
    districts: types.array(AddressCatalogModel),
    wards: types.array(AddressCatalogModel),

    shippingAddressDetail: types.maybe(UserShippingAddressModel),
  })
  .views((self) => ({
    get getListProvinces() {
      const provinces = getSnapshot(self.provinces).map((el) => ({
        value: el.id,
        label: el.name,
      }));

      return provinces;
    },
    get getListDistricts() {
      const districts = getSnapshot(self.districts).map((el) => ({
        value: el.id,
        label: el.name,
      }));

      return districts;
    },
    get getListWards() {
      const wards = getSnapshot(self.wards).map((el) => ({
        value: el.id,
        label: el.name,
      }));

      return wards;
    },
  }))
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<AddressCatalogStoreEnv>(self);

    const getShippingAddressDetailAsync = flow(function* (id: string) {
      const loadingId = load('Get Shipping Address Detail Async');

      try {
        const response = yield httpInstance.get(
          generatePath(SHIPPING_ADDRESSES_API.GET_SHIPPING_ADDRESSES_DETAIL, { id })
        );

        self.shippingAddressDetail = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getProvincesAsync = flow(function* () {
      const loadingId = load('Get Provinces Async');
      try {
        const response = yield httpInstance.get(CATALOG_API.GET_PROVINCES);

        applySnapshot(self.provinces, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getDistrictsAsync = flow(function* (idProvince?: string) {
      const loadingId = load('Get Districts By Province Id Async');

      try {
        const response = yield httpInstance.get(
          generatePath(CATALOG_API.GET_DISTRICTS, { id: idProvince })
        );

        applySnapshot(self.districts, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getWardsAsync = flow(function* (idDistrict?: string) {
      const loadingId = load('Get Districts By Province Id Async');

      try {
        const response = yield httpInstance.get(
          generatePath(CATALOG_API.GET_WARDS, { id: idDistrict })
        );

        applySnapshot(self.wards, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      getProvincesAsync,
      getDistrictsAsync,
      getWardsAsync,
      getShippingAddressDetailAsync,
    };
  });

export default AddressCatalogStore;

export type AddressCatalogStoreInstance = Instance<typeof AddressCatalogStore>;
