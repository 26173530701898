const API_V1_ADDRESS = '/cat/api/v1/addresses';
const API_V1_SETTINGS = '/cat/api/v1/settings';

export const CATALOG_API = {
  GET_PROVINCES: `${API_V1_ADDRESS}/provinces`,
  GET_DISTRICTS: `${API_V1_ADDRESS}/provinces/:id/districts`,
  GET_WARDS: `${API_V1_ADDRESS}/districts/:id/wards`,

  GET_SETTING_TYPE_BY_KEY: `${API_V1_SETTINGS}/key/:key`,

  GET_LIST_BANNERS: `${API_V1_SETTINGS}/banner`,
};
