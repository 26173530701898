import { SnapshotOut, types } from '@vklink/libs-state';

export const OutOfStockModel = types.model('Out Of Stock Model', {
  goodsId: types.string,
  goodsCode: types.string,
  goodsName: types.string,
  stockQuantity: types.number,
  message: types.string,
});

export interface OutOfStock extends SnapshotOut<typeof OutOfStockModel> {}

const ProductItemModel = types.model('ProductItem Model', {
  goodsCode: types.string,
  quantity: types.number,
});

export const CheckStockModel = types.model('Check Stock Model', {
  provinceCode: types.string,
  items: types.array(ProductItemModel),
});

export interface CheckStock extends SnapshotOut<typeof CheckStockModel> {}
