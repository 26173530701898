import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import { VerifiedUserOutlined } from '@mui/icons-material';
import visa from 'assets/visa-black.svg';
import mastercard from 'assets/mastercard.svg';
import JCB from 'assets/JCB.svg';
import { useForm, TextField } from '@vklink/components-form';

type AddCardProps = {
  onClose: () => void;
};

const AddCard = (props: AddCardProps) => {
  const { control } = useForm<any>();

  const handleClose = () => {
    props.onClose && props.onClose();
  };

  return (
    <Box p={4} sx={{ backgroundColor: (theme) => theme.palette.common.white }}>
      <Typography variant="h6" component="p" pb={2} sx={{ fontWeight: 'bold' }}>
        Add Card
      </Typography>
      <Box p={1} border={1} borderColor="primary.main" borderRadius={2}>
        <Box display="flex" alignItems="center">
          <Box width={32}>
            <VerifiedUserOutlined fontSize="small" color="primary" />
          </Box>
          <Typography variant="body1" component="div" pb={1}>
            Your card information is secure.
          </Typography>
        </Box>
        <Typography variant="body2" component="div" pl={4}>
          We work with CyberSource to make sure your card information is safe and secure. VKlink
          will not be granted access to your card information. We will keep your personal
          information confidential
        </Typography>
      </Box>
      <Box py={2} display="flex" justifyContent="space-between">
        <Typography variant="inherit" component="div">
          Card Detail
        </Typography>
        <Box display="flex">
          <Avatar alt="Administrator" src={visa} sx={{ width: 32, height: 32 }} />
          <Avatar alt="Administrator" src={mastercard} sx={{ width: 32, height: 32 }} />
          <Avatar alt="Administrator" src={JCB} sx={{ width: 32, height: 32 }} />
        </Box>
      </Box>
      <Stack spacing={2}>
        <TextField control={control} name="numberCard" placeholder="Number card" />
        <Box display="flex" gap={2}>
          <TextField
            control={control}
            name="expirationDate"
            placeholder="Expiration date (MM/YY)"
            sx={{ flexGrow: 1 }}
          />
          <TextField control={control} name="cvv" placeholder="Code CVV" />
        </Box>
        <TextField control={control} name="cardholder" placeholder="Cardholder's full name" />
        <Typography variant="inherit" component="div" color="gray">
          Credit/Debit card registration address
        </Typography>
        <TextField control={control} name="address" placeholder="Address" />
        <TextField control={control} name="postalCode" placeholder="Postal code" />
        <Box display="flex" gap={2} alignSelf="flex-end">
          <Button
            type="reset"
            variant="outlined"
            sx={{
              px: 4,
              color: 'grey.600',
              borderColor: 'grey.400',
            }}
            onClick={handleClose}
          >
            Back
          </Button>
          <Button type="submit" variant="contained" color="warning" onClick={handleClose}>
            Complete
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default AddCard;
