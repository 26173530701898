import { types } from '@vklink/libs-state';
import { ConditionType } from 'enums';
import { GiftDetailModel } from './GiftDetailModel';

export const PromotionDetailModel = types.model('Promotion Detail Model', {
  id: types.identifier,
  conditionType: types.enumeration('Condition Type', Object.values(ConditionType)),
  orderProductId: types.string,
  orderProductName: types.maybeNull(types.string),
  orderProductCode: types.maybeNull(types.string),
  totalOrder: types.maybeNull(types.number),
  giftDetails: types.maybeNull(GiftDetailModel),
});
