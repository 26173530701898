import { SnapshotOut, types } from '@vklink/libs-state';
import { DeliveryAddressModel } from './DeliveryAddressModel';
import { ProductItemModel } from './ProductItem';

export const SubmitOrderModel = types.model('SubmitOrder Model', {
  deliveryFee: types.number,
  couponCode: types.maybeNull(types.string),
  couponValue: types.number,
  couponType: types.maybeNull(types.string),
  subTotal: types.number,
  totalPrice: types.number,
  paymentMethod: types.string,
  note: types.maybeNull(types.string),
  items: types.array(ProductItemModel),
  deliveryAddress: types.maybeNull(DeliveryAddressModel),
  pointDiscount: types.number,
});

export interface SubmitOrder extends SnapshotOut<typeof SubmitOrderModel> {}
