import { PointHistory, HistoryStatus } from 'enums';

export const HistoryStatusOptions: SelectOption[] = [
  {
    label: 'pages:point.other',
    value: HistoryStatus.OTHER,
  },
  {
    label: 'pages:point.used',
    value: HistoryStatus.USE,
  },
  {
    label: 'pages:point.cancelled',
    value: HistoryStatus.CANCEL,
  },
  {
    label: 'pages:point.gift',
    value: HistoryStatus.GIFT,
  },
];

export const PointHistoryOptions: SelectOption[] = [
  {
    label: 'pages:point.received',
    value: PointHistory.RECEIVED,
  },
  {
    label: 'pages:point.used',
    value: PointHistory.USED,
  },
];
