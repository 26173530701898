import { PromotionType } from 'enums';

export const PromotionTypeOptions: SelectOption[] = [
  {
    label: 'Gift',
    value: PromotionType.GIFT,
  },
  {
    label: 'Points',
    value: PromotionType.POINT,
  },
  {
    label: 'Other',
    value: PromotionType.OTHER,
  },
];
