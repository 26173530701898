import { observer } from '@vklink/libs-state';
import { ProductAvatar, ProductName } from 'pages/shared/components';
import { Box, Typography, Grid, Stack } from '@mui/material';
import { useBreakpoints } from '@vklink/components-shared';
import { GiftDetail, Product } from 'stores/models';
import { formatCurrency } from 'pages/shared/utils';
import promotion from 'assets/promotion-3.png';
import { PromotionType } from 'enums';

type Props = {
  giftDetail: GiftDetail | null;
  readonly?: boolean;
};

const GiftDetail = (props: Props) => {
  const { giftDetail, readonly } = props;

  const breakpoints = useBreakpoints();

  const renderPoint = () => (
    <Grid
      container
      justifyContent="space-between"
      sx={{
        borderTop: 1,
        borderColor: 'grey.300',
        py: 2,
        pl: 2,
        pr: breakpoints.md ? 2 : 0,
      }}
    >
      <Grid item gap={1} xs={6} md={5} sx={{ display: 'flex', alignItems: 'center', pl: 4 }}>
        <Box
          sx={{
            width: 70,
            height: 70,
          }}
          border={1}
          borderColor="grey.400"
          borderRadius={2}
        >
          <Box
            component="img"
            src={promotion}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              transition: '0.5s',
              p: 1,
            }}
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={6}
        md={2}
        sx={{
          placeSelf: 'center',
        }}
      >
        <Typography
          variant="body2"
          component="div"
          color={readonly ? 'grey.400' : 'inherit'}
          sx={{
            textAlign: breakpoints.md ? 'center' : 'right',
          }}
        >
          {giftDetail?.point && formatCurrency(giftDetail.point, 0, 3)} points
        </Typography>
      </Grid>
      {breakpoints.md && (
        <>
          <Grid item md={2} />
          <Grid item md={2} />
        </>
      )}

      {!readonly && <Grid item md={1} />}
    </Grid>
  );

  const renderProduct = (product: Product) => (
    <Grid
      key={product.id}
      container
      justifyContent="space-between"
      sx={{
        borderTop: 1,
        borderColor: 'grey.300',
        p: 2,
      }}
    >
      <Grid item gap={1} xs={11} md={5} sx={{ display: 'flex', alignItems: 'center', pl: 4 }}>
        <Box
          sx={{
            width: 70,
            height: 70,
          }}
          border={1}
          p={1}
          borderColor="grey.400"
          borderRadius={2}
        >
          <ProductAvatar
            product={{
              id: product.id,
              imageUrl: product.fileUrl || '',
              slug: product.slug || '',
            }}
          />
        </Box>
        <ProductName
          name={product.name || ''}
          id={product.id}
          slug={product.slug || ''}
          numOfLines={2}
          promotionType={PromotionType.GIFT}
        />
      </Grid>
      <Grid
        item
        xs={1}
        md={2}
        sx={{
          placeSelf: 'center',
        }}
      >
        <Typography
          variant="body2"
          component="div"
          color={readonly ? 'grey.400' : 'inherit'}
          sx={{
            textAlign: breakpoints.md ? 'center' : 'right',
          }}
        >
          {!breakpoints.md && 'x'} 1
        </Typography>
      </Grid>
      <Grid item md={2} />
      <Grid item md={2} />
      {!readonly && <Grid item md={1} />}
    </Grid>
  );

  const renderListProducts = () => (
    <Stack>{giftDetail?.products?.map((product) => renderProduct(product))}</Stack>
  );

  return <>{giftDetail?.point ? renderPoint() : renderListProducts()}</>;
};

export default observer(GiftDetail);
