import { Gender } from 'enums';

export const GenderOptions: SelectOption[] = [
  {
    label: 'common:gender.male',
    value: Gender.MALE,
  },
  {
    label: 'common:gender.female',
    value: Gender.FEMALE,
  },
  {
    label: 'common:gender.other',
    value: Gender.OTHER,
  },
];
