import { SnapshotOut, types } from '@vklink/libs-state';

export const DeliveryAddressModel = types.model('DeliveryAddress Model', {
  contactName: types.string,
  contactPhone: types.string,
  address: types.string,
  wardName: types.string,
  districtName: types.string,
  provinceName: types.string,
  wardCode: types.string,
  districtCode: types.string,
  provinceCode: types.string,
  countryName: types.string,
});

export interface DeliveryAddress extends SnapshotOut<typeof DeliveryAddressModel> {}
