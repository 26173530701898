import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { observer } from '@vklink/libs-state';

import { useAuthStore } from 'AuthStore';

const SigninCallbackPage = observer(() => {
  const { signinCallbackAsync } = useAuthStore();
  const navigator = useNavigate();

  useEffect(() => {
    (async () => {
      const queryParams = new URLSearchParams(window.location.search);
      await signinCallbackAsync(window.location.href);

      const redirectPath = queryParams.get('redirect_path') || '/';
      navigator(redirectPath);
    })();
  }, []);

  return <></>;
});

export default SigninCallbackPage;
