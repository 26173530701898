import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';

import { Avatar, Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import avatar from 'assets/avatar-default.jpeg';
import {
  ExitToAppOutlined,
  FeedOutlined,
  NotificationsNoneOutlined,
  PaidOutlined,
  PersonOutlined,
  SvgIconComponent,
} from '@mui/icons-material';
import { MyProfileRoutes } from 'pages/shared/routers';
import { Fragment } from 'react';
import { useBreakpoints } from '@vklink/components-shared';
import { observer } from '@vklink/libs-state';
import { useRootStore } from 'stores';
import { useI18n } from '@vklink/libs-i18n';

type MenuItem = {
  title: string;
  icon?: SvgIconComponent;
  isActive?: boolean;
  path?: string;
};

type Props = {
  onClose?: () => void;
};

const MenuBar = (props?: Props) => {
  const breakpoints = useBreakpoints();
  const { pathname } = useLocation();
  const navigator = useNavigate();
  const { t } = useI18n(['common']);
  const { commonStore } = useRootStore();

  const activeLink = (path: string) => {
    return pathname === path;
  };

  const renderMenuItem = (item: MenuItem, isSub?: boolean) => {
    const Icon: any = item.icon;
    const title: any = item.title;

    return (
      <Box
        color={item.isActive ? 'primary.main' : 'grey.600'}
        display="flex"
        sx={{
          textDecoration: 'none',
          alignItems: 'center',
          ml: (theme) => theme.spacing(isSub ? 1 : 0),
          mb: 0.5,
        }}
        {...(item.path ? { component: Link, to: item.path } : {})}
      >
        {item.icon && <Icon sx={{ mr: 1, width: (theme: any) => theme.spacing(3) }} />}
        <Typography
          sx={{
            ml: (theme: any) => theme.spacing(item.icon ? 0 : 4),
            // fontWeight: item.isActive ? 'bold' : undefined,
          }}
          variant="body1"
          component="div"
          onClick={() => props?.onClose && props?.onClose()}
        >
          {t(title)}
        </Typography>
      </Box>
    );
  };

  const renderMenu = (main: MenuItem, subs?: MenuItem[]) => {
    return (
      <>
        {renderMenuItem(main)}
        <Divider sx={{ mb: 0.5, backgroundColor: 'grey.300' }} variant="middle" />
        {subs?.map((item, idx) => {
          return <Fragment key={idx}>{renderMenuItem(item, true)}</Fragment>;
        })}
      </>
    );
  };

  const Menu: {
    main: MenuItem;
    subs?: MenuItem[];
  }[] = [
    {
      main: {
        title: 'common:user.myAccount',
        isActive: pathname.includes(MyProfileRoutes.ACCOUNT_INFORMATION),
        icon: PersonOutlined,
      },
      subs: [
        // {
        //   title: 'Profile Info',
        //   path: MyProfileRoutes.ACCOUNT_INFORMATION,
        //   isActive: activeLink(MyProfileRoutes.ACCOUNT_INFORMATION),
        // },
        {
          title: 'common:user.editProfile',
          path: MyProfileRoutes.EDIT_PROFILE,
          isActive: activeLink(MyProfileRoutes.EDIT_PROFILE),
        },
        // {
        //   title: 'Bank',
        //   path: MyProfileRoutes.BANK_MANAGEMENT,
        //   isActive: activeLink(MyProfileRoutes.BANK_MANAGEMENT),
        // },
        {
          title: 'common:address.addresses',
          path: MyProfileRoutes.ADDRESS_MANAGEMENT,
          isActive: activeLink(MyProfileRoutes.ADDRESS_MANAGEMENT),
        },
        {
          title: 'common:user.changePassword',
          path: MyProfileRoutes.CHANGE_PASSWORD,
          isActive: activeLink(MyProfileRoutes.CHANGE_PASSWORD),
        },
      ],
    },
    {
      main: {
        title: 'common:user.myOrders',
        path: MyProfileRoutes.PURCHASE_ORDER,
        isActive: pathname.includes(MyProfileRoutes.PURCHASE_ORDER),
        icon: FeedOutlined,
      },
    },
    {
      main: {
        title: 'common:user.vkPoint',
        path: MyProfileRoutes.MY_POINTS,
        isActive: pathname.includes(MyProfileRoutes.MY_POINTS),
        icon: PaidOutlined,
      },
    },
    {
      main: {
        title: 'common:notification.notify',
        isActive: pathname.includes(MyProfileRoutes.ORDER_UPDATE),
        icon: NotificationsNoneOutlined,
      },
      subs: [
        {
          title: 'common:mixins.orderUpdate',
          isActive: activeLink(MyProfileRoutes.ORDER_UPDATE),
        },
        {
          title: 'common:mixins.promotion',
          isActive: activeLink(MyProfileRoutes.PROMOTION),
        },
      ],
    },
  ];

  const handleLogout = () => {
    navigator('/');
  };

  return (
    <Box
      p={{ xs: 2, md: 3 }}
      sx={{
        boxShadow: { md: 2 },
        backgroundColor: 'common.white',
      }}
    >
      <Stack direction="row" spacing={1} sx={{ overflow: 'hidden', alignItems: 'center' }}>
        <Avatar
          alt="Administrator"
          src={commonStore.userInformation?.avatar ?? avatar}
          sx={{ width: 50, height: 50 }}
        />
        <Stack overflow="hidden">
          <Typography variant="body2" component="p" color="gray">
            {t('common:user.myAccount')}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            fontWeight="bold"
            color="black"
            sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {commonStore.userInformation?.username ?? commonStore.userInformation?.phone}
          </Typography>
        </Stack>
        {!breakpoints.md && (
          <Box sx={{ flexGrow: 1, textAlign: 'right' }}>
            <IconButton size="small" onClick={handleLogout}>
              <ExitToAppOutlined />
            </IconButton>
          </Box>
        )}
      </Stack>
      <Divider
        // variant="middle"
        sx={{
          backgroundColor: 'grey.400',
          mt: 1,
          mb: 3,
        }}
      />
      <Stack>
        {Menu.map((item, idx) => {
          return (
            <Stack key={idx} sx={{ mb: 2 }}>
              {renderMenu(item.main, item.subs)}
            </Stack>
          );
        })}
      </Stack>
    </Box>
  );
};

export default observer(MenuBar);
