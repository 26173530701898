import { SnapshotOut, types } from '@vklink/libs-state';

export const UserBankModel = types.model('User Bank Model', {
  id: types.identifier,
  isDefault: types.boolean,
  cardType: types.number,
  cardNumber: types.string,
  expriationDate: types.string,
  cvv: types.string,
  cardName: types.string,
  address: types.string,
  postalCode: types.string,
});

export interface UserBank extends SnapshotOut<typeof UserBankModel> {}
