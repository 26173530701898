import { LocalPhoneOutlined, EmailOutlined, ArrowDropDownOutlined } from '@mui/icons-material';
import { Box, Container, Typography } from '@mui/material';

const Header = () => {
  return (
    <Box
      width={'100%'}
      sx={{
        backgroundColor: (theme) => theme.palette.secondary.main,
        color: (theme) => theme.palette.common.white,
        height: 40,
      }}
    >
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <Box display="flex">
          <Box>
            <LocalPhoneOutlined fontSize="small" sx={{ verticalAlign: 'middle' }} />
            <Typography variant="body2" component="span" ml={1}>
              (+84) 373646346
            </Typography>
          </Box>
          <Box ml={2}>
            <EmailOutlined fontSize="small" sx={{ verticalAlign: 'middle' }} />
            <Typography variant="body2" component="span" ml={1}>
              Support@gmail.com
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Box>
            <EmailOutlined fontSize="small" sx={{ verticalAlign: 'middle' }} />
            <Typography variant="body2" component="span" ml={1}>
              Notify
            </Typography>
          </Box>
          <Box ml={2}>
            <Typography variant="body2" component="span" ml={1}>
              EN
            </Typography>
            <ArrowDropDownOutlined fontSize="small" sx={{ verticalAlign: 'middle' }} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Header;
