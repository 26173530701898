import { Avatar, Box, Rating, Typography, Stack } from '@mui/material';

import { MediaFeedback, ProductFeedback } from 'pages/product-detail/stores/models';
import avatar from 'assets/avatar-default.jpeg';
import { dayjs, FormatDate } from 'enums';

type Props = {
  feedback: ProductFeedback;

  onPreviewMedias: (medias: MediaFeedback[]) => void;
};

const Comment = (props: Props) => {
  const { feedback } = props;

  const renderImageFeedback = () => (
    <Box pl={7}>
      <Typography variant="body1" component="div" gutterBottom>
        {feedback.comment}
      </Typography>
      {!!feedback.medias.length && (
        <Box
          display="flex"
          flexDirection="row"
          gap={{ xs: 1, md: 2 }}
          alignItems="center"
          mt={2}
          flexWrap="wrap"
        >
          {feedback.medias.map((media) => (
            <Box
              key={media.id}
              component={'img'}
              src={media.url}
              onClick={() => props.onPreviewMedias(feedback.medias)}
              sx={{
                cursor: 'pointer',
                width: 80,
                height: 80,
                objectFit: 'contain',
                p: 0.5,
                border: 1,
                borderRadius: 1,
                borderColor: 'grey.300',
              }}
            />
          ))}
        </Box>
      )}
    </Box>
  );

  return (
    <Stack borderBottom={1} borderColor="grey.200" py={3}>
      <Box display="flex" flexDirection="row" gap={2}>
        <Avatar src={feedback.customerAvatar ?? avatar} alt="user-avatar" />
        <Box>
          <Typography variant="body2" component="div">
            {feedback.customerName}
          </Typography>
          <Rating value={feedback.rate} readOnly size="small" />
        </Box>
        <Typography variant="body2" component="div" sx={{ color: 'text.secondary' }}>
          {dayjs(feedback.createdAt).format(FormatDate.DATE_TIME)}
        </Typography>
      </Box>
      {feedback.medias.length > 0 && renderImageFeedback()}
    </Stack>
  );
};

export default Comment;
