import { ElectronicInvoice } from 'enums';

export const ElectronicInvoiceOptions: SelectOption[] = [
  {
    label: 'Personal',
    value: ElectronicInvoice.PERSONAL,
  },
  {
    label: 'Company',
    value: ElectronicInvoice.COMPANY,
  },
];
