import { generatePath } from 'react-router-dom';

import { Instance, types, flow, getEnv, applySnapshot, getSnapshot } from '@vklink/libs-state';
import {
  ProductDiscussionModel,
  ProductFeedbackModel,
  ProductRatingFeedbackModel,
  UserCommentInputModel,
  PostProductDiscussion,
} from './models';
import { PRODUCT_API } from 'api';
import { RootStoreEnv } from 'stores';
import { UserCommentModel } from './models';
import { DefaultPaginationInfo, PaginationModel } from 'pages/shared/models';
import { removeEmptyInObject } from 'pages/shared/utils';

export type ProductReviewStoreEnv = RootStoreEnv & {
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const ProductReviewStore = types
  .model('Product Store', {
    productFeedbacks: types.array(ProductFeedbackModel),
    productRatingFeedbacks: types.array(ProductRatingFeedbackModel),
    userComment: types.maybeNull(UserCommentModel),
    productDiscussion: types.array(ProductDiscussionModel),

    paginationReview: types.optional(PaginationModel, DefaultPaginationInfo),
  })
  .views((self) => ({
    get getRatingFeedback() {
      return getSnapshot(self.productRatingFeedbacks);
    },
    get getProductDiscussion() {
      return getSnapshot(self.productDiscussion);
    },
    get getPaginationReviewParams() {
      return removeEmptyInObject({
        pageSize: self.paginationReview.pageSize,
        pageNumber: self.paginationReview.pageNumber,
      });
    },
  }))
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<ProductReviewStoreEnv>(self);

    const setPaginationReview = (pagination: Partial<Pagination>) => {
      applySnapshot(self.paginationReview, { ...DefaultPaginationInfo, ...pagination });
    };

    const setUserComment = (userComment: UserCommentInputModel) => {
      self.userComment = userComment;
    };

    const getRatingFeedbackByProductIdAsync = flow(function* (id: string) {
      const loadingId = load('Get Rating Feedback By Product Id Async');

      try {
        const url = generatePath(PRODUCT_API.GET_PRODUCT_RATING_FEEDBACK, { id });
        const response = yield httpInstance.get(url);

        applySnapshot(self.productRatingFeedbacks, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getFeedbackByProductIdAsync = flow(function* (id: string, star?: number) {
      const loadingId = load('Get Feedback By Product Id Async');

      try {
        const url = generatePath(PRODUCT_API.GET_PRODUCT_FEEDBACK, { id });
        const response = yield httpInstance.get(url, {
          params: {
            star,
            ...self.paginationReview,
          },
        });

        applySnapshot(self.productFeedbacks, response.data);
        applySnapshot(self.paginationReview, response.metadata.pagination);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getDiscussionByProductIdAsync = flow(function* (id: string, keyword?: string) {
      const loadingId = load('Get Discussion By Product Id Async');

      try {
        const url = generatePath(PRODUCT_API.GET_PRODUCT_DISCUSSION, { id });
        const response = yield httpInstance.get(url, {
          params: {
            keyword,
            ...self.paginationReview,
          },
        });

        applySnapshot(self.productDiscussion, response.data);
        applySnapshot(self.paginationReview, response.metadata.pagination);
        console.log(self.paginationReview);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const postDiscussionByProductIdAsync = flow(function* (
      id: string,
      discussion: PostProductDiscussion,
      cb?: RequestCallback
    ) {
      const loadingId = load('Post Discussion By Product Id Async');

      try {
        const url = generatePath(PRODUCT_API.POST_PRODUCT_DISCUSSION, { id });

        yield httpInstance.post(url, { ...discussion });

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      setPaginationReview,
      setUserComment,
      getFeedbackByProductIdAsync,
      getRatingFeedbackByProductIdAsync,
      getDiscussionByProductIdAsync,
      postDiscussionByProductIdAsync,
    };
  });

export default ProductReviewStore;
export type ProductReviewStoreInstance = Instance<typeof ProductReviewStore>;
