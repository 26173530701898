import { generatePath } from 'react-router-dom';

import { Instance, types, flow, getEnv, applySnapshot, getSnapshot } from '@vklink/libs-state';
import { ProductDetailModel, UserCommentInputModel } from './models';
import { PRODUCT_API } from 'api';
import { CategoryModel, ProductItemModel } from 'pages/shared/models';
import { PaginationStore } from 'pages/shared/stores';
import { MediaIndex, Pagination } from 'enums';
import { HomeRoutes } from 'pages/shared/routers';
import { RootStoreEnv } from 'stores';
import ProductReviewStore from './ProductReviewStore';

export type ProductStoreEnv = RootStoreEnv & {
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const ProductStore = types
  .compose(
    ProductReviewStore,
    PaginationStore,
    types.model('Product Store', {
      productDetail: types.maybeNull(ProductDetailModel),
      productRelation: types.array(ProductItemModel),
      categoryTree: types.array(CategoryModel),
    })
  )
  .views((self) => ({
    get getProductRelation() {
      return getSnapshot(self.productRelation);
    },
    get getProductFeedbacks() {
      return getSnapshot(self.productFeedbacks);
    },
    get getCategoryTree() {
      const categories = getSnapshot(self.categoryTree).map((category) => {
        return {
          title: category.name,
          path: `${HomeRoutes.SEARCH}?search=${category.name}`,
        };
      });

      const productBreadcrumbs = [
        ...categories,
        {
          title: self.productDetail?.name ?? '',
        },
      ];

      return productBreadcrumbs;
    },
    get getAttributeProduct() {
      return self.productDetail?.attributes ? getSnapshot(self.productDetail?.attributes) : [];
    },
  }))
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<ProductStoreEnv>(self);

    const setUserComment = (userComment: UserCommentInputModel) => {
      self.userComment = userComment;
    };

    const getProductDetailAsync = flow(function* (id: string) {
      const loadingId = load('Get Product Detail By Id Async');
      try {
        const response = yield httpInstance.get(
          generatePath(PRODUCT_API.GET_PRODUCT_DETAIL, { id })
        );

        self.productDetail = {
          ...response.data,
          medias: response.data.medias.filter((el: any) => el.mediaIndex === MediaIndex.DETAIL),
        };
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getProductRelationAsync = flow(function* () {
      const loadingId = load('Get All Products Async');
      try {
        const response = yield httpInstance.get(PRODUCT_API.GET_PRODUCTS, {
          params: {
            categoryId: self.productDetail?.category.id,
            pageNumber: self.getPaginationInfo.pageNumber,
            pageSize: Pagination.PAGE_SIZE,
          },
        });

        if (self.getPaginationInfo.pageNumber === 1) {
          applySnapshot(self.productRelation, response.data);
        } else {
          applySnapshot(self.productRelation, [...self.productRelation, ...response.data]);
        }
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getCategoryByProductIdAsync = flow(function* (productId: string) {
      const loadingId = load('Get All Products Async');
      try {
        const response = yield httpInstance.get(PRODUCT_API.GET_CATEGORY_BY_PRODUCT_ID, {
          params: {
            productId,
          },
        });

        applySnapshot(self.categoryTree, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      setUserComment,
      getProductDetailAsync,
      getProductRelationAsync,
      getCategoryByProductIdAsync,
    };
  });

export default ProductStore;
export type ProductStoreInstance = Instance<typeof ProductStore>;
