import {
  QueryClient,
  type QueryClientConfig,
  useQuery as useQueryBase,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

export const createQueryClient = (http: HttpInstance) => {
  const config: QueryClientConfig = {
    defaultOptions: {
      queries: {
        retry: 3,
        refetchOnWindowFocus: false,
        retryDelay: (count) => Math.pow(2, count),
        queryFn: async ({ queryKey, meta }) => {
          const preferPost = meta?.method == 'POST';
          const params = queryKey[1] ? (queryKey[1] as Record<string, any>) : undefined;

          let response: AxiosResponse<any>;

          if (preferPost) response = await http.post(`${queryKey[0]}`, params);
          else
            response = await http.get(`${queryKey[0]}`, {
              params,
            });

          return response.data;
        },
      },
    },
  };

  const queryClient = new QueryClient(config);

  return queryClient;
};

export const useQuery = <TResponse>(
  path: string,
  params?: Record<string, any> | (() => Record<string, any>),
  config?: {
    method?: 'GET';
    queryOption?: UseQueryOptions;
  }
) => {
  let queryParams: Record<string, any> | undefined;

  if (typeof params === 'function') queryParams = params();
  else queryParams = params;

  return useQueryBase<TResponse>([path, queryParams], {
    meta: {
      method: config?.method ?? 'GET',
    },
    suspense: false, // Not Ready For Production Yet
    keepPreviousData: true,
    ...(config?.queryOption as any),
  });
};
