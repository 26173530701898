import { createStoreContext } from '@vklink/libs-state';
import { CheckoutStoreInstance } from './CheckoutStore';

const [CheckoutStoreProvider, useCheckoutStore] = createStoreContext<CheckoutStoreInstance>();

export { CheckoutStoreProvider, useCheckoutStore };

export {
  default as CheckoutStore,
  type CheckoutStoreEnv,
  type CheckoutStoreInstance,
} from './CheckoutStore';
