import { AttributeType } from 'enums';

export const AttributeTypeOptions: SelectOption[] = [
  {
    label: 'Color',
    value: AttributeType.COLOR,
  },
  {
    label: 'Size',
    value: AttributeType.SIZE,
  },
  {
    label: 'Variation',
    value: AttributeType.VARIANT,
  },
  {
    label: 'Other',
    value: AttributeType.OTHER,
  },
];
