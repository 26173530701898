import { types, Instance, getEnv, flow } from '@vklink/libs-state';
import { HttpInstance } from '@vklink/libs-http';
import { AUTHENTICATION_API } from 'api';
import { ConfirmOtp, Register } from './models';

export type RegisterStoreEnv = {
  httpInstance: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const RegisterStore = types.model('Register Store', {}).actions((self) => {
  const { httpInstance, load, loaded } = getEnv<RegisterStoreEnv>(self);

  const confirmOtp = flow(function* (otpValue: ConfirmOtp, cb?: RequestCallback) {
    const loadingId = load('Confirm Otp Async');

    try {
      yield httpInstance.post(AUTHENTICATION_API.POST_CONFIRM_EMAIL, {
        ...otpValue,
      });

      cb?.success && cb.success();
    } catch (err) {
      console.log(err);
      cb?.error && cb.error(err);
    } finally {
      loaded(loadingId);
    }
  });

  const registerAccount = flow(function* (accountInformation: Register, cb?: RequestCallback) {
    const loadingId = load('Register User');

    try {
      yield httpInstance.post(AUTHENTICATION_API.POST_REGISTRATION, {
        ...accountInformation,
      });

      cb?.success && cb.success();
    } catch (err) {
      console.log(err);
      cb?.error && cb.error(err);
    } finally {
      loaded(loadingId);
    }
  });

  return {
    confirmOtp,
    registerAccount,
  };
});

export default RegisterStore;

export type RegisterStoreInstance = Instance<typeof RegisterStore>;
