import { createI18n } from '@vklink/libs-i18n';

import common from './locales/en/common.json';
import components from './locales/en/components.json';
import pages from './locales/en/pages.json';

import { en, vi } from '@vklink/components-form';

export const fallbackLng = 'en';
export const defaultNS = 'common';
export const supportedLngs = ['en', 'vi'];
export const resources = {
  en: {
    common,
    components,
    pages,
    validation: en,
  },
  vi: {
    common: require('./locales/vi/common.json'),
    components: require('./locales/vi/components.json'),
    pages: require('./locales/vi/pages.json'),
    validation: vi,
  },
};

const i18n = createI18n(resources, fallbackLng, supportedLngs, defaultNS);

export default i18n;
