import { generatePath } from 'react-router';

import { Instance, types, flow, getEnv } from '@vklink/libs-state';
import { CouponInputModel, CouponModel, SubmitOrder } from './models';
import { UserInformationStore } from 'pages/profile/store/UserInformationStore';
import { OrderFieldModel } from 'stores/models';
import { CATALOG_API, ORDER_API } from 'api';
import { CouponType, dayjs, OrderSettingType } from 'enums';
import { PaymentStore } from './PaymentStore';
import { RootStoreEnv } from 'stores';
import { strip } from 'pages/shared/utils';
import { SettingKeyModel } from 'pages/shared/models';
import { CheckStockStore } from './CheckStockStore';

export type CheckoutStoreEnv = RootStoreEnv & {
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const CheckoutStore = types
  .compose(
    CheckStockStore,
    UserInformationStore,
    PaymentStore,
    types.model('Checkout Store', {
      order: types.maybeNull(OrderFieldModel),
      addressId: types.optional(types.string, ''),
      note: types.optional(types.string, ''),
      coupons: types.maybeNull(CouponModel),
      totalPrice: types.optional(types.number, 0),
      shippingFee: types.maybeNull(SettingKeyModel),
    })
  )
  .views((self) => {
    return {
      get getSelectedAddress() {
        return self.userShippingAddress.find((x) => x.id === self.addressId);
      },
      get getCouponValue() {
        if (self.coupons?.type === CouponType.PERCENT) {
          return strip(self.totalPrice * (self.coupons?.value / 100));
        } else if (self.coupons?.type === CouponType.VALUE) {
          return self.coupons?.value;
        }
        return 0;
      },
      get getShippingFee() {
        return Number(self.shippingFee?.value);
      },
    };
  })
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<CheckoutStoreEnv>(self);

    const setAddressId = (addressId: string) => {
      self.addressId = addressId;
    };

    const setNoteField = (note: string) => {
      self.note = note;
    };

    const setCoupon = (coupon: CouponInputModel | null) => {
      self.coupons = coupon;
    };

    const postOrderAsync = flow(function* (order: SubmitOrder, cb?: RequestCallback) {
      const loadingId = load('Get All Products Async');
      try {
        const response = yield httpInstance.post(ORDER_API.POST_ORDER, {
          ...order,
        });

        self.order = response.data;
        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getCouponAsync = flow(function* (code: string, totalPrice: number, cb?: RequestCallback) {
      const loadingId = load('Get All Products Async');
      try {
        const response = yield httpInstance.get(generatePath(ORDER_API.GET_COUPONS, { code }));

        if (dayjs(response.data.endDate).diff(dayjs()) < 0) {
          throw new Error('Invalid coupon, Please enter another coupon');
        }

        if (dayjs(response.data.startDate).diff(dayjs()) > 0) {
          throw new Error('Invalid coupon, Please enter another coupon');
        }

        self.totalPrice = totalPrice;
        self.coupons = response.data;

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getSettingByKeyAsync = flow(function* (key: OrderSettingType) {
      const loadingId = load('Get Setting By Key Async');

      try {
        const url = generatePath(CATALOG_API.GET_SETTING_TYPE_BY_KEY, { key });

        const response = yield httpInstance.get(url);

        self.shippingFee = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getOrderDetailByIdAsync = flow(function* (id: string) {
      const loadingId = load('Get Order Detail Async');
      try {
        const url = generatePath(ORDER_API.GET_ORDER_DETAIL, { id });
        const response = yield httpInstance.get(url);

        self.order = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      setCoupon,
      setNoteField,
      setAddressId,
      postOrderAsync,
      getCouponAsync,
      getSettingByKeyAsync,
      getOrderDetailByIdAsync,
    };
  });

export default CheckoutStore;
export type CheckoutStoreInstance = Instance<typeof CheckoutStore>;
