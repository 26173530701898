import { createStoreContext } from '@vklink/libs-state';

import { type ProfileStoreInstance } from './ProfileStore';

const [ProfileStoreProvider, useProfileStore] = createStoreContext<ProfileStoreInstance>();

export { ProfileStoreProvider, useProfileStore };

export {
  default as ProfileStore,
  type ProfileStoreEnv,
  type ProfileStoreInstance,
} from './ProfileStore';
