import { SnapshotOut, types } from '@vklink/libs-state';

export const OrderDeliveryInformationModel = types.model('Order Item Attribute Values Model', {
  address1: types.string,
  address2: types.string,
  contactName: types.string,
  contactPhone: types.string,
  countryName: types.string,
  districtName: types.string,
  fullAddress: types.string,
  id: types.identifier,
  orderId: types.string,
  provinceName: types.string,
  wardName: types.string,
});

export interface OrderDeliveryInformation
  extends SnapshotOut<typeof OrderDeliveryInformationModel> {}
