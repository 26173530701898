import { SnapshotOut, types } from '@vklink/libs-state';

export const PaginationParamsModel = types.model({
  pageSize: types.optional(types.number, 12),
  pageNumber: types.optional(types.number, 1),
});

export const DefaultPaginationParams: PaginationParams = {
  pageSize: 12,
  pageNumber: 1,
};

export interface PaginationParams extends SnapshotOut<typeof PaginationParamsModel> {}
