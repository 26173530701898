import { Breakpoint, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useBreakpoints = (): Record<Breakpoint, boolean> => {
  const theme = useTheme();

  const xsMatches = useMediaQuery(theme.breakpoints.up('xs'));
  const smMatches = useMediaQuery(theme.breakpoints.up('sm'));
  const mdMatches = useMediaQuery(theme.breakpoints.up('md'));
  const lgMatches = useMediaQuery(theme.breakpoints.up('lg'));
  const xlMatches = useMediaQuery(theme.breakpoints.up('xl'));

  return {
    xs: xsMatches,
    sm: smMatches,
    md: mdMatches,
    lg: lgMatches,
    xl: xlMatches,
  };
};

export default useBreakpoints;
