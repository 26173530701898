import { SnapshotIn, types } from '@vklink/libs-state';
import { UploadInputModel } from 'pages/shared/models';

export const BankTransferModel = types.model('Bank TransferModel', {
  orderNumber: types.string,
  customerName: types.string,
  medias: types.array(UploadInputModel),
});

export interface BankTransfer extends SnapshotIn<typeof BankTransferModel> {}
