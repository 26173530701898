import { IModelType, ModelProperties } from 'mobx-state-tree';

import configureStore from 'configureStore';

const createStore = <TStore extends IModelType<ModelProperties, unknown>>(
  store: TStore,
  initialValue?: Record<string, unknown>,
  env?: Record<string, unknown>
) => {
  const initialState = store.create(initialValue, env);

  configureStore(initialState);

  return initialState;
};

export default createStore;
