import { ProductSortBy } from 'enums';

export const ProductSortByOptions: SelectOption[] = [
  {
    label: 'common:actions.rating',
    value: ProductSortBy.Rating,
  },
  {
    label: 'pages:products.priceLowToHigh',
    value: ProductSortBy.PriceLowToHigh,
  },
  {
    label: 'pages:products.priceHighToLow',
    value: ProductSortBy.PriceHighToLow,
  },
];
